import { useState } from "react";
import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import Header from "../Header";
import { getParsedCsvData } from "@/utils";
import ColumnMapping from "./ColumnMapping";
import BackToMainPage from "../BackToMainPage";

import ToolsBg from "@/assets/images/tools-bg.png";
import EmailFinderIcon from "@/assets/svgs/email-finder.svg";
import UniqueEmailFinderIcon from "@/assets/svgs/email-finder-icon.svg";
import Icons from "@/components/Icons";

import leadService from "@/services/leads.service";

const BulkEmailFinder = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [uploadedColumns, setUploadedColumns] = useState([]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState<any>({
    fullName: "",
    companyName: "",
    linkedinUrl: "",
  });

  const { mutateAsync: bulkEmailFinderMutateAsync, isPending: isLoading } =
    useMutation({
      mutationFn: ({ formData }: { formData: FormData }) =>
        leadService.uploadCSVFindWorkEmail("new", formData),
    });

  const handleFileChange = async (e: any) => {
    const file = e.target.files?.[0] || null;
    if (file?.type !== "text/csv") {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const columnNames = (await getParsedCsvData(file)) as any;
    setSelectedFile(file);

    if (columnNames.columns) {
      onOpen();
      setUploadedColumns(columnNames.columns?.filter(Boolean));
    }
  };

  const handleSubmit = async () => {
    if (
      !selectedOptions.linkedinUrl ||
      !selectedOptions.fullName ||
      !selectedOptions.companyName
    ) {
      // show error
      toast({
        position: "top-right",
        title:
          "Please map Full name, Company name/domain, Linkedin URL columns",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    selectedFile && formData.append("csvFile", selectedFile);

    formData.append(
      "selectedColumnsMaps",
      JSON.stringify({
        fullName: selectedOptions.fullName,
        linkedinUrl: selectedOptions.linkedinUrl,
        companyName: selectedOptions.companyName,
      }),
    );

    await bulkEmailFinderMutateAsync(
      {
        formData,
      },
      {
        onSuccess(response: any) {
          if (response?.success) {
            window.open(`/table/${response.data.tableId}`, "_blank");
            toast({
              title: "Success",
              description: "File uploaded successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Error",
              description: response?.error?.message || "Failed to upload CSV!",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
        onError(error) {
          toast({
            title: "Error",
            description: error?.message || "Failed to upload CSV!",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        },
      },
    );

    setSelectedOptions({
      linkedinUrl: "",
      companyName: "",
      fullName: "",
    });
    setSelectedFile(null);
    setUploadedColumns([]);
    onClose();
  };

  return (
    <div
      className="bg-cover bg-center bg-no-repeat min-h-screen"
      style={{ backgroundImage: `url(${ToolsBg})` }}
    >
      <Header />
      <div className="flex items-center justify-center flex-col w-[72%] mx-auto">
        <div className="w-full">
          <div className="w-full">
            <Box className="flex justify-between items-center my-4 w-full">
              <div className="flex items-center gap-2">
                <img src={EmailFinderIcon} height={40} width={40} />
                <div>
                  <p className="text-[16px] text-[#0F0D1C] font-[600]">
                    Bulk Email Finder
                  </p>
                  <p className="text-[13px] text-[#7E889F]">
                    Find email addresses of a list of leads.
                  </p>
                </div>
              </div>
              <Link to="/tools/email-finder">
                <Button
                  variant={"outline"}
                  leftIcon={
                    <img
                      src={UniqueEmailFinderIcon}
                      alt=""
                      width={12}
                      height={12}
                    />
                  }
                  size={"sm"}
                  className="!text-[#0F0D1C] !font-medium !text-[14px] !border !border-[#C03EDC] !bg-white"
                >
                  Unique Email Finder
                </Button>
              </Link>
            </Box>
          </div>

          <Box className="!w-full p-[24px] pb-[40px] flex items-center justify-center flex-col gap-6 border border-[#D0D4DC] !bg-white rounded-[12px] ">
            <p className="text-[14px] text-[#0F0D1C] font-medium">
              Import a CSV of your leads list
            </p>
            <label className="cursor-pointer border-dashed border-2 border-[#D0D4DC] rounded-md w-[80%] h-[100px] flex items-center justify-center gap-3 flex-col cursor-pointer">
              <Icons.ExportIcon className="text-[#C03EDC] text-[36px] font-bold" />
              <p className="text-[14px] font-medium text-[#373F51]">
                Upload CSV here.
              </p>
              <input
                type="file"
                accept=".csv"
                className="hidden"
                onChange={handleFileChange}
              />
            </label>
          </Box>
        </div>
      </div>
      <BackToMainPage />
      <ColumnMapping
        isOpen={isOpen}
        onClose={onClose}
        uploadedColumns={uploadedColumns}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        handleSubmit={handleSubmit}
        isPending={isLoading}
      />
    </div>
  );
};

export default BulkEmailFinder;
