import {
  WalkThroughModalActionType,
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "@/components/Common/AutoPilot/WalkThroughModal";
import WalkThroughInfo from "@/components/Common/AutoPilot/WalkThroughModal/WalkThroughInfo";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import Icons from "@/components/Icons";
import { queryClient } from "@/lib/queryClient";
import leadService from "@/services/leads.service";
import { TableData } from "@/types/table.types";
import {
  Button,
  Checkbox,
  HStack,
  Input,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface FindPeopleSourceProps {
  totalSteps: number;
  action: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | undefined;
}

const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Biweekly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

function JobOpeningsSource({
  totalSteps,
  action,
  selectedTableData,
}: FindPeopleSourceProps) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [limit, setLimit] = useState(20);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [isFindPeopleAtCompany, setIsFindPeopleAtCompany] = useState(false);
  const [isFindWorkEmail, setIsFindWorkEmail] = useState(false);
  const [isNotifyByEmail, setIsNotifyByEmail] = useState(false);
  const [findPeopleJobTitles, setFindPeopleJobTitles] = useState<Option[]>([]);

  const isConfigured = !!selectedTableData;
  const otherPayloads = [
    {
      name: "Location",
      state: location,
      description: "",
      setState: (value: any) => {
        setLocation(value);
      },
    },
    {
      name: "Organization Name",
      state: organizationName,
      description: "",
      setState: (value: any) => {
        setOrganizationName(value);
      },
    },
    {
      name: "Number of jobs to fetch",
      state: limit,
      description: "Max limit is 100",
      setState: (value: any) => {
        setLimit(value);
      },
    },
  ];

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runGoogleJobOpenings(
      {
        jobTitle,
        location,
        organizationName,
        limit,
        frequencyToUpdate: frequencyToUpdate?.value,
        isNotifyByEmail,
        isFindPeopleAtCompany,
        isFindWorkEmail,
        findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
      },
      "",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runGoogleJobOpenings(
      {},
      selectedTableData?._id || "",
      "trigger",
    );
    window.open(`/table/${selectedTableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await leadService.runGoogleJobOpenings(
      {
        jobTitle,
        location,
        organizationName,
        limit,
        frequencyToUpdate: frequencyToUpdate?.value,
        isNotifyByEmail,
        isFindPeopleAtCompany,
        isFindWorkEmail,
        findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
      },
      selectedTableData?._id || "",
      "update",
    );

    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      window.open(`/table/${selectedTableData?._id}`, "_blank");
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === selectedTableData?.metaData?.frequencyToUpdate,
    ) as Option;

    setJobTitle(selectedTableData?.metaData?.jobTitle || "");
    setLocation(selectedTableData?.metaData?.location || "");
    setOrganizationName(selectedTableData?.metaData?.organizationName || "");
    setLimit(selectedTableData?.metaData?.limit || 20);
    setFrequencyToUpdate(freq);
    setIsNotifyByEmail(selectedTableData?.metaData?.isNotifyByEmail || false);
    setIsFindPeopleAtCompany(
      selectedTableData?.metaData?.isFindPeopleAtCompany,
    );
    setIsFindWorkEmail(selectedTableData?.metaData?.isFindWorkEmail);
    const savedJobTitles =
      selectedTableData?.metaData?.findPeopleJobTitles?.map((item: string) => ({
        label: item,
        value: item,
      }));
    setFindPeopleJobTitles(savedJobTitles || []);
  }, [selectedTableData]);

  return (
    <>
      <WalkThroughModalBody>
        <WalkThroughInfo
          info="This workflow will help you find new job openings based on your preferences."
          className="border-b-[1.5px] border-[#F0EFF1] py-4"
        />
        <div className="h-full px-4 pb-5 pt-0">
          <VStack spacing={4} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
            <VStack spacing={2} align="stretch">
              <div className="flex items-center justify-between">
                <Text fontSize="md" fontWeight="semibold">
                  How frequently you want to find new jobs?
                </Text>

                <Checkbox
                  isChecked={isNotifyByEmail}
                  onChange={(e) => setIsNotifyByEmail(e.target.checked)}
                  size="md"
                  colorScheme="purple"
                >
                  Notify me via email
                </Checkbox>
              </div>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to find new jobs"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={1} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Job title <span className="text-red-400">*</span>
              </Text>
              <Input
                placeholder="Enter Job Title"
                size="md"
                variant="outline"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
            </VStack>
            {otherPayloads?.map((item) => (
              <VStack spacing={1} align="stretch">
                <Text fontSize="md" fontWeight="semibold">
                  {item.name}
                </Text>
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.state}
                  onChange={(e) => item.setState(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                />
                <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                  {item.description}
                </p>
              </VStack>
            ))}
          </VStack>
          <HStack align={"center"} gap={4} className="!mt-3">
            <Text fontSize="md" fontWeight="semibold">
              Would you like to find people at company?
            </Text>
            <Switch
              isChecked={isFindPeopleAtCompany}
              size="md"
              onChange={(e) => setIsFindPeopleAtCompany(e.target.checked)}
              id={`source-switch`}
              className="flex text-[14px] font-medium"
              colorScheme="purple"
            />
          </HStack>
          {isFindPeopleAtCompany && (
            <div className="!mt-3">
              <p className="pb-1 text-base font-semibold">
                {"Add job titles to find people"}
              </p>
              <MultiSelectDropdown
                value={findPeopleJobTitles}
                options={[]}
                onChange={(selectedValues) =>
                  setFindPeopleJobTitles(selectedValues as Option[])
                }
                placeholder={"Add job titles to include"}
              />
            </div>
          )}
          {/* {isFindPeopleAtCompany && (
            <HStack align={"center"} gap={4} className="!mt-3">
              <Text fontSize="md" fontWeight="semibold">
                Would you like to find work email of each found person?
              </Text>
              <Switch
                isChecked={isFindWorkEmail}
                size="md"
                onChange={(e) => setIsFindWorkEmail(e.target.checked)}
                id={`source-switch`}
                className="flex text-[14px] font-medium"
                colorScheme="purple"
              />
            </HStack>
          )} */}
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={isLoading}
        onSave={async () => {
          action === "new"
            ? await handleAutoPilot()
            : await updatePreferences();
        }}
        totalSteps={totalSteps}
        action={action}
      />
    </>
  );
}

export default JobOpeningsSource;
