import { Input, Textarea, Box, Text } from "@chakra-ui/react";

interface Props {
  profileInfo: {
    firstName: string;
    companyName: string;
    companyDescription: string;
    callToAction: string;
  };
  handleUpdateSettings: (fieldName: string, value: string) => void;
}

const Settings = ({ profileInfo, handleUpdateSettings }: Props) => {
  return (
    <Box className="border border-[#D0D4DC] px-[24px] py-[12px] rounded-md">
      {/* Your Name */}
      <Box>
        <Text fontSize="sm" mb={1} fontWeight={600}>
          Your Name:
        </Text>
        <Input
          id="firstName"
          value={profileInfo.firstName}
          onChange={(e) => handleUpdateSettings("firstName", e.target.value)}
          variant="outline"
          placeholder="Your Name"
          focusBorderColor="primary.500"
        />
      </Box>

      {/* Company Name */}
      <Box mt={4}>
        <Text fontSize="sm" mb={1} fontWeight={600}>
          Your Company Name:
        </Text>
        <Input
          id="companyName"
          value={profileInfo.companyName}
          onChange={(e) => handleUpdateSettings("companyName", e.target.value)}
          variant="outline"
          placeholder="Your Company Name"
          focusBorderColor="primary.500"
        />
      </Box>

      {/* Company Description */}
      <Box mt={4}>
        <Text fontSize="sm" mb={1} fontWeight={600}>
          Your Company Description:
        </Text>
        <Textarea
          id="companyDescription"
          value={profileInfo.companyDescription}
          onChange={(e) =>
            handleUpdateSettings("companyDescription", e.target.value)
          }
          variant="outline"
          placeholder="Company Description"
          focusBorderColor="primary.500"
        />
      </Box>

      {/* Call to Action */}
      <Box mt={4}>
        <Text fontSize="sm" mb={1} fontWeight={600}>
          Call to Action:
        </Text>
        <Input
          id="callToAction"
          value={profileInfo.callToAction}
          onChange={(e) => handleUpdateSettings("callToAction", e.target.value)}
          variant="outline"
          placeholder="Call to Action"
          focusBorderColor="primary.500"
        />
      </Box>
    </Box>
  );
};

export default Settings;
