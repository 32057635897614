import UniqueMobileFinder from "@/components/Tools/MobileFinder/MobileFinder";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/tools/mobile-finder").createRoute({
  component: MobileFinderPage,
  errorComponent: () => <div>Error</div>,
});

function MobileFinderPage() {
  return <UniqueMobileFinder />;
}

export default MobileFinderPage;
