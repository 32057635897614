import { AutoGeneratedColumnSchema } from "@/components/Common/SchemaCreator/schema.store";
import { FETCH_TABLE_LIMIT } from "@/constants/table";
import MakeAPICall from "@/lib/apiCalls";
import { generateFilterPayload } from "@/lib/utils";
import { TFoldersListItem } from "@/pages/TableList/types";
import { useTableStore } from "@/stores/table.store";
import {
  TableCacheSocketData,
  TableQueueReRunPayload,
} from "@/types/enrichment.types";

import type {
  BulkRowUpdatePayload,
  Column,
  ColumnCreatePayload,
  CreditHistoryData,
  DeleteColumnResponse,
  DeleteRowResponse,
  ResponseData,
  RowCreatePayload,
  RowData,
  RowUpdatePayload,
  RunningProcessesData,
  TableData,
  TableView,
} from "@/types/table.types";

class TableService {
  async getTableList() {
    const apiCall = new MakeAPICall({
      apiPath: `table/list`,
      params: {
        fields:
          "name,folderId,isAutoPilot,metaData,workflowConfig,workflowType",
      },
    });
    const response =
      await apiCall.get<
        (Partial<ResponseData["tableData"]> & { _id: string; name: string })[]
      >();
    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  }

  async getCellData(tableId: string, rowId: string, columnId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cell/${tableId}`,
      params: {
        rowId,
        columnId,
      },
    });
    const response = await apiCall.get();

    if (response.success) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  }

  async saveFilterData({
    tableId,
    isClearFilter,
  }: {
    tableId: string;
    isClearFilter?: boolean;
  }) {
    const tableFilters = useTableStore.getState().tableFilters;

    const { filterPayload } = generateFilterPayload(tableFilters.filters || []);

    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/save-filter`,
      apiVersion: "v2",
      payload: {
        filterBody: isClearFilter ? [] : filterPayload,
      },
    });

    const response = await apiCall.post<{ success: boolean }>();
    return response;
  }

  async fetchTableData({
    tableId,
    clearFilters,
    nextPage,
    signal,
  }: {
    tableId: string;
    clearFilters?: boolean;
    nextPage?: number;
    signal?: AbortSignal;
  }) {
    const queryParams = useTableStore.getState().queryParams;

    const { pageNumber } = queryParams || {};
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
      apiVersion: "v2",
      params: {
        ...queryParams,
        pageNumber: nextPage || pageNumber || 1,
        limit: FETCH_TABLE_LIMIT,
        clearFilters: !!clearFilters,
      },
    });
    const response = (await apiCall.get({
      signal,
    })) as ResponseData;

    if (response.success) {
      useTableStore.setState({
        ...response,
      });
      return response;
    } else {
      throw new Error(response?.error?.message);
    }
  }

  async getCreditHistory({
    limit,
    pageNumber,
    tableId,
    sortingKey,
    sortOrder,
  }: {
    limit?: number;
    pageNumber?: number;
    tableId?: string;
    sortingKey?: string;
    sortOrder?: string;
  }): Promise<{
    success: boolean;
    currentPage: number;
    totalPages: number;
    totalCount: number;
    data?: CreditHistoryData[];
    message?: string;
  }> {
    const params = {
      limit: limit || 50,
      pageNumber: pageNumber || 1,
    } as any;
    if (tableId) {
      params["tableId"] = tableId;
    }
    if (sortingKey) {
      params["sortingKey"] = sortingKey;
      params["sortOrder"] = sortOrder;
    }
    const apiCall = new MakeAPICall({
      apiPath: `table/credit-history`,
      apiVersion: "v2",
      params,
    });

    try {
      const response = await apiCall.get();
      console.log("myLog response", response);

      return response as any;
    } catch (error) {
      return {
        success: false,
      } as any;
    }
  }

  async updateTableData(
    tableId: string,
    payload: {
      name: string; // TODO: add more later
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }
  async updateTableDataV2(
    tableId: string,
    payload: {
      name?: string;
      metaData?: any;
      selectedViewId?: string;
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/update`,
      payload,
      apiVersion: "v2",
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateColumnData(
    tableId: string,
    columnId: string,
    payload: Partial<Column>,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/column/${columnId}`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateColumnsData({
    tableId,
    payload,
  }: {
    tableId: string;
    payload: {
      columnId: string;
      column?: Partial<Column>;
      position?: number;
      type?: string;
    }[];
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/columns`,
      payload,
    });
    const response = await apiCall.patch<ResponseData["tableData"]>();
    return response;
  }

  async updateRowData({
    tableId,
    rowId,
    payload,
  }: {
    payload: RowUpdatePayload;
    tableId: string;
    rowId: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/row/${rowId}`,
      payload,
    });
    const response = await apiCall.patch<any>();
    return response;
  }
  async updateBulkRowCells({
    tableId,
    payload,
  }: {
    payload: BulkRowUpdatePayload[];
    tableId: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/rows`,
      payload,
    });
    const response = await apiCall.patch<any>();
    return response;
  }

  async deleteTable(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}`,
    });
    const response = await apiCall.delete<ResponseData>();
    return response;
  }

  async copyTable(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/copy/${tableId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async deleteRow(tableId: string, rowId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/row/${rowId}`,
    });
    const response = await apiCall.delete<DeleteRowResponse>();
    return response;
  }

  async insertRow(tableId: string, payload?: RowCreatePayload) {
    const apiCall = new MakeAPICall({
      apiPath: `table/insert-row/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: RowData;
      message: string;
    }>();
    return response;
  }

  async insertColumn(
    tableId: string,
    payload: ColumnCreatePayload,
    query?: { [key: string]: string },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/insert-column/${tableId}`,
      payload,
      params: query,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: TableData;
      message: string;
    }>();
    return response;
  }

  async deleteColumns(tableId: string, columnIds: string[]) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/columns`,
      params: {
        columnIds: columnIds.join(","),
      },
    });
    const response = await apiCall.delete<DeleteColumnResponse>();
    return response;
  }

  async deleteRunningProcess({
    processingId,
    tableId,
  }: {
    processingId: string;
    tableId?: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cancel-job/${processingId}`,
      params: {
        tableId: tableId || "",
      },
    });
    const response = await apiCall.delete<ResponseData["tableData"]>();
    return response;
  }

  async createAndGetStarterTable(id: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/starter-list/${id}`,
    });
    const response = await apiCall.get<ResponseData>();
    if (response.success) {
      return response.data;
    }
    throw new Error(response.error);
  }

  async downloadCSV(tableId: string, queryParams: any, payload?: any) {
    const apiCall = new MakeAPICall({
      apiPath: `table/download-csv/${tableId}`,
      params: queryParams,
      payload,
    });
    const response = await apiCall.getBlob();

    if (response?.type === "application/json") {
      return {
        success: true,
        message: "We're creating your file...",
      };
    }

    const url = window.URL.createObjectURL(
      new Blob([response], { type: "text/csv" }),
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${queryParams.tableName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async getCSVSignUrl(fileKey: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/get-csv`,
      payload: {
        fileKey,
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      signedUrl: string;
      message?: string;
    }>();
    return response;
  }

  async getTableCacheData(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/cache-data/${tableId}`,
    });
    const response = await apiCall.get<TableCacheSocketData[]>();
    return response;
  }

  async createBlankTable(payload: { columns: { name: string }[] } | undefined) {
    const apiCall = new MakeAPICall({
      apiPath: `table/blank-table`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: { tableId: string };
      message?: string;
    }>();
    return response;
  }
  async removeRows(
    rowIds: string[],
    tableId: string,
    delFiltered: boolean = false,
    removeAll: boolean = false,
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/rows`,
      params: {
        rowIds: rowIds.join(","),
        delFiltered,
        removeAll,
      },
    });
    const response = await apiCall.delete<{
      success: boolean;
      message?: string;
    }>();
    return response;
  }

  async generateShareLink(tableId: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/generate-shareable-link/${tableId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getTablePreviewData(token: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/preview?token=${token}`,
      apiVersion: "v2",
    });
    const response = await apiCall.get();
    return response;
  }

  async generateFormula(tableId: string, payload: any) {
    const queryParams = useTableStore.getState().queryParams;

    const apiCall = new MakeAPICall({
      apiPath: `table/generate-formula/${tableId}`,
      payload: {
        ...payload,
        queryParams,
      },
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: {
        formula: string;
        usedTableColumns: Column[];
        formulaAppliedRows: string[];
      };
      message?: string;
    }>();

    return response;
  }

  async addFormulaColumn(
    tableId: string,
    payload: {
      prompt?: any;
      formula: string;
      name?: string;
      totalRows: number;
      reRunColumnId?: string;
      action?: "add" | "edit";
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/add-formula-to-table/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      data?: TableData;
      message?: string;
    }>();
    return response;
  }

  async shareWithTeam(
    tableId: string,
    payload: {
      usersId: string[];
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/share-table-with-team/${tableId}`,
      payload,
    });
    const response = await apiCall.post<{
      success: boolean;
      message?: string;
      data: TableData;
    }>();
    return response;
  }

  async removeDuplicateRows(
    tableId: string,
    payload: {
      columnId: string;
      totalRows: number;
    },
  ) {
    const apiCall = new MakeAPICall({
      apiPath: `table/remove-duplicate-rows/${tableId}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getRunningProcesses({
    tableId,
    signal,
  }: {
    tableId: string;
    signal?: AbortSignal;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/running-processes`,
      apiVersion: "v2",
    });
    const response = await apiCall.get<RunningProcessesData[]>({
      signal,
    });
    return response;
  }
  async getRunningProcessesByTypes({
    types,
    signal,
  }: {
    types: string;
    signal?: AbortSignal;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/running-processes`,
      apiVersion: "v2",
      params: {
        types,
      },
    });
    const response = await apiCall.get<RunningProcessesData[]>({
      signal,
    });
    return response;
  }

  async createOrUpdateView({
    tableId,
    viewId,
    bodyData,
  }: {
    tableId: string;
    viewId: string;
    bodyData: Partial<TableView>;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/views/${viewId}`,
      payload: bodyData,
      apiVersion: "v2",
    });
    const response = await apiCall.patch();
    return response as {
      success: boolean;
      message?: string;
      data?: TableView[];
    };
  }

  async deleteView({ tableId, viewId }: { tableId: string; viewId: string }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/views/${viewId}`,
      apiVersion: "v2",
    });
    const response = await apiCall.delete();
    return response as {
      success: boolean;
      message?: string;
      data?: TableView[];
    };
  }

  async callToReRunColumn({
    tableId,
    columnId,
    payload,
  }: TableQueueReRunPayload) {
    const apiCall = new MakeAPICall({
      apiPath: `table/${tableId}/re-run-column/${columnId}`,
      payload,
    });
    const response = await apiCall.post();
    return response;
  }

  async getFolderList() {
    const apiCall = new MakeAPICall({
      apiPath: `table/list/folders`,
      apiVersion: "v2",
    });
    const response = await apiCall.get<TFoldersListItem[]>();
    if (!response.success) {
      throw response;
    }
    return response;
  }

  async deleteFolder(folderId: string, deleteChildren = false) {
    const apiCall = new MakeAPICall({
      apiPath: `table/folder/${folderId}/${deleteChildren ? "include-children" : ""}`,
      apiVersion: "v2",
    });
    const response = await apiCall.delete();
    if (!response.success) {
      throw response;
    }
    return response;
  }

  // /folder/move/:sourceType/:sourceId/:targetId
  async moveFolderItems(params: {
    sourceType: string;
    sourceId: string;
    targetId: string;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/folder/move/${params.sourceType}/${params.sourceId}/${params.targetId}`,
      apiVersion: "v2",
    });
    const response = await apiCall.put();
    if (!response.success) {
      throw response;
    }
    return response;
  }

  async renameFolder(folderId: string, name: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/folder/rename/${encodeURIComponent(folderId)}/${encodeURIComponent(name)}`,
      apiVersion: "v2",
    });
    const response = await apiCall.patch();
    if (!response.success) {
      throw response;
    }
    return response;
  }

  async createFolder(name: string, parentFolderId?: string) {
    const apiCall = new MakeAPICall({
      apiPath: `table/folder/create/${encodeURIComponent(name)}/${encodeURIComponent(parentFolderId ?? "")}`,
      apiVersion: "v2",
    });
    const response = await apiCall.post();
    if (!response.success) {
      throw response;
    }
    return response;
  }
  async generateColumns({ prompt }: { prompt: string }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/generate-schema`,
      apiVersion: "v2",
      payload: prompt,
    });
    const response = await apiCall.post();
    if (!response.data?.data?.columns) {
      console.error("Failed to generate columns", response);
      throw new Error("Failed to generate columns");
    }
    return response.data as {
      success: boolean;
      data?: {
        columns: AutoGeneratedColumnSchema[];
        pinnedColumns: string[];
        mainColumn: string;
      };
    };
  }
  async getTableInfo({
    tableIds,
    signal,
  }: {
    tableIds: string | string[];
    signal?: AbortSignal;
  }) {
    const apiCall = new MakeAPICall({
      apiPath: `table/info`,
      apiVersion: "v1",
      params: {
        tableIds: Array.isArray(tableIds) ? tableIds.join(",") : tableIds,
      },
    });
    return apiCall
      .get({
        signal,
        timeout: 5000,
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          return { data: null };
        } else {
          throw error;
        }
      })
      .then((response) => {
        if (typeof tableIds === "string") {
          return { data: response.data?.[0] };
        }
        return { data: response.data };
      });
  }
}

const tableService = new TableService();
export default tableService;
