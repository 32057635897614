import AIDraftIcon from "@/assets/svgs/ai-draft-icon.svg";
import AIDraftGenerateEmail from "@/assets/svgs/ai-draft-generate-email.svg";

const AIDraftMessageHeader = () => {
  return (
    <div className="py-[20px]">
      <div className="flex items-center justify-center gap-3">
        <img src={AIDraftGenerateEmail} alt="" width={20} height={20} />
        <p className="text-[20px] font-[600]">Generate Email</p>
        <div className="bg-[#DDD8FF] rounded-[8px] px-[8px] py-[4px] flex items-center gap-1">
          <img src={AIDraftIcon} alt="" width={16} height={16} />
          <p className="text-[13px] text-[#5024DC] font-medium">AI Draft</p>
        </div>
      </div>
      <p className="text-[13px] text-[#0F0D1C] mx-auto mt-2 font-[400] w-[60%] text-center">
        Leverage Persana's AI-powered message writer to craft personalized
        outreach that connects effectively with your potential customers.
      </p>
    </div>
  );
};

export default AIDraftMessageHeader;
