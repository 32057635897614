import { Box } from "@chakra-ui/react";

import { useEffect, useState } from "react";

// import { StatusOptions } from "./constants";
import { Option } from "../Common/MultiSelectDropdown/types";
import ScheduleCampaign from "./ScheduleCampaign";

import { useGetAllEmailAccounts } from "./hooks/useGetAllEmailAccounts";
import { useCampaignStore } from "@/stores/campaigns.store";
import { userStore } from "@/stores/user.store";

import { EmailAccount } from "@/types/campaings.types";
// import Icons from "../Icons";

const Settings = (
  {
    // status,
    // setStatus,
  }: {
    status: Option | null;
    setStatus: any;
  },
) => {
  const campaignDetails = useCampaignStore((state) => state.campaignDetails);
  const { data: allEmailAccounts } = useGetAllEmailAccounts({
    offset: 0,
    limit: 100,
  });
  const currentUser = userStore((state) => state.currentUser);

  const [senderAccounts, setSenderAccounts] = useState<EmailAccount[]>([]);

  useEffect(() => {
    const senderAccountIds = campaignDetails?.senderAccounts?.map(
      (account: any) => account.email_account_id,
    );

    const filteredData = allEmailAccounts?.data?.filter((item) => {
      if (senderAccountIds.includes(item.id)) {
        return item;
      }
    });
    filteredData && setSenderAccounts(filteredData);
  }, [allEmailAccounts?.data, campaignDetails]);

  return (
    <>
      <div className="py-[20px] px-[40px] border-b border-[#F0EFF1]">
        <p className="text-[18px] font-medium mb-1 text-[#0F0D1C]">
          Campaign Setting
        </p>
        <p className="text-[12px] text-[#4A5264]">Setup your campaign</p>
      </div>
      <div className="border border-[#D0D4DC] rounded-md mx-[40px]  my-[20px] p-[16px] flex flex-col gap-5">
        <div>
          <p className="text-[14px] text-[#0F0D1C] font-medium mb-2">
            Campaign Name:
          </p>
          <Box className="border border-[#D0D4DC] w-full p-[8px] rounded-[4px] flex items-center gap-3 px-[10px]">
            <p className="text-[14px] text-[#7E889F]">{campaignDetails.name}</p>
          </Box>
        </div>
        <div>
          <p className="text-[14px] text-[#0F0D1C] font-medium mb-2">
            Company Description:
          </p>
          <Box className="border border-[#D0D4DC] w-full p-[8px] rounded-[4px] flex items-center gap-3 px-[10px]">
            <p className="text-[14px] text-[#7E889F]">
              {currentUser?.profileInfo?.companyDescription}
            </p>
          </Box>
        </div>
        <div>
          <p className="text-[14px] text-[#0F0D1C] font-medium mb-2">
            Sender Accounts:
          </p>
          <Box className="border border-[#D0D4DC] w-full p-[8px] rounded-[4px] flex items-center flex-wrap gap-3 px-[10px]">
            {senderAccounts?.map((item: any) => (
              <div className="bg-[#F9F8F9] border border-[#F0EFF1] px-[8px] py-[4px] text-[13px] text-[#5024DC] rounded-[6px]">
                {item.from_email}
              </div>
            ))}
          </Box>
        </div>

        <div>
          <p className="text-[14px] text-[#0F0D1C] font-medium mb-2">
            Schedule Campaign:
          </p>
          <div className="flex items-center gap-6">
            <p className="text-[14px] text-[#00011599]">
              Email will be triggered based on time chosen here:
            </p>
            <ScheduleCampaign campaign={campaignDetails} />
          </div>
        </div>

        {/* <div>
          <p className="text-[14px] text-[#0F0D1C] font-medium mb-2">Status:</p>
          <Box className="border border-[#D0D4DC] w-full p-[8px] rounded-[4px] flex flex-col gap-3 px-[10px]">
            {StatusOptions?.map((item: Option) => (
              <div
                className=" flex items-center gap-2 cursor-pointer w-fit"
                onClick={() => setStatus(item as Option)}
              >
                <div
                  className={`flex items-center justify-center h-[20px] w-[20px] border  rounded-full ${status?.value === item.value ? "border-[#fff] bg-[#5024DC]" : "border-[#7E889F]"}`}
                >
                  {status?.value === item.value ? (
                    <Icons.CheckIcon className="text-[#fff]" />
                  ) : (
                    ""
                  )}
                </div>
                <p className="text-[14px] font-medium text-[#0F0D1C]">
                  {item.label}
                </p>
              </div>
            ))}
          </Box>
        </div> */}
      </div>
    </>
  );
};

export default Settings;
