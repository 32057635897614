import EmailFinderIcon from "@/assets/svgs/email-finder.svg";
import MobileFinderIcon from "@/assets/svgs/mobile-finder.svg";
import UniqueEmailFinderIcon from "@/assets/svgs/unique-email-finder.svg";
import UniqueMobileFinderIcon from "@/assets/svgs/unique-mobile-finder.svg";

export const tools = {
  emailFinder: [
    {
      id: "1",
      heading: "Unique Email Finder",
      description: "Find a lead’s email addresses.",
      path: "email-finder",
      icon: UniqueEmailFinderIcon,
    },
    {
      id: "2",
      heading: "Bulk Email Finder",
      description: "Find email addresses of a list of leads.",
      path: "bulk-email-finder",
      icon: EmailFinderIcon,
    },
  ],
  mobileFinder: [
    {
      id: "1",
      heading: "Unique Mobile Finder",
      description: "Find a lead’s mobile number.",
      path: "mobile-finder",
      icon: UniqueMobileFinderIcon,
    },
    {
      id: "2",
      heading: "Bulk Mobile Finder",
      description: "Find mobile numbers of a list of leads.",
      path: "bulk-mobile-finder",
      icon: MobileFinderIcon,
    },
  ],
};
