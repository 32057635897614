import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import Icons from "../Icons";
import AISDRProfile from "@/assets/svgs/ai-sdr-user-profile.svg";
import AISDREmptyState from "@/assets/svgs/ai-sdr-empty-state.svg";
import useLeadList from "./hooks/useLeadList";
import useInboxList from "./hooks/useInboxList";
import { stripHtmlTags } from "@/lib/utils";

// Extend dayjs with relativeTime plugin
dayjs.extend(relativeTime);

const AllInboxListGrid = ({ campaignId }: { campaignId: number }) => {
  const {
    data: allInboxMessages,
    isFetching,
    isLoading,
    refetch,
  } = useInboxList(campaignId) as any;

  const { data: allLeads } = useLeadList(campaignId) as any;

  const [inboxMessages, setInboxMessages] = useState<any[]>([]);
  const [filteredInboxMessages, setFilteredInboxMessages] = useState<any[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const updatedMessages = (allInboxMessages?.data?.data || []).map(
      (message: any) => {
        const lead = allLeads?.data?.data?.find(
          (lead: any) => lead.lead.email === message.to,
        );

        return {
          ...message,
          fullName: lead
            ? `${lead.lead.first_name || ""} ${lead.lead.last_name || ""}`
            : "Unknown",
        };
      },
    );
    setInboxMessages(updatedMessages);
    setFilteredInboxMessages(updatedMessages);
    setSelectedMessage(updatedMessages?.[0]);
  }, [allInboxMessages, allLeads]);

  const handleSearch = (value: string) => {
    setSearchInput(value);
    if (value) {
      const filteredData = inboxMessages?.filter(
        (item) => item.fullName.includes(value) || item.to?.includes(value),
      );
      setFilteredInboxMessages(filteredData);
      setSelectedMessage(filteredData?.[0]);
    } else {
      setFilteredInboxMessages(inboxMessages);
      setSelectedMessage(inboxMessages?.[0]);
    }
  };

  return (
    <Box className="ag-theme-quartz mx-4">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Box className="flex flex-row items-center justify-between mb-2">
            <></>
            <ButtonGroup size="sm" className="mb-1 !ml-auto">
              <Button
                onClick={() => refetch()}
                isLoading={isFetching}
                isDisabled={isFetching}
                variant={"outline"}
                aria-label={"Reload"}
                leftIcon={<Icons.RefreshIcon />}
              >
                Refetch Inbox Details
              </Button>
            </ButtonGroup>
          </Box>
          <Box className="flex items-center gap-6" height="calc(100vh - 200px)">
            {/* Left section */}
            <div className="border border-[#F0EFF1] rounded-[8px] bg-[#FDFCFF] !h-full !w-[25%] overflow-auto custom-scrollbar py-[20px]">
              <InputGroup width="90%" className="mb-[20px] mx-auto">
                <Input
                  style={{ borderRadius: "20px", border: "2px solid #F0EFF1" }}
                  placeholder="Search by name or email..."
                  variant="outline"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                  value={searchInput}
                />
                <InputRightElement>
                  <Icons.SearchIcon size={20} color="#9EA4B2" />
                </InputRightElement>
              </InputGroup>
              {filteredInboxMessages?.length > 0 ? (
                filteredInboxMessages?.map((item: any) => (
                  <div
                    className="flex items-start gap-4 px-[24px] py-[20px] cursor-pointer border-b border-[#F0EFF1]"
                    onClick={() => {
                      setSelectedMessage(item);
                    }}
                  >
                    <img src={AISDRProfile} alt="" height={32} width={32} />
                    <div>
                      <p className="text-[14px] text-[#373F51] font-medium mb-1">
                        {item.fullName}
                      </p>
                      <p className="text-[13px] text-[#7E889F] mb-1">
                        {item.history?.[0]?.subject?.length > 20
                          ? `${item.history?.[0]?.subject?.substring(0, 20)}...`
                          : item.history?.[0]?.subject}
                      </p>
                      <p className="text-[12px] text-[#373F51]">
                        <Tooltip
                          label={new Date(
                            item?.history?.[item?.history?.length - 1]?.time,
                          ).toLocaleString()}
                        >
                          {dayjs(
                            item?.history?.[item?.history?.length - 1]?.time,
                          ).format("D MMMM")}
                        </Tooltip>
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col gap-1 items-center justify-center p-[16px]">
                  <p className="text-[15px] text-[#373F51] font-medium">
                    Nothing to see here...
                  </p>
                  <p className="text-[13px] text-[#7E889F]">
                    Create an active campaign to begin
                  </p>
                </div>
              )}
            </div>

            {/* Right section */}
            <div className="border border-[#F0EFF1] rounded-[8px]  !h-full !w-[75%] overflow-auto custom-scrollbar">
              {selectedMessage ? (
                <>
                  <div className="flex items-center gap-3 border-b border-[#F0EFF1] p-[16px]">
                    <img src={AISDRProfile} alt="" height={24} width={24} />
                    <p className="text-[14px] text-[#0F0D1C]">
                      {selectedMessage?.fullName}
                    </p>
                    <div className="px-[8px] py-[6px] bg-[#F5F3FF] rounded-[6px]">
                      <p className="text-[14px] text-[#5024DC]">
                        {selectedMessage?.to}
                      </p>
                    </div>
                  </div>
                  <div className="mt-[16px] flex flex-col gap-3 mx-[16px]">
                    {selectedMessage?.history?.map((item: any) => (
                      <div
                        className={`flex flex-col border border-[#DDD8FF] py-[24px] px-[16px] rounded-[8px] ${item?.type === "SENT" ? "bg-white" : " bg-[#F5F3FF]"}`}
                      >
                        {item.type === "SENT" ? (
                          <div className="flex items-start gap-4 mb-4 ml-auto">
                            <div>
                              <p className="text-[15px] text-[#5C34DD] ml-auto">
                                You
                              </p>
                              <p className="text-[13px] text-[#7E889F] mt-1">
                                {dayjs(item.time)
                                  .utcOffset(5.5)
                                  .format("D MMMM YYYY h:mm A")}
                              </p>
                            </div>
                            <img
                              src={AISDRProfile}
                              alt=""
                              height={32}
                              width={32}
                            />
                          </div>
                        ) : (
                          <div className="flex items-start gap-4 mb-4">
                            <img
                              src={AISDRProfile}
                              alt=""
                              height={32}
                              width={32}
                            />
                            <div>
                              <p className="text-[15px] text-[#5C34DD]">
                                {selectedMessage.fullName}
                              </p>
                              <p className="text-[13px] text-[#7E889F] mt-1">
                                {dayjs(item?.time)
                                  .utcOffset(5.5)
                                  .format("D MMMM YYYY h:mm A")}
                              </p>
                            </div>
                          </div>
                        )}

                        <p>{stripHtmlTags(item?.email_body || "")}</p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="h-full flex flex-col gap-3 items-center justify-center p-[16px]">
                  <img src={AISDREmptyState} alt="" />
                  <p className="text-[20px] text-[#373F51] font-medium">
                    Wow, so empty...
                  </p>
                  <p className="text-[14px] text-[#7E889F]">
                    Create your first active campaign to fill this void.
                  </p>
                </div>
              )}
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AllInboxListGrid;
