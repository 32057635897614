import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import ExportDataModal from "./ExportDataModal";

import leadService from "@/services/leads.service";
import { userStore } from "@/stores/user.store";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";
import { useCampaignStore } from "@/stores/campaigns.store";
import { ExportTableMapper } from "./components/ExportTableSelector/ExportTableMapperModal";

type Props = {
  buttonIcon?: JSX.Element;
  currentSearchPayload: ANY;
  isForFindEmail?: boolean;
  isForAISDR?: boolean;
  buttonText: string;
  variant: "primary" | "secondary";
  handleAISDR?: () => void;
};

const PeopleSearchResultTableActions = ({
  buttonIcon,
  currentSearchPayload,
  buttonText,
  isForFindEmail = false,
  isForAISDR = false,
  variant,
}: Props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTable, setSelectedTable] = useState<{
    _id: string;
    name: string;
    fieldMap: { [field: string]: string };
    isComplete: boolean;
  } | null>(null);
  const tableSelectorDisclosure = useDisclosure({
    defaultIsOpen: false,
  });
  const [tableId, setTableId] = useState<string>("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const currentUser = userStore((state) => state.currentUser);
  const isFreeUser = currentUser.planType?.toLowerCase() === "free";
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const isUseMixrank = userStore((state) => state.isUseMixrank);
  const selectedCampaign = useCampaignStore((state) => state.selectedCampaign);
  const updatedLeadState = useSearchLeadsStore((state) => state.updateState);

  // Show error if no leads are selected
  useEffect(() => {
    if (!selectedLeads.length && tableSelectorDisclosure.isOpen) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the leads.",
        timer: 2000,
        timerProgressBar: true,
      });
      tableSelectorDisclosure.onClose();
      return;
    }
  }, [tableSelectorDisclosure]);
  const { mutateAsync, isPending: isAddingToTable } = useMutation({
    mutationFn: ({
      tableId,
      fieldMap,
    }: {
      tableId?: string;
      fieldMap?: { [field: string]: string };
    }) => {
      const customSelectionNumber = isFreeUser
        ? Math.min(25, selectionCount)
        : selectionCount;

      const payload = {
        ...(currentSearchPayload || {}),
        cacheKey: searchLeadsResult?.cacheKey,
        isForFindEmail,
        isForAISDR,
        campaignId: selectedCampaign,
        selectedLeads: ["select-all", "range-selection"].includes(
          selectionAction,
        )
          ? []
          : Array.from(new Set(selectedLeads?.map((item) => item._id))),
        customSelectionNumber:
          selectionAction === "range-selection" ? customSelectionNumber : 0,
        isSelectAll: selectionAction === "select-all",
        totalEntries: searchLeadsResult?.pagination?.total_entries || 0,
        isForMixrank: isUseMixrank,
        exportFieldMapping: fieldMap,
      } as ANY;
      console.log("selectionAction", selectionAction);

      return isForAISDR
        ? leadService.addLeadsToCampaign(payload)
        : leadService.addLeadsToTable(payload, tableId);
    },
  });

  const handleView = () => {
    window.open(`${window.location.origin}/table/${tableId}`, "_blank");
  };

  const handleImportLeads = async (table?: typeof selectedTable) => {
    if (!selectedLeads.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the leads.",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }

    await mutateAsync(
      {
        tableId: table?._id || selectedTable?._id,
        fieldMap: table?.fieldMap || selectedTable?.fieldMap,
      },
      {
        onSuccess: (response: ANY) => {
          if (!response?.data?.tableData) return;
          tableSelectorDisclosure.onClose();
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          setTableId(response?.data?.tableData._id!);
          queryClient.refetchQueries({
            queryKey: ["user-credit-details"],
          });
          if (isForFindEmail) {
            updatedLeadState({
              selectionAction: "",
            });
            onOpen();
            setIsExportModalOpen(false);
          } else {
            toast({
              title: "Success",
              description: (
                <p>
                  Leads have been successfully imported to the table. <br />
                  <a
                    href={`/table/${response?.data?.tableData._id}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-lg font-bold text-white underline"
                  >
                    View Table
                  </a>
                </p>
              ),
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            window.open(
              `${window.location.origin}/table/${response?.data?.tableData._id}`,
              "_blank",
            );
          }
        },
        onError: (error) => {
          console.log(error);
          toast({
            title: "Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          // setIsAddingToTable(false);
        },
      },
    );
  };

  const handleExportData = () => {
    if (!selectedLeads.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select the leads.",
        timer: 2000,
        timerProgressBar: true,
      });
      return;
    }
    setIsExportModalOpen(true);
  };

  return (
    <>
      <div className="flex shrink-0 justify-end">
        <Button
          size={"sm"}
          isLoading={isAddingToTable}
          loadingText={isForFindEmail ? "Exporting..." : "Importing..."}
          variant="outline"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() => {
            isForAISDR ? handleImportLeads() : tableSelectorDisclosure.onOpen();
          }}
          leftIcon={buttonIcon}
          className={`!hover:text-inherit !h-[35px] !w-fit !rounded-[8px] !px-4 !py-1.5 !font-title !text-sm !font-semibold !tracking-wide ${variant === "primary" ? "!border !border-[#C03EDC] !bg-[#F5F3FF] !text-[#373F51]" : "!bg-[#FFFFFF] !text-[#373F51]"} ${buttonText.includes("Add to Persana Table & Enrich") ? "joyride-import-leads" : "joyride-export-find-email"}`}
        >
          {buttonText}
        </Button>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Export</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              Your email export is processing. You will receive an email with a
              link to the list.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button variant={"outline"} colorScheme="blue" onClick={handleView}>
              View
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ExportDataModal
        isShowExportModal={isExportModalOpen}
        handleClose={() => setIsExportModalOpen(false)}
        handleExport={handleImportLeads}
        selectedLeadsCount={selectedLeads.length}
        isLoading={isAddingToTable}
      />
      <ExportTableMapper
        disclosure={tableSelectorDisclosure}
        isLoading={isAddingToTable}
        onConfirm={(table) => {
          setSelectedTable(table);
          if (isForFindEmail) {
            handleExportData();
            tableSelectorDisclosure.onClose();
          } else handleImportLeads(table);
        }}
        buttonLabel={buttonText}
        buttonIcon={buttonIcon}
        variant={variant}
        isForEmailEnrichment={isForFindEmail}
      />
    </>
  );
};

export default PeopleSearchResultTableActions;
