import { usePresets } from "@/hooks/usePresets";
import { usePresetsStore } from "@/stores/presets.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Icons from "@/components/Icons";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";
import { convertTextToSlate } from "@/utils";

interface Props {
  personalization: any;
  selectedPreference: string;
  personalizationPromptPreview: string;
  isLoading: boolean;
  setPersonalization: (val: any) => void;
  handlePreviewClick: (forPersonalizationPromptPreview: boolean) => void;
}

const PersonalizationPrompt = ({
  selectedPreference,
  personalization,
  personalizationPromptPreview,
  isLoading,
  setPersonalization,
  handlePreviewClick,
}: Props) => {
  const { selectedPreset: presetFromStore } = usePresetsStore();
  const updateState = usePresetsStore((state) => state.updateState);
  const { data: presetData } = usePresets("ai-draft-email");
  const [selectedColumns, setSelectedColumns] = useState<
    {
      placeholderValue: string;
      value: TSelectedColumnOption;
    }[]
  >([]);
  const [selectedPreset, setSelectedPreset] = useState<any>();

  useEffect(() => {
    const newSelectedPreset =
      presetData?.find((item) => item.name === selectedPreference) || null;
    setSelectedPreset(newSelectedPreset);
  }, [presetData, selectedPreference]);

  useEffect(() => {
    if (presetFromStore) {
      setSelectedPreset(presetFromStore);
      setSelectedColumns([]);
    }
  }, [presetFromStore]);

  useEffect(() => {
    let modifiedPrompts = selectedPreset?.prompt || "";

    selectedColumns.forEach((item) => {
      const placeholder = `{{${item.placeholderValue}}}`;
      if (modifiedPrompts.includes(placeholder)) {
        modifiedPrompts = modifiedPrompts.replace(
          placeholder,
          `{{${item.value.key}}}`,
        );
      }
    });
    const slateFormattedPrompt = convertTextToSlate(modifiedPrompts);
    setPersonalization(slateFormattedPrompt);
  }, [selectedColumns, selectedPreset, setPersonalization]);

  return (
    <div className="flex items-start gap-3">
      <VStack align="stretch" spacing={3} className="w-[55%]">
        <p className="text-sm italic text-[#4A5264]">
          {`Note:  In this step, we will run the below prompt to add the AI personalization for each lead. Please map your corresponding columns.`}
        </p>

        <div className="border border-[#C03EDC] rounded-[4px]">
          <div className="bg-[#FDFCFF] px-[16px] py-[10px] rounded-t-[4px]">
            <p className="text-[#0F0D1C] text-[14px] font-medium">
              {presetFromStore?.name || selectedPreference}
            </p>
            <div className="flex items-center gap-2 mt-1">
              <Icons.InformationIcon />
              <p className="text-[13px] text-[#7E889F]">
                Create tailored emails effortlessly with AI using your custom
                prompt
              </p>
            </div>
          </div>
          <div className="p-[10px]">
            <HStack justify="space-between" className="mb-2">
              <Text fontSize="sm" fontWeight="bold">
                AI Personalization
              </Text>
              <Button
                onClick={() =>
                  updateState({
                    isOpenPresetsModal: true,
                    presetsModalData: { modalType: "ai-draft-email" },
                  })
                }
                size="sm"
                variant="outline"
                borderRadius="md"
                px={6}
                py={2}
                className="!bg-[#F5F3FF] !text-[#5024DC]"
              >
                Change Template
              </Button>
            </HStack>

            <CustomTextEditor
              slateValue={personalization}
              setSlateValue={(newInputText) => {
                setPersonalization(newInputText);
              }}
            />
          </div>
        </div>
      </VStack>
      <div className="w-[45%] h-full">
        <HStack align={"center"} justify="space-between">
          <Text fontSize="sm" fontWeight="bold">
            Preview
          </Text>
          <Button
            onClick={() => handlePreviewClick(true)}
            size="sm"
            variant="outline"
            borderRadius="md"
            px={6}
            py={2}
            className="!bg-[#F5F3FF] !border !border-[#C03EDC] !text-[#C03EDC] text-[14px] !h-[30px]"
            isLoading={isLoading}
          >
            {personalizationPromptPreview
              ? "Regenerate Preview"
              : "Generate Preview"}
          </Button>
        </HStack>
        <div className="border border-[#D0D4DC] rounded-[6px] px-[12px] py-[8px] flex items-start mt-5 h-[330px] overflow-auto custom-scrollbar">
          <p className="text-[14px] text-[#7E889F]">
            {personalizationPromptPreview
              ? personalizationPromptPreview
              : "No preview available"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalizationPrompt;
