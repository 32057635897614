import { useEffect, useRef, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import { useEnrichStore } from "@/stores/enrich.store";
import { preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";

import CustomTextEditor from "../../Common/CustomTextEditor";
import { Descendant } from "slate";
import { Switch } from "@chakra-ui/react";

interface EnrichmentDataType extends EnrichmentMetaData {
  extraColumns?: string[];
  excludedJobTitles?: Option[];
  includedJobTitles?: Option[];
  isPrioritizeJobTitle?: boolean;
  companyNameId?: string;
}

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const FindLinkedinUrlPersana = () => {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [firstName, setFirstName] = useState<Descendant[]>(initialSlateValue);
  const [lastName, setLastName] = useState<Descendant[]>(initialSlateValue);
  const [isOnlyReturnFirst, setIsOnlyReturnFirst] = useState(true);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        setFirstName(enrichmentData.firstName || initialSlateValue);
        setLastName(enrichmentData.lastName || initialSlateValue);
        setIsOnlyReturnFirst(enrichmentData.onlyReturnFirstProfile);
      }

      return;
    }
  }, [viewMode, selectedColumnToEdit]);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <>
      <div
        className="max-h-[70vh]  grow overflow-y-auto p-4"
        ref={modalBodyRef}
      >
        <div className="flex w-full items-center justify-between pb-1">
          <p className="pb-1 text-base font-semibold">Company Name</p>
          <div className="flex items-center space-x-2">
            <label
              htmlFor="only-retun-first-profile-FPAC"
              className="text-gray-400 text-base font-medium"
            >
              Only return first profile
            </label>
            <Switch
              size={"md"}
              isChecked={isOnlyReturnFirst}
              id={`only-retun-first-profile-FPAC`}
              onChange={() => setIsOnlyReturnFirst((prev) => !prev)}
            />
          </div>
        </div>
        <div>
          {/* <p className="pb-1.5 text-xs">
            Company domain/website or company Id or Company Name
          </p> */}
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="space-y-4 mt-4">
          <div>
            <p className="pb-1 text-base font-semibold">First Name</p>
            <CustomTextEditor
              editorHeight={"2rem"}
              slateValue={firstName}
              setSlateValue={(val) => setFirstName(val)}
              placeholder={`Start typing or use the dropdown to select a column.`}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">Last Name</p>
            <CustomTextEditor
              editorHeight={"2rem"}
              slateValue={lastName}
              setSlateValue={(val) => setLastName(val)}
              placeholder={`Start typing or use the dropdown to select a column.`}
            />
          </div>
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.key && !firstName && !lastName}
        apiPayload={{
          columnId: selectedOption?.keyId,
          firstName,
          lastName,
          isPrioritizeJobTitle: false,
          isUseOwnKey: false,
          onlyReturnFirstProfile: isOnlyReturnFirst,
        }}
      />
    </>
  );
};

export default FindLinkedinUrlPersana;
