import { Button } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { tools } from "./constants";
import Header from "./Header";
import ToolsBg from "@/assets/images/tools-bg.png";
import MobileFinderIcon from "@/assets/svgs/mobile-finder-icon.svg";
import EmailFinderIcon from "@/assets/svgs/email-finder-icon.svg";
import { useState } from "react";
import StarterPlaybooksList from "../StarterPlaybooksList";

const Tools = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<
    "emailFinder" | "mobileFinder"
  >("emailFinder");

  return (
    <div
      className="bg-cover bg-center bg-no-repeat min-h-screen"
      style={{ backgroundImage: `url(${ToolsBg})` }}
    >
      <Header />
      <div className="w-fit mx-auto flex items-center justify-center gap-3 rounded-md bg-[#F5F3FF] p-[4px]">
        <div
          className={`flex items-center gap-2 p-[12px] rounded-[6px] ${selectedTab === "emailFinder" && "border border-[#C03EDC] bg-white"} cursor-pointer`}
          onClick={() => setSelectedTab("emailFinder")}
        >
          <img src={EmailFinderIcon} alt="" width={16} height={16} />
          <p className="text-[14px] font-medium text-[#0F0D1C]">
            Email Waterfall
          </p>
        </div>
        <div
          className={`flex items-center gap-2 p-[12px] rounded-[6px] ${selectedTab === "mobileFinder" && "border border-[#C03EDC] bg-white"} cursor-pointer`}
          onClick={() => setSelectedTab("mobileFinder")}
        >
          <img src={MobileFinderIcon} alt="" width={16} height={16} />
          <p className="text-[14px] font-medium text-[#0F0D1C]">
            Mobile Waterfall
          </p>
        </div>
      </div>
      <div className="grid select-none grid-cols-1 gap-5 px-[32px] py-[16px] sm:grid-cols-2 md:grid-cols-2 w-[70%] mx-auto">
        {tools[selectedTab]?.map((item) => (
          <div
            key={item.id}
            className="border border-[#D0D4DC] rounded-[12px] p-[24px] flex flex-col gap-4 justify-center items-center bg-white"
          >
            <img src={item.icon} alt="" height={40} width={40} />
            <div className="flex flex-col items-center justify-center">
              <p className="text-[16px] text-[#0F0D1C] font-[600]">
                {item.heading}
              </p>
              <p className="text-[13px] text-[#7E889F]">{item.description}</p>
            </div>
            <Button
              className="!w-full !h-[32px] !border !border-[#C03EDC] !rounded-[20px] !bg-[#F5F3FF] !text-[14px] !font-medium  !text-[#373F51]"
              // @ts-ignore
              onClick={() => navigate({ to: item.path })}
            >
              Try Now
            </Button>
          </div>
        ))}
      </div>
      <div className="w-[66%] pb-[40px] mx-auto">
        <StarterPlaybooksList showFindWorkEmail={false} />
      </div>
    </div>
  );
};

export default Tools;
