import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Icons from "../../Icons";
import campaignService from "@/services/campaign.service";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient";
import { useState } from "react";
import { getParsedCsvData } from "@/utils";
import ImportLeadsFromScratch from "./ImportLeadsFromScratch";

import {
  createSearchLeadsStore,
  TabContext,
} from "@/components/PeopleSearch/store";

const predefinedColumns = [
  {
    label: "First Name",
    value: "first_name",
  },
  {
    label: "Last Name",
    value: "last_name",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Phone Number",
    value: "phone_number",
  },
  {
    label: "Company Name",
    value: "company_name",
  },
  {
    label: "Website",
    value: "website",
  },
  {
    label: "Location",
    value: "location",
  },
  {
    label: "LinkedIn URL",
    value: "linkedin_profile",
  },
  {
    label: "Company URL",
    value: "company_url",
  },
  {
    label: "AI Personalization",
    value: "ai_personalization",
  },
];

const ImportLeads = ({ campaignId }: { campaignId: number }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [uploadedColumns, setUploadedColumns] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    company_name: "",
    website: "",
    location: "",
    linkedin_profile: "",
    company_url: "",
    ai_personalization: "",
  });
  const [selectedFile, setSelectedFile] = useState<any>();

  const toast = useToast();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ formData }: { formData: FormData }) =>
      campaignService.uploadLeads(formData),
  });

  const handleFileChange = async (e: any) => {
    const file = e.target.files?.[0] || null;
    if (file?.type !== "text/csv") {
      toast({
        title: "Invalid file type",
        description: "Please upload a CSV file",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const columnNames = (await getParsedCsvData(file)) as any;
    setSelectedFile(file);

    if (columnNames.columns) {
      onOpen();
      setUploadedColumns(columnNames.columns?.filter(Boolean));
    }
  };

  const handleSubmit = async () => {
    /*
      Minimum valid Inputs
        Email
    */
    if (!selectedOptions.email) {
      // show error
      toast({
        position: "top-right",
        title: "Please select Email",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    selectedFile && formData.append("csvFile", selectedFile);
    // @ts-ignore
    formData.append("campaignId", campaignId);
    formData.append("first_name", selectedOptions.first_name);
    formData.append("last_name", selectedOptions.last_name);
    formData.append("email", selectedOptions.email);
    formData.append("linkedin_profile", selectedOptions.linkedin_profile);
    formData.append("phone_number", selectedOptions.phone_number);
    formData.append("company_name", selectedOptions.company_name);
    formData.append("website", selectedOptions.website);
    formData.append("location", selectedOptions.location);
    formData.append("company_url", selectedOptions.company_url);
    formData.append("ai_personalization", selectedOptions.ai_personalization);

    await mutateAsync(
      {
        formData,
      },
      {
        onSuccess(response: any) {
          if (response?.success) {
            toast({
              title: "Success",
              description: "Successfully added leads!",
              status: "success",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            setTimeout(() => {
              queryClient.refetchQueries({
                queryKey: ["leadList"],
              });
            }, 1000);
          } else {
            toast({
              title: "Error",
              description: response?.error?.message || "Failed to upload CSV!",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
        onError(error) {
          toast({
            title: "Error",
            description: error?.message || "Failed to upload CSV!",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        },
      },
    );

    setSelectedOptions({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      company_name: "",
      website: "",
      location: "",
      linkedin_profile: "",
      company_url: "",
      ai_personalization: "",
    });
    setSelectedFile(null);
    setUploadedColumns([]);
    onClose();
  };

  return (
    <>
      <TabContext.Provider value={createSearchLeadsStore("campaign_1")}>
        <div className="w-full flex flex-col gap-2">
          <div className="!bg-[#FDFCFF] py-[20px] border-b border-[#F0EFF1] flex items-center justify-center">
            <div className="border border-[#C03EDC] rounded -[12px] py-[12px] px-[16px] bg-white w-[80%]">
              <p className="text-[14px] text-[#0F0D1C] font-medium">
                Import CSV
              </p>
              <p className="text-[12px] text-[#4A5264] my-1">
                Add leads from your device
              </p>
              <label className="block text-center cursor-pointer flex flex-col items-center justify-center gap-3 w-fit">
                <div className="text-center bg-[#fff] border border-[#F0EFF1] rounded-[8px] py-[8px] px-[12px] flex items-center gap-2">
                  <Icons.ImportIcon />
                  <p className="text-[#373F51] text-[14px]">Import CSV</p>
                </div>
                <input
                  type="file"
                  accept=".csv"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>
          <div className="flex items-center justify-center !mb-6">
            <ImportLeadsFromScratch />
          </div>
        </div>
      </TabContext.Provider>

      {/* Columns Mapping for CSV Upload */}
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalHeader className="!py-[20px]">
            <p className="text-[20px]">Organize Columns</p>
          </ModalHeader>
          <ModalBody>
            <div className="grid grid-cols-2 items-center gap-3">
              {predefinedColumns.map((column) => (
                <Card key={column.label} className="mb-2">
                  <CardBody className="border border-[#D0D4DC] rounded-[4px] !py-[14px] !px-[16px]">
                    <div className="flex items-center justify-between">
                      <Text className="flex-1 text-[14px] text-[#0F0D1C]">
                        {column.label}{" "}
                        <span className="text-red-400">
                          {column.label === "Email" && "*"}
                        </span>
                      </Text>
                      <div className="flex-1">
                        <Select
                          size={"sm"}
                          placeholder="Select Column"
                          onChange={(selectedItem) => {
                            setSelectedOptions((prevState: any) => ({
                              ...prevState,
                              [column.value]: selectedItem.target.value,
                            }));
                          }}
                          value={selectedOptions[column.value]}
                        >
                          {uploadedColumns.map((column) => (
                            <option value={column} key={column}>
                              {column}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
            <div className="mb-5 mt-3 flex w-full justify-center">
              <Button
                className="!w-full !bg-[#C03EDC] !text-white !border-none h-[40px] rounded-md"
                onClick={handleSubmit}
                isLoading={isPending}
              >
                Submit
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImportLeads;
