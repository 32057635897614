import { useSearchLeadsStore } from "@/components/PeopleSearch/store";
import { Tabs, TabsProps, useToast } from "@chakra-ui/react";
import { useSearch } from "@tanstack/react-router";
import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { createStore, StoreApi, useStore } from "zustand";

interface TabControllerState {
  activeTabIndex: number;
  previousTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

const createTabContextState = () =>
  createStore<TabControllerState>((set) => ({
    activeTabIndex: 0,
    previousTabIndex: 0,
    setActiveTabIndex: (index) =>
      set((state) => ({
        activeTabIndex: index,
        previousTabIndex:
          index === state.activeTabIndex
            ? state.previousTabIndex
            : state.activeTabIndex,
      })),
  }));

const TabControllerContext = createContext<StoreApi<TabControllerState> | null>(
  null,
);
interface SearchType {
  type: "companies" | "people" | "savedSearch";
  from?: "ai" | "manual";
}

const tabMapping = ["people-search", "company-search", "saved-search"];
// ??? Why was this generic component destroyed???? - Why was people-search, company-search specific code added to this component?
export const ControlledTabs = ({
  children,
  ...props
}: { children: ReactNode } & TabsProps) => {
  const urlQueries = useSearch({
    strict: false,
  }) as SearchType;
  const toast = useToast();
  const store = useMemo(() => createTabContextState(), []);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);

  const activeTabIndex = useStore(store, (state) => state.activeTabIndex);

  const handleChange = useCallback((index: number) => {
    store.setState({ activeTabIndex: index });
    if (index != 2) updateLeadState({ sidebarActiveTab: tabMapping[index] });
  }, []);

  const showCompanySearchPopup = useCallback(() => {
    const isAlreadyShown = localStorage.getItem("companySearchPopup");
    if (!isAlreadyShown) {
      toast({
        description:
          "Access 200M companies by adding filters and queries on the left pane",
        status: "info",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
      localStorage.setItem("companySearchPopup", "true");
    }
  }, [toast]);

  useEffect(() => {
    if (urlQueries.type === "companies") {
      handleChange(1);
      if (urlQueries.from === "ai") {
        // Show popup and store in local storage
        showCompanySearchPopup();
      }
    }
  }, [urlQueries, handleChange, showCompanySearchPopup]);

  return (
    <TabControllerContext.Provider value={store}>
      <Tabs
        onChange={(index) => {
          handleChange(index);
        }}
        index={activeTabIndex}
        {...props}
      >
        {children}
      </Tabs>
    </TabControllerContext.Provider>
  );
};

export const useTabController = () => {
  const store = useContext<StoreApi<TabControllerState> | null>(
    TabControllerContext,
  );
  if (!store) {
    throw new Error(
      "useTabController must be used within a TabControllerProvider",
    );
  }
  return useStore(store);
};
