import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";

const ColumnMapping = ({
  selectedOptions,
  setSelectedOptions,
  uploadedColumns,
  handleSubmit,
  isPending,
  isOpen,
  onClose,
}: any) => {
  const predefinedColumns = [
    {
      label: "Linkedin URL",
      value: "linkedinUrl",
    },
  ];

  return (
    <>
      {/* Columns Mapping for CSV Upload */}
      <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent>
          <ModalHeader className="!py-[20px]">
            <p className="text-[20px]">Map Columns</p>
          </ModalHeader>
          <ModalBody>
            <div className="grid grid-cols-1 items-center gap-3">
              {predefinedColumns.map((column) => (
                <Card key={column.label} className="mb-2">
                  <CardBody className="border border-[#D0D4DC] rounded-[4px] !py-[14px] !px-[16px]">
                    <div className="flex items-center justify-between">
                      <Text className="flex-1 text-[14px] text-[#0F0D1C]">
                        {column.label}
                      </Text>
                      <div className="flex-1">
                        <Select
                          size={"sm"}
                          placeholder="Select Column"
                          onChange={(selectedItem) => {
                            setSelectedOptions((prevState: any) => ({
                              ...prevState,
                              [column.value]: selectedItem.target.value,
                            }));
                          }}
                          value={selectedOptions[column.value]}
                        >
                          {uploadedColumns.map((column: any) => (
                            <option value={column} key={column}>
                              {column}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
            <div className="mb-5 mt-3 flex w-full justify-center">
              <Button
                className="!w-fit !bg-[#C03EDC] !text-white !border-none h-[40px] rounded-md"
                onClick={handleSubmit}
                isLoading={isPending}
              >
                Submit
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ColumnMapping;
