import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ButtonGroup,
  Button,
  IconButton,
  ModalFooter,
  UseDisclosureReturn,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import Icons from "../../../Icons";
import { useState } from "react";
import { TablePicker } from "../../../TablePicker";
import { ExportMapper } from "./ExportMapper";
interface Props {
  disclosure: UseDisclosureReturn;
  buttonLabel: string;
  buttonIcon?: JSX.Element;
  onConfirm: (
    table: {
      _id: string;
      name: string;
      fieldMap: { [field: string]: string };
      isComplete: boolean;
    } | null,
  ) => void;
  isForEmailEnrichment: boolean;
  isLoading: boolean;
  variant: "primary" | "secondary";
}
export const ExportTableMapper = ({
  disclosure,
  buttonIcon,
  isLoading,
  isForEmailEnrichment,
  onConfirm,
}: Props) => {
  const [selectedTable, setSelectedTable] = useState<{
    _id: string;
    name: string;
  } | null>(null);
  const [fieldMapping, setFieldMapping] = useState<{
    fieldMap: { [field: string]: string };
    isComplete: boolean;
  }>({
    fieldMap: {},
    isComplete: false,
  });
  const tableSelectorDisclosure = useDisclosure({
    defaultIsOpen: false,
  });
  console.log("table::", selectedTable, fieldMapping);
  return (
    <>
      <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="flex items-center gap-2">
            <Icons.ViewTableIcon />
            Choose or Create Table
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="relative max-h-[400px] overflow-auto">
            <Flex className="gap-2" flexDir={"column"}>
              <Flex
                position={"sticky"}
                justifyContent="center"
                alignItems="center"
              >
                <ButtonGroup isAttached>
                  <Button
                    fontWeight={selectedTable?._id ? "auto" : "thin"}
                    size={"sm"}
                    w={"full"}
                    leftIcon={<Icons.ChevronDownIcon />}
                    variant={"outline"}
                    onClick={() => {
                      disclosure.onClose();
                      tableSelectorDisclosure.onOpen();
                    }}
                  >
                    {selectedTable?._id
                      ? selectedTable?.name?.slice(0, 40) +
                        (selectedTable?.name?.length > 40 ? "..." : "")
                      : "Select a Table"}
                  </Button>
                  <IconButton
                    isDisabled={!selectedTable}
                    aria-label="Clear Selection"
                    size={"sm"}
                    variant={"outline"}
                    icon={<Icons.CloseIcon />}
                    onClick={() => {
                      setSelectedTable(null);
                    }}
                  />
                </ButtonGroup>
              </Flex>
              {selectedTable?._id && (
                <>
                  <Divider className="my-2" />
                  <ExportMapper
                    isForEmailEnrichment={isForEmailEnrichment}
                    selectedTableId={selectedTable?._id || ""}
                    setMapping={setFieldMapping}
                  />
                </>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter>
            {/* Cancel Button */}
            <Button
              leftIcon={<Icons.PlusIcon />}
              variant="outline"
              size={"sm"}
              onClick={() => {
                setSelectedTable(null);
                onConfirm(null);
                disclosure.onClose();
                tableSelectorDisclosure.onClose();
              }}
              mr={3}
              className="px-2 !font-normal"
            >
              Create New Table
            </Button>

            <Button
              size={"sm"}
              colorScheme="purple"
              isDisabled={
                !!(selectedTable && !fieldMapping?.isComplete) || !selectedTable
              }
              isLoading={isLoading}
              variant="outline"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              onClick={() => {
                onConfirm(
                  selectedTable && fieldMapping?.isComplete
                    ? { ...selectedTable, ...fieldMapping }
                    : null,
                );
              }}
              leftIcon={buttonIcon || <Icons.ExportIcon />}
              // className={`!hover:text-inherit !h-[35px] !w-fit !rounded-[8px] !px-4 !py-1.5 !font-title !text-sm !font-semibold !tracking-wide ${variant === "primary" ? "!border !border-[#C03EDC] !bg-[#F5F3FF] !text-[#373F51]" : "!bg-[#FFFFFF] !text-[#373F51]"} ${buttonLabel.includes("Add to Persana Table & Enrich") ? "joyride-import-leads" : "joyride-export-find-email"}`}
            >
              Add to Selected Table
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <TablePicker
        onTableSelect={(table) => {
          setSelectedTable(table);
          disclosure.onOpen();
          tableSelectorDisclosure.onClose();
        }}
        disclosure={tableSelectorDisclosure}
      />
    </>
  );
};
