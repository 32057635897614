import { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import Settings from "./Settings";
import DefaultPrompt from "./DefaultPrompt";
import PersonalizationPrompt from "./PersonalizationPrompt";
import AIDraftMessageHeader from "./Header";
import TemplatePreference from "./TemplatePreference";
import RightSection from "./RightSection";

import Icons from "@/components/Icons";
import { useModalStore } from "@/stores/modal.store";
import { useProcessingStore } from "@/stores/processing.store";
import { userStore } from "@/stores/user.store";
import { usePresetsStore } from "@/stores/presets.store";
import { TSelectedColumnOption } from "@/types/common.types";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import misServices from "@/services/misc.services";
import userService from "@/services/user.service";
import { useTableStore } from "@/stores/table.store";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText, convertTextToSlate } from "@/utils";

const DraftEmail = () => {
  const toast = useToast();
  const tableId = useTableStore((state) => state.tableData._id);
  const bgColor = useColorModeValue("white", "gray.800");
  const [tabData, setTabData] = useState([
    { id: 1, label: "Preferences" },
    { id: 2, label: "Your Details" },
    { id: 4, label: "Personalization" },
    { id: 3, label: "Overview" },
  ]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [mailDetails, setMailDetails] = useState<{ subject: any; body: any }>({
    subject: [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ],
    body: [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ],
  });
  const [subjectPreview, setSubjectPreview] = useState("");
  const [personalizationPromptPreview, setPersonalizationPromptPreview] =
    useState("");
  const [emailTemplatePreview, setEmailTemplatePreview] = useState("");
  const [personalization, setPersonalization] = useState<any>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  const [selectedOption] = useState<TSelectedColumnOption | null>(null);
  const [selectedPreference, setSelectedPreference] = useState<Option | null>(
    null,
  );

  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const processesData = useProcessingStore((state) => state.processingData);
  const updateState = useModalStore((state) => state.updateState);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const currentUser = userStore((state) => state.currentUser);
  const setCurrentUser = userStore((state) => state.setCurrentUser);
  const [profileInfo, setProfileInfo] = useState(() => ({
    firstName: currentUser?.profileInfo?.firstName || "",
    companyName: currentUser?.profileInfo?.companyName || "",
    companyDescription: currentUser?.profileInfo?.companyDescription || "",
    callToAction: currentUser?.profileInfo?.callToAction || "",
  }));

  const selectedPresetValues = usePresetsStore(
    (state) => state.selectedPresetValues,
  );
  const updatePresetState = usePresetsStore((state) => state.updateState);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const enrichmentMetaData = selectedColumnToEdit?.metaData?.enrichmentMetaData;

  const { mutateAsync: previewMutateAsync, isPending: isGeneratingPreview } =
    useMutation({
      mutationFn: (forPersonalizationPromptPreview: boolean) =>
        misServices.getDraftEmailPreview({
          prompts: convertSlateToText(mailDetails?.body)?.text,
          subject: convertSlateToText(mailDetails.subject).text,
          aiPersonalization: convertSlateToText(personalization).text,
          isStatic: selectedPreference?.value === "Static Email Template",
          tableId,
          forPersonalizationPromptPreview,
        }),
      onSuccess: (response) => {
        // @ts-ignore
        setEmailTemplatePreview(response?.data?.data?.draftEmailPreview || "");
        setPersonalizationPromptPreview(
          // @ts-ignore
          response?.data?.data?.personalizationPromptPreview || "",
        );
        // @ts-ignore
        setSubjectPreview(response?.data?.data?.subjectPreview);
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      },
    });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => misServices.getDraftEmailTemplate(profileInfo),
    onSuccess: (response) => {
      if (response?.data) {
        // @ts-ignore
        handleDetails("body", convertTextToSlate(response?.data));
        return;
      }
    },
    onError: () => {
      handleDetails("body", [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ]);
    },
  });

  const handleUpdateSettings = (fieldName: string, value: string) => {
    setProfileInfo({
      ...profileInfo,
      [fieldName]: value,
    });
  };

  const handleModalClose = () => {
    updateEnrichmentState({
      selectedColumnToEdit: null,
      selectedEnrichments: null,
    });
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
    updatePresetState({
      presetsModalData: {},
      selectedPresetValues: null,
      endpoint: null,
      body: null,
      headers: [],
      isOpenPresetsModal: false,
      selectedMethod: null,
      selectedPreset: null,
    });
  };

  const handleDetails = (key: string, value: any) => {
    setMailDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const handleTabsChange = (index: number) => {
    selectedPreference !== null && setActiveIndex(index);
  };

  const handleProfileUpdate = async () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
    const response = await userService.updateProfileInfo({
      email: currentUser?.Email,
      info: {
        firstName: profileInfo?.firstName,
        companyName: profileInfo?.companyName,
        companyDescription: profileInfo?.companyDescription,
        callToAction: profileInfo?.callToAction,
        lastName: currentUser.profileInfo?.lastName,
        jobTitle: currentUser.profileInfo?.jobTitle,
        companyURL: currentUser.profileInfo?.companyURL,
        tools: currentUser.profileInfo?.tools,
        assistYouWith: currentUser.profileInfo?.assistYouWith,
        companyTarget: currentUser.profileInfo?.companyTarget,
      },
    });

    if (response.success) {
      const res = await userService.getUser();
      setCurrentUser(res);
    }
  };
  const includeTemplateStep =
    selectedPreference?.label !== "Generate Email for LinkedIn Connect";

  const includePersonalizationStep =
    selectedPreference?.label !== "Static Email Template";

  useEffect(() => {
    if (isPending) {
      setEmailTemplatePreview("");
    }
  }, [isPending]);

  useEffect(() => {
    if (selectedPreference?.label === "Static Email Template") {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 3, label: "Overview" },
      ]);
    } else if (
      selectedPreference?.label === "Generate Email for LinkedIn Connect"
    ) {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 3, label: "Personalization" },
      ]);
    } else {
      setTabData([
        { id: 1, label: "Preferences" },
        { id: 2, label: "Your Details" },
        { id: 4, label: "Personalization" },
        { id: 3, label: "Overview" },
      ]);
    }
  }, [selectedPreference]);

  useEffect(() => {
    if (processesData?.length) {
      handleModalClose();
    }
  }, [processesData]);

  useEffect(() => {
    if (selectedPresetValues) {
      setPersonalization(convertTextToSlate(selectedPresetValues));
    }
  }, [selectedPresetValues]);

  useEffect(() => {
    if (enrichmentMetaData?.selectedPreference && activeIndex === 0) {
      setSelectedPreference(enrichmentMetaData?.selectedPreference || {});
    }

    if (enrichmentMetaData?.aiPersonalization && activeIndex === 2) {
      setPersonalization(
        convertTextToSlate(enrichmentMetaData?.aiPersonalization),
      );
    }

    if (enrichmentMetaData && activeIndex === 3) {
      const formattedBody = convertTextToSlate(
        enrichmentMetaData?.prompts || mailDetails.body,
      );

      const formattedSubject = convertTextToSlate(
        enrichmentMetaData?.emailData?.subject || mailDetails.subject,
      );

      setMailDetails({
        body: formattedBody,
        subject: formattedSubject,
      });
    }
  }, [enrichmentMetaData, activeIndex]);

  useEffect(() => {
    if (
      selectedPreference?.label !== "Custom Email Template" &&
      selectedPreference?.label !== "Static Email Template" &&
      selectedPreference?.label !== "Generate Email for LinkedIn Connect" &&
      !enrichmentMetaData?.prompts &&
      activeIndex === 3
    ) {
      mutateAsync();
    }
  }, [activeIndex, selectedPreference, enrichmentMetaData]);

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "draft-email"}
      motionPreset="scale"
      size={"6xl"}
    >
      <ModalOverlay />
      <ModalContent className="rounded-[20px]">
        <ModalCloseButton />
        <ModalHeader className="bg-[#F5F3FF] rounded-t-[8px]">
          <AIDraftMessageHeader />
        </ModalHeader>
        <Divider />
        <ModalBody
          className={`!px-0 !pt-0 overflow-auto custom-scrollbar ${activeIndex + 1 !== tabData.length ? "!min-h-[500px] !max-h-[500px]" : "!min-h-[420px] !max-h-[420px]"}`}
        >
          <div className="flex">
            <Box w="full" bg={bgColor}>
              {/* Navigation Buttons and Stepper at the top */}
              <Flex
                justify="space-between"
                alignItems="center"
                mb={4}
                flexWrap="wrap"
              >
                <Tabs
                  className="w-full"
                  // onChange={handleTabsChange}
                  index={activeIndex}
                >
                  <TabList
                    className="flex gap-8 mt-2"
                    display="flex"
                    justifyContent="center"
                  >
                    {tabData?.map(({ label }, index) => (
                      <Tab
                        key={index}
                        className="flex items-center gap-2"
                        onClick={() => handleTabsChange(index)}
                      >
                        <div
                          className={`px-2 rounded-full ${
                            activeIndex === index
                              ? "bg-[#C03EDC]"
                              : "bg-[#D0D4DC]"
                          } text-white`}
                        >
                          {index + 1}
                        </div>
                        <p
                          className={`text-[16px] font-medium ${
                            activeIndex === index
                              ? "text-[#C03EDC]"
                              : "text-[#373F51]"
                          }`}
                        >
                          {label}
                        </p>
                      </Tab>
                    ))}
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <TemplatePreference
                        selectedPreference={selectedPreference}
                        setSelectedPreference={setSelectedPreference}
                        handleModalClose={handleModalClose}
                        handleDetails={handleDetails}
                        setPersonalization={setPersonalization}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Settings
                        handleUpdateSettings={handleUpdateSettings}
                        profileInfo={profileInfo}
                      />
                    </TabPanel>
                    {includePersonalizationStep && (
                      <TabPanel>
                        <PersonalizationPrompt
                          selectedPreference={selectedPreference?.value || ""}
                          personalization={personalization}
                          personalizationPromptPreview={
                            personalizationPromptPreview
                          }
                          isLoading={isGeneratingPreview}
                          setPersonalization={setPersonalization}
                          handlePreviewClick={previewMutateAsync}
                        />
                      </TabPanel>
                    )}
                    {includeTemplateStep && (
                      <TabPanel>
                        <div className="flex items-start">
                          <DefaultPrompt
                            isLoading={isPending}
                            mailDetails={mailDetails}
                            handleDetails={handleDetails}
                          />
                          <RightSection
                            subjectPreview={subjectPreview}
                            emailTemplatePreview={emailTemplatePreview}
                            isLoading={isGeneratingPreview}
                            handlePreviewClick={previewMutateAsync}
                          />
                        </div>
                      </TabPanel>
                    )}
                  </TabPanels>
                </Tabs>
              </Flex>
            </Box>
          </div>
          <Divider />
        </ModalBody>
        <ModalFooter className="!flex !items-center">
          {activeIndex + 1 === tabData?.length ? (
            <SaveAndRunEnrichment
              showDivider={false}
              isDisabled={selectedPreference === null}
              apiPayload={{
                prompts: convertSlateToText(mailDetails?.body)?.text,
                columnId: selectedOption?.keyId,
                emailData:
                  (selectedOption?.keyId && mailDetails.subject) ||
                  mailDetails.subject
                    ? {
                        email_body: "email_body",
                        subject: convertSlateToText(mailDetails.subject).text,
                        sendTo: selectedOption?.keyId,
                        ...(includePersonalizationStep && {
                          ai_personalization: "ai_personalization",
                        }),
                      }
                    : selectedOption?.keyId && !mailDetails.subject
                      ? {
                          email_body: "email_body",
                          sendTo: selectedOption?.keyId,
                          ...(includePersonalizationStep && {
                            ai_personalization: "ai_personalization",
                          }),
                        }
                      : includePersonalizationStep
                        ? {
                            ai_personalization: "ai_personalization",
                            email_body: "email_body",
                          }
                        : { email_body: "email_body" },
                ...profileInfo,
                aiPersonalization: convertSlateToText(personalization).text,
                isStatic: selectedPreference?.value === "Static Email Template",
                selectedPreference,
              }}
              onClear={handleModalClose}
            />
          ) : (
            <ButtonGroup className="flex items-center justify-between w-full">
              <Button
                leftIcon={<Icons.LeftArrowIcon />}
                variant={"outline"}
                isDisabled={activeIndex === 0}
                onClick={() => setActiveIndex((prevIndex) => prevIndex - 1)}
                className="hover:!shadow-lg"
              >
                Back
              </Button>
              <Button
                isDisabled={selectedPreference === null}
                rightIcon={<Icons.RightArrowIcon />}
                variant={"outline"}
                onClick={() =>
                  activeIndex === 1
                    ? handleProfileUpdate()
                    : setActiveIndex((prevIndex) => prevIndex + 1)
                }
                className="hover:!shadow-lg hover:!bg-[#C03EDC] bg-[#C03EDC] border border-[#BD90F4] !text-white"
              >
                Next
              </Button>
            </ButtonGroup>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DraftEmail;
