import { useDebounce } from "@/hooks/useDebounce";
import { useUserConfig } from "@/hooks/useUserConfig";
import { Actions, State } from "@/stores/searchleads.store";
import _ from "lodash";
import { createContext, useCallback, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { create, StoreApi } from "zustand";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { HIDE_TABS } from "./constants";

export const TabContext = createContext<StoreApi<TabStoreType> | null>(null);
interface StaticType {
  name: string;
  value?: string;
}

interface OptionType {
  label: string;
  value: string;
}
export interface InputtedData {
  search_text: string;
  person_titles: OptionType[];
  person_not_titles: OptionType[];
  person_past_titles: OptionType[];
  person_seniorities: StaticType[];
  company_types: OptionType[];
  company_sizes: OptionType[];
  q_person_name: string;
  q_keywords: string;
  q_exclude_keywords: string;
  organization_ids: any;
  not_organization_ids: any;
  person_past_organization_ids: any;
  organization_industry_tag_ids: StaticType[];
  organization_num_employees_ranges: StaticType[];
  q_organization_keyword_tags: OptionType[];
  q_not_organization_keyword_tags: OptionType[];
  person_locations: OptionType[];
  person_not_locations: OptionType[];
  person_city_or_state_to_include: string;
  person_city_or_state_to_exclude: string;
  person_location_name: string;
  person_location_radius: string;
  in_house_organizations: OptionType[];
  in_house_organizations_not: OptionType[];
  in_house_organizations_past: OptionType[];
  educations: OptionType[];
  languages: OptionType[];
  seniorities: OptionType[];
  skills: OptionType[];
  certifications: OptionType[];
  followers: string;
  employee_count: string;
  min_num_connection: number | null;
  min_month_in_current_role: number | null;
  max_month_in_current_role: number | null;
  isMoreFollowers: boolean;
  isLessFollowers: boolean;
  isMoreEmployees: boolean;
  isLessEmployees: boolean;
  industries: OptionType[];
  included_industry_codes: OptionType[];
  excluded_industry_codes: OptionType[];
  expandFields: {
    [key: string]: boolean;
  };
}
export type TabStoreType = State &
  Actions<
    State & {
      sideBarInput: InputtedData;
      valueLabels: Record<string, string>;
      peopleSearchPayload: any;
      appliedPeopleSearchPayload?: any;
      tabId: string;
      assistantInput: string;
      isUseMixrank: boolean;
      sidebarActiveTab: string;
    }
  > & {
    tabId: string;
    sideBarInput: InputtedData;
    valueLabels: Record<string, string>;
    peopleSearchPayload: any;
    applyFilterData?: boolean;
    appliedPeopleSearchPayload?: any;
    assistantInput: string;
    isUseMixrank: boolean;
    sidebarActiveTab: string;
  };
type TSelector<R> = (state: TabStoreType) => R;

export const useSearchLeadsStore = <R>(selector: TSelector<R>) => {
  const store = useContext(TabContext);
  return useStoreWithEqualityFn(
    store as unknown as StoreApi<TabStoreType>,
    selector,
    (a, b) => _.isEqual(a, b),
  );
};

export const createSearchLeadsStore = (tabId: string) =>
  create<TabStoreType>((set) => ({
    tabId,
    instanceId: `people-search-${tabId}`,
    peopleSearchPayload: null,
    assistantInput: "",
    searchLeadsResult: null,
    valueLabels: {},
    searchLeadsColumnNames: [],
    searchLeadsResultRows: [],
    selectedLeads: [],
    selectionAction: "",
    selectionCount: 0,
    currentSearchPayload: {},
    isUseMixrank: true,
    isSearching: false,
    isShowErrorModal: false,
    sidebarActiveTab: "people-search",
    sideBarInput: {
      search_text: "",
      q_keywords: "",
      q_exclude_keywords: "",
      person_titles: [],
      company_types: [],
      company_sizes: [],
      person_not_titles: [],
      person_past_titles: [],
      person_seniorities: [],
      q_person_name: "",
      organization_ids: [],
      not_organization_ids: [],
      person_past_organization_ids: [],
      organization_industry_tag_ids: [],
      organization_num_employees_ranges: [],
      q_organization_keyword_tags: [],
      q_not_organization_keyword_tags: [],
      person_locations: [],
      person_not_locations: [],
      person_location_name: "",
      person_city_or_state_to_include: "",
      person_city_or_state_to_exclude: "",
      person_location_radius: "",
      in_house_organizations: [],
      in_house_organizations_not: [],
      in_house_organizations_past: [],
      educations: [],
      languages: [],
      seniorities: [],
      skills: [],
      certifications: [],
      followers: "",
      employee_count: "",
      isMoreEmployees: false,
      isLessEmployees: false,
      min_num_connection: null,
      min_month_in_current_role: null,
      max_month_in_current_role: null,
      isMoreFollowers: false,
      isLessFollowers: false,
      industries: [],
      included_industry_codes: [],
      excluded_industry_codes: [],
      expandFields: {
        person_titles: true,
        person_not_titles: true,
        person_past_titles: true,
      },
    },
    updateState: (payload) => {
      set((state) => ({ ...state, ...payload }));
    },
    updateSelectedLeads: (selectedLeads: any[]) => {
      return set((state) => {
        const uniqueLeads = Array.from(
          new Set([...(state.selectedLeads ?? {}), ...selectedLeads]),
        );
        return {
          ...state,
          selectedLeads: uniqueLeads,
          // selectionCount: uniqueLeads.length,
          // selectionAction: uniqueLeads.length == 0 ? "clear-all" : "",
        };
      });
    },
  }));
interface Tab {
  name: string;
  isNameStale: boolean;
  id: string;
  store: StoreApi<TabStoreType>;
  createdAt: Date;
}
interface ExportableTab {
  name: string;
  id: string;
  isNameStale: boolean;
  createdAt: string;
  store: {
    valueLabels: Record<string, string>;
    sideBarInput: InputtedData;
    currentSearchPayload: any;
    naturalFilters?: {
      natural_query: string;
      query_title: string;
    };
    expandedFields: {
      [key: string]: string[];
    };
  };
}
interface ExportableTabsState {
  version: number;
  tabs: ExportableTab[];
  activeTab: number;
}
interface TabState {
  version: number;
  tabs: Tab[];
  activeTab: number;
  deleteTab: (id: string) => void;
  renameTab: (id: string, name?: string) => void;
  activateTab: (id: string) => void;
  newTab: (params: {
    name?: string;
    from?: number;
    at?: number;
    id?: string;
    store?: StoreApi<TabStoreType>;
  }) => Tab;
  updateData: (payload: Partial<TabState>) => void;
  exportState: () => ExportableTabsState;
  importState: (payload: ExportableTabsState) => void;
  getOrCreateFirstStateTab: () => Tab;
}
export const useTabStore = create<TabState>((set, get) => ({
  version: 0,
  tabs: [
    {
      id: `tab-Search 1`,
      name: "Search 1",
      isNameStale: true,
      store: createSearchLeadsStore("tab-Search 1"),
      createdAt: new Date(),
    },
  ],
  exportState: () => {
    const exportData = {
      version: get().version + 1,
      tabs: get().tabs.map((tab) => {
        return {
          name: tab.name,
          id: tab.id,
          isNameStale: tab.isNameStale,
          createdAt: tab.createdAt.toISOString(),
          store: _.pick(tab.store.getState(), [
            "valueLabels",
            "sideBarInput",
            "naturalFilters",
            "currentSearchPayload",
          ]),
        };
      }),
      activeTab: get().activeTab,
    };
    return JSON.parse(JSON.stringify(exportData));
  },
  importState: (payload) => {
    const tabs = payload.tabs.map((tab) => {
      const store = createSearchLeadsStore(tab.id);
      store.setState({
        valueLabels: tab.store.valueLabels,
        sideBarInput: tab.store.sideBarInput,
        naturalFilters: tab.store.naturalFilters,
        expandedFields: tab.store.expandedFields,
        currentSearchPayload: tab.store.currentSearchPayload,
      });
      return {
        ...tab,
        createdAt: new Date(tab.createdAt),
        store,
      };
    });
    set({
      version: payload.version,
      tabs,
      activeTab:
        payload.activeTab < tabs.length && payload.activeTab >= 0
          ? payload.activeTab ?? 0
          : 0,
    });

    if (!tabs.length) {
      get().newTab({});
    }
  },
  renameTab: async (id, name) => {
    const currentName = get().tabs.find((tab) => tab.id === id)?.name;
    if (typeof name === "undefined") {
      const { isConfirmed, value } = await Swal.fire({
        input: "text",
        animation: false,
        title: "Enter new name",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputValue: currentName,
        didOpen: () => {
          Swal.getInput()?.select();
        },
        showCancelButton: true,
        confirmButtonText: "Rename",
        showLoaderOnConfirm: true,
        preConfirm: (name) => {
          if (!name) {
            Swal.showValidationMessage(`Please enter a name`);
          }
          return name;
        },
      });
      if (!isConfirmed) return;
      name = value;
    }
    const tabs = get().tabs.map((tab) => {
      if (tab.id === id) {
        return {
          ...tab,
          name: name ?? "Search Tab",
          isNameStale: false,
        };
      }
      return tab;
    });
    set({ tabs });
  },
  newTab: ({ name, from, at, id, store }) => {
    let activeTab: number = get().activeTab;
    const tab = {} as Tab;
    tab.id = id ?? `tab-${Math.random().toString(16).slice(2)}`;
    tab.createdAt = new Date();
    tab.store = store ?? createSearchLeadsStore(tab.id);
    if (typeof from === "number") {
      const fromTab = get().tabs[from];
      if (fromTab) {
        tab.name = fromTab.name ?? tab.name;
        tab.store.setState({
          ..._.cloneDeep(
            _.omit(fromTab.store.getState(), [
              "updateState",
              "instanceId",
              "tabId",
            ]),
          ),
        });
      }
    }
    if (name) tab.name = name;
    else tab.name = `Search ${get().tabs.length + 1}`;

    const tabs = [...get().tabs];
    tab.isNameStale = true;

    if (typeof at === "number") {
      tabs.splice(at, 0, tab);
      activeTab = at;
    } else if (typeof from === "number") {
      tabs.splice(from + 1, 0, tab);
      activeTab = from + 1;
    } else {
      tabs.push(tab);
      activeTab = tabs.length - 1;
    }
    set(() => ({
      tabs,
      activeTab,
    }));
    return tab;
  },
  deleteTab: (id) => {
    let activeTab = get().activeTab;
    const tabs = get().tabs.filter((tab) => tab.id !== id);
    activeTab = activeTab < tabs.length && activeTab >= 0 ? activeTab : 0;
    set(() => ({
      tabs,
      activeTab,
    }));
    if (tabs.length === 0) {
      get().newTab({});
      set({ activeTab: 0 });
    }
  },
  activeTab: 0,
  updateData: (payload) =>
    set((state) => ({
      ...payload,
      ...(typeof payload.activeTab !== "undefined" &&
      payload.activeTab in (payload.tabs ?? state.tabs)
        ? {
            activeTab: payload.activeTab,
          }
        : { activeTab: state.activeTab }),
    })),
  activateTab: (id) => {
    const index = get().tabs.findIndex((tab) => tab.id === id);
    set({ activeTab: index < 0 ? 0 : index });
  },
  getOrCreateFirstStateTab() {
    const tabs = get().tabs;
    if (HIDE_TABS) {
      if (!tabs.length) {
        return get().newTab({});
      }
      return tabs[0];
    }
    const firstStale = tabs.find((tab) => tab.isNameStale);
    if (!firstStale) {
      return get().newTab({});
    }
    return firstStale;
  },
}));
export const useSaveTabState = () => {
  const [query] = useUserConfig("persana-magic-state2");
  const config = query.data?.config?.["persana-magic-state2"] ?? null;

  const restoreState = useCallback(() => {
    if (!query || !config) return;
    const tabState = config as ExportableTabsState;
    const currentVersion = useTabStore.getState().version;
    if (config.version <= currentVersion - 1) return;
    useTabStore.getState().importState(tabState);
  }, [config, query]);
  return restoreState;
};

export const useSyncPeopleSearchState = () => {
  const [query, mutation] = useUserConfig("persana-magic-state2");
  const config = query.data?.config?.["persana-magic-state2"] ?? null;
  const tabs = useTabStore((state) => state.tabs);
  const restoreState = useSaveTabState();

  const saveStateToServer = useDebounce(2000, () => {
    const state = useTabStore.getState();
    const tabState = state.exportState();
    if (
      !query.isLoading &&
      (!config || !_.isEqual(config.tabs, tabState.tabs))
    ) {
      mutation?.mutate(tabState);
    }
  });

  useEffect(() => {
    if (HIDE_TABS) return;
    const unSubArray: any[] = [];
    const unSub = useTabStore.subscribe(saveStateToServer);
    const tabs = useTabStore.getState().tabs;
    tabs.forEach((tab) => {
      unSubArray.push(
        tab.store.subscribe(() => {
          saveStateToServer();
        }),
      );
    });
    unSubArray.push(unSub);
    return () => unSubArray.forEach((unsub) => unsub());
  }, [tabs]);

  useEffect(() => {
    if (HIDE_TABS) return;
    if (!query || !config) return;
    const currentState = useTabStore.getState().exportState();
    if (currentState.version > 1) return;
    restoreState();
  }, [config, restoreState]);
};
