import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect } from "react";

import Icons from "../Icons";
import HttpPresetsList from "./HttpPresetList";
import PresetsList from "./PresetsList";

import { usePresets } from "@/hooks/usePresets";
import { usePresetsStore } from "@/stores/presets.store";

import GptLogo from "@/assets/svgs/chatGpt.svg";
import { PresetsResponseData } from "@/types/presets.types";
import { filterButtons, modalTypeTitleMapping } from "./constants";

const PresetsModals = () => {
  const [presetsData, setPresetsData] = React.useState<any[]>([]);
  const [selectedFilter, setSelectedFilter] =
    React.useState<string>("persanaTemplates");

  const presetsModalData = usePresetsStore((state) => state.presetsModalData);
  const updateState = usePresetsStore((state) => state.updateState);

  const { isLoading: isLoading, data } = usePresets(
    presetsModalData.modalType!,
  );

  const applyFilter = (name: string) => {
    if (name === "persanaTemplates") {
      const filteredData = data?.filter((item: any) => item?.isOpen) || [];
      setPresetsData(filteredData);
    } else {
      const filteredData = data?.filter((item: any) => !item?.isOpen) || [];
      setPresetsData(filteredData);
    }
  };

  useEffect(() => {
    if (data) {
      // @ts-ignore
      setPresetsData(data);

      if (data.length) {
        applyFilter(selectedFilter);
      }
    }
  }, [data]);

  const handleFilterChange = (value: string) => {
    setSelectedFilter(value);
    applyFilter(value);
  };

  return (
    <Modal
      isOpen={presetsModalData !== null}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="2xl"
      onClose={() => {
        updateState({
          isOpenPresetsModal: false,
        });
      }}
      useInert={false}
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="rounded-[20px]">
        <ModalHeader className="border-b">
          <p className="flex items-center gap-x-2">
            {presetsModalData.modalType === "use-ai" && (
              <img
                src={GptLogo}
                className="size-[30px] rounded-full"
                alt="logo"
              />
            )}
            {presetsModalData.modalType === "ask-question" && (
              <Icons.AgentIcon color="#ffef00" size={26} />
            )}
            {presetsModalData.modalType === "sales-emails" && (
              <Icons.CampaignIcon size={26} color="#1972f5" />
            )}
            {presetsModalData.modalType === "http-api" && (
              <Icons.HttpIcon size={26} color="#1972f5" />
            )}
            {presetsModalData.modalType === "persana-agent" && (
              <Icons.AgentIcon color="#0284fe" size={26} />
            )}
            {presetsModalData.modalType === "ai-draft-email" && (
              <Icons.MailIcon color="#0284fe" size={26} />
            )}
            <span>
              {modalTypeTitleMapping[presetsModalData.modalType!] || ""}
            </span>
          </p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="custom-scrollbar h-[35rem] overflow-auto">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="space-x-1.5 pb-2">
                  {filterButtons.map((button, index) => (
                    <button
                      key={`${button.value}-${index}`}
                      onClick={() => handleFilterChange(button?.value)}
                      className={`rounded-md border-0 px-2 py-1 text-sm duration-300 hover:border-[#038ff7] hover:bg-[#f3f7fe] hover:text-[#005597] ${
                        selectedFilter === button.value
                          ? "border-[#038ff7] bg-[#f3f7fe] text-[#005597]"
                          : ""
                      }`}
                    >
                      {button.name}
                    </button>
                  ))}
                </div>

                {!presetsData?.length ? (
                  <div className="flex items-center justify-center">
                    <p className=" text-lg">No data found</p>
                  </div>
                ) : (
                  <React.Suspense
                    fallback={
                      <div className="flex items-center justify-center">
                        <Spinner />
                      </div>
                    }
                  >
                    {!!presetsData?.length &&
                      presetsModalData.modalType === "http-api" && (
                        <HttpPresetsList
                          selectedPresetId={presetsModalData?.selectedPresetId}
                          presetsData={presetsData}
                        />
                      )}

                    {!!presetsData?.length &&
                      presetsModalData.modalType !== "http-api" && (
                        <PresetsList
                          selectedFilter={selectedFilter}
                          selectedPresetId={presetsModalData?.selectedPresetId}
                          presetsData={
                            presetsData as unknown as PresetsResponseData[]
                          }
                        />
                      )}
                  </React.Suspense>
                )}
              </>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PresetsModals;
