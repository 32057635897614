import { Button } from "@chakra-ui/react";
import Icons from "../Icons";
import { Link } from "@tanstack/react-router";

const BackToMainPage = () => {
  return (
    <div className="!w-full mt-4 flex items-center justify-center">
      <Link to="/tools">
        <Button
          className="!bg-[#F9F8F9] !text-[14px] !font-medium !text-[#0F0D1C]"
          variant={"outline"}
          size={"sm"}
          leftIcon={<Icons.ArrowLeftIcon />}
        >
          Back to Main Page
        </Button>
      </Link>
    </div>
  );
};

export default BackToMainPage;
