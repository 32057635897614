import { Portal } from "@chakra-ui/portal";
import React, { useCallback, useMemo, useState } from "react";
import {
  Descendant,
  Editor,
  Node,
  Text,
  Transforms,
  createEditor,
} from "slate";
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";

import SelectColumnDropdown from "../SelectColumnDropdown";
import Element from "./Element";
import Leaf from "./Leaf";

import Icons from "@/components/Icons";
import type { TSelectedColumnOption } from "@/types/common.types";
import { convertSlateToText } from "@/utils";
import { twMerge } from "tailwind-merge";

interface Props {
  placeholder?: string;
  editorHeight?: string;
  readonly?: boolean;
  actionBtn?: React.ReactNode;
  isCodeView?: boolean;
  slateValue: Descendant[];
  setSlateValue: (value: Descendant[]) => void;
  isInvalidFormula?: boolean;
  showDetails?: boolean;
  isInline?: boolean;
  editorClassName?: string;
  maxWidth?: string;
  maxHeight?: string;
  autoFocus?: boolean;
  includeAIPersonalizationOption?: boolean;
}

const withInlineVoid = (editor: Editor) => {
  const { isVoid, isInline } = editor;

  editor.isVoid = (element: any) =>
    (element && element.type === "inlineVoid") || isVoid(element);
  editor.isInline = (element: any) =>
    (element && element.type === "inlineVoid") || isInline(element);

  return editor;
};

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const CustomTextEditor = ({
  placeholder,
  readonly = false,
  editorHeight,
  actionBtn,
  isCodeView,
  slateValue,
  setSlateValue,
  isInvalidFormula,
  showDetails = true,
  isInline = false,
  editorClassName,
  maxHeight,
  maxWidth,
  autoFocus = true,
  includeAIPersonalizationOption = false,
}: Props) => {
  const [queryText, setQueryText] = useState("");
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });

  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const [selectedOption] = useState<TSelectedColumnOption | null>(null);

  const editor = useMemo(
    () => withInlineVoid(withHistory(withReact(createEditor()))),
    [],
  ) as ReactEditor & {
    blurSelection: any;
  };

  const onBlur = useCallback((event: React.FocusEvent) => {
    setIsEditorFocused(false);
    if (event.target.className.includes("options-to-select")) {
      return;
    }
    // Save the current selection to the editor instance
    if (editor.selection) {
      Transforms.setSelection(editor, editor.selection);
    }

    // Get the last node
    const [lastNode] = Editor.nodes(editor, {
      at: Editor.end(editor, editor.selection || []),
      reverse: true,
      match: (n) => Text.isText(n),
    });
    if (lastNode) {
      const [node, path] = lastNode;

      // Check if the last node has the 'queryText' property
      //   @ts-ignore
      if (node.queryText) {
        // Convert the 'queryText' back to a normal string
        //   @ts-ignore
        Transforms.setNodes(editor, { queryText: false }, { at: path });
      }
    }

    setQueryText("");
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (isInline) {
        if (event.key === "Enter") {
          event.preventDefault();
          return;
        }
      }
      if (event.key.toLowerCase() === "backspace") {
        if (queryText) {
          // If we are in query mode, remove the last character from the query text
          setQueryText((prevQueryText) => prevQueryText?.slice(0, -1));
        }
        return;
      }

      if (event.key === "/" && !queryText) {
        event.preventDefault();

        Transforms.insertNodes(editor, {
          text: "/",
          //   @ts-ignore
          queryText: true,
        });

        // Get the cursor position
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const rect = range?.getBoundingClientRect();

        setModalPosition({ x: rect?.left || 0, y: rect?.top || 0 });

        setQueryText("/");
      } else if (queryText) {
        console.log("queryText", event.key, queryText, "queryText");
        // If we are in query mode, append the typed character to the query text
        setQueryText((prevQueryText) => prevQueryText + event.key);
      }
    },
    [queryText],
  );
  const removeCurrentQueryText = () => {
    try {
      // Or simply JSON stringify-replace-parse
      setSlateValue(
        slateValue.map(
          (para) =>
            (({
              ...para,

              children:
                // @ts-expect-error fixme
                para?.children?.map((child) => {
                  if (child.queryText)
                    return {
                      ...child,
                      queryText: false,
                    };
                  return child;
                }) || []
            }) as Descendant),
        ),
      );
    } catch (e) {
      console.error(e);
    }
    setQueryText("");
  };
  const insertInlineVoidWithText = (text: string, id: string) => {
    const inlineVoidElement = {
      type: "inlineVoid",
      id: id,
      isCodeView: isCodeView,
      children: [{ text: text }],
    } as Node;

    // Remove the active queryText set true text entirely
    Transforms.removeNodes(editor, {
      //  @ts-ignore
      match: (node) => Text.isText(node) && node.queryText,
    });

    // Insert the inline void element at the current selection
    Transforms.insertNodes(editor, inlineVoidElement);

    // // Insert a new paragraph after the inline void
    // Transforms.insertNodes(editor, emptyParagraph);

    setQueryText("");
    Transforms.move(editor);
    // Ensure the editor is focused

    setTimeout(() => {
      ReactEditor.focus(editor);
    }, 100);
  };

  //   Update the editor's value
  editor.children = slateValue || initialValue;

  return (
    <div>
      <div
        className={`border !font-title duration-300 focus:border-primary ${
          isInvalidFormula
            ? "border-red-500"
            : isEditorFocused
              ? "border-primary"
              : "border-lightGray"
        } ${isInline ? "flex items-center rounded" : "rounded-t"}`}
      >
        <Slate
          editor={editor}
          initialValue={initialValue}
          onChange={(value) => {
            setSlateValue(value);
          }}
        >
          <Editable
            autoFocus={autoFocus}
            className={twMerge(
              "!relative !h-fit !flex-grow !w-full overflow-y-auto !p-2 !text-[#000000] focus:!outline-none custom-text-editor !font-title",
              editorClassName,
            )}
            placeholder={placeholder || "Type '/' to insert column"}
            style={{
              minHeight: editorHeight ? editorHeight : "10rem",
              height: "fit-content",
              maxHeight: maxHeight ? maxHeight : "20rem",
              fontSize: isCodeView ? "1.15rem" : "0.875rem",
              overflowY: "auto",
              maxWidth: maxWidth ? maxWidth : "none",
            }}
            onFocus={(e) => {
              e.preventDefault();
              setIsEditorFocused(true);
            }}
            readOnly={readonly}
            spellCheck
            renderLeaf={(props) => <Leaf {...props} isCodeView={isCodeView} />}
            renderElement={(props) => (
              <Element {...props} editor={editor} isCodeView={isCodeView} />
            )}
            onKeyDown={onKeyDown}
            onBlur={(event) => {
              onBlur(event);
            }}
          />
        </Slate>
        {isInvalidFormula && (
          <div className="flex items-center gap-x-1 rounded-bl px-2 py-1.5 text-[0.875rem] text-red-500">
            <span className="pt-[1px] text-[1.1rem]">
              <Icons.CloseIcon />
            </span>{" "}
            <span>Invalid Formula</span>
          </div>
        )}
        {isInline ? (
          !convertSlateToText(slateValue).text ? (
            <p className="shrink-0 pr-2 text-xs font-semibold text-[#717989]">
              <span className="rounded-sm bg-[rgb(245_245_245)] px-1">/</span>{" "}
              to insert column
            </p>
          ) : null
        ) : null}
      </div>
      {showDetails && !isInline ? (
        <div className="flex items-center justify-between rounded-b border border-t-0 border-[#c3c7d0] p-1 px-2 py-1.5 text-sm">
          <p>
            Type{" "}
            <span className="rounded-sm bg-[rgb(245_245_245)] px-1 font-medium">
              /
            </span>{" "}
            to insert column
          </p>
          {actionBtn ? actionBtn : null}
        </div>
      ) : null}
      {queryText && (
        <Portal>
          <div
            className="absolute !z-[9999] custom-scrollbar"
            style={{
              left: modalPosition.x,
              top: modalPosition.y + 20,
            }}
          >
            <SelectColumnDropdown
              searchTextNoInput={queryText?.slice(1)}
              onNoMatch={removeCurrentQueryText}
              type="noInput"
              selectedOption={selectedOption}
              setSelectedOption={(options: any) => {
                console.log("options", options);
                insertInlineVoidWithText(options.key, options.keyId);
              }}
              includeAIPersonalizationOption={includeAIPersonalizationOption}
            />
          </div>
        </Portal>
      )}
    </div>
  );
};

export default CustomTextEditor;
