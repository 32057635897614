import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

function MatchedKeywordsModal({
  isOpen,
  onClose,
  matchedKeywords,
}: {
  isOpen: boolean;
  onClose: () => void;
  fieldName: string;
  matchedKeywords: string[];
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        onMouseDown={(e) => e.stopPropagation()}
        maxH="80vh"
        overflow="hidden"
      >
        <ModalHeader>Matched Titles</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {matchedKeywords?.length > 0 ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Keyword</Th>
                </Tr>
              </Thead>
              <Tbody>
                {matchedKeywords.map((title: string, index: number) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{title}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <p>No matches found</p>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default MatchedKeywordsModal;
