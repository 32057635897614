import { Box } from "@chakra-ui/react";
import Settings from "./Settings";
import { userStore } from "@/stores/user.store";
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PainPoints from "./PainPoints";
import ProofPoints from "./ProofPoints";
import { useCampaignStore } from "@/stores/campaigns.store";
import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";
import userService from "@/services/user.service";

export interface Feature {
  point: string;
  solution: string;
}

type PitchProps = {
  pitchComponentRef: MutableRefObject<any>;
};

const Pitch = forwardRef(({ pitchComponentRef }: PitchProps) => {
  const currentUser = userStore((state) => state.currentUser);
  const setCurrentUser = userStore((state) => state.setCurrentUser);
  const [profileInfo, setProfileInfo] = useState({
    firstName: "",
    companyName: "",
    companyDescription: "",
    callToAction: "",
  });
  const [proofPoints, setProofPoints] = useState<string[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);

  const campaignDetails = useCampaignStore((state) => state.campaignDetails);
  const selectedCampaign = useCampaignStore((state) => state.selectedCampaign);
  const { mutateAsync: campaignDetailsMutateAsync } = useMutation({
    mutationFn: (payload: any) =>
      campaignService.updateCampaignDetails(payload),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
    },
  });

  const handleFeatureInputChange = (
    index: number,
    field: keyof Feature,
    value: string,
  ) => {
    setFeatures((prevFeatures) => {
      const updatedFeatures = [...prevFeatures];
      updatedFeatures[index] = { ...updatedFeatures[index], [field]: value };
      return updatedFeatures;
    });
  };

  const handleAddFeature = () => {
    setFeatures((prevFeatures) => [
      ...prevFeatures,
      { point: "", solution: "" },
    ]);
  };

  const handleDeleteFeature = (index: number) => {
    setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
  };

  const handleProofPointInputChange = (index: number, value: string) => {
    setProofPoints((prevPoints) => {
      const updatedPoints = [...prevPoints];
      updatedPoints[index] = value;
      return updatedPoints;
    });
  };

  const handleAddProofPoint = () => {
    setProofPoints((prevPoints) => [...prevPoints, ""]);
  };

  const handleDeleteProofPoint = (index: number) => {
    setProofPoints((prevPoints) => prevPoints.filter((_, i) => i !== index));
  };

  const handleUpdateSettings = (fieldName: string, value: string) => {
    setProfileInfo({
      ...profileInfo,
      [fieldName]: value,
    });
  };

  const handleSave = async () => {
    const response = await userService.updateProfileInfo({
      email: currentUser?.Email,
      info: {
        firstName: profileInfo?.firstName,
        companyName: profileInfo?.companyName,
        companyDescription: profileInfo?.companyDescription,
        callToAction: profileInfo?.callToAction,
        lastName: currentUser.profileInfo?.lastName,
        jobTitle: currentUser.profileInfo?.jobTitle,
        companyURL: currentUser.profileInfo?.companyURL,
        tools: currentUser.profileInfo?.tools,
        assistYouWith: currentUser.profileInfo?.assistYouWith,
        companyTarget: currentUser.profileInfo?.companyTarget,
      },
    });

    if (response.success) {
      const res = await userService.getUser();
      setCurrentUser(res);
    }

    await campaignDetailsMutateAsync({
      campaignId: selectedCampaign,
      proofPoints,
      painPoints: features,
    });
  };

  useEffect(() => {
    if (campaignDetails?.proofPoints) {
      setProofPoints(campaignDetails?.proofPoints);
    }
    if (campaignDetails?.painPoints) {
      setFeatures(campaignDetails?.painPoints);
    }
  }, [campaignDetails]);

  useEffect(() => {
    if (currentUser?.profileInfo) {
      const profile = currentUser?.profileInfo;
      setProfileInfo({
        firstName: profile?.firstName,
        companyName: profile?.companyName,
        companyDescription: profile?.companyDescription,
        callToAction: profile?.callToAction,
      });
    }
  }, [currentUser]);

  useImperativeHandle(pitchComponentRef, () => ({
    triggerFunction: handleSave,
  }));

  return (
    <div className="flex flex-col gap-2 items-center justify-center ">
      <div className="flex items-center justify-center w-full !bg-[#FDFCFF] py-[40px] border border-[#F0EFF1]">
        <Box className="shadow-[1px_1px_4px_4px_#DDD8FF4D,_-1px_-1px_4px_4px_#DDD8FF4D] w-[80%] border border-[#C03EDC] p-[32px] rounded-md bg-white">
          <Settings
            handleUpdateSettings={handleUpdateSettings}
            profileInfo={profileInfo}
          />
        </Box>
      </div>
      <Box className="flex flex-col gap-6 shadow-md w-[80%] border border-[#D0D4DC] p-[32px] rounded-md mt-3 !mb-6">
        <ProofPoints
          proofPoints={proofPoints}
          handleAddProofPoint={handleAddProofPoint}
          handleDeleteProofPoint={handleDeleteProofPoint}
          handleInputChange={handleProofPointInputChange}
        />
        <PainPoints
          features={features}
          handleAddFeature={handleAddFeature}
          handleDeleteFeature={handleDeleteFeature}
          handleInputChange={handleFeatureInputChange}
        />
      </Box>
    </div>
  );
});

export default Pitch;
