import { frequencyList } from "@/components/Common/AutoPilot/constants";
import {
  WalkThroughModalActionType,
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "@/components/Common/AutoPilot/WalkThroughModal";
import WalkThroughInfo from "@/components/Common/AutoPilot/WalkThroughModal/WalkThroughInfo";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import Icons from "@/components/Icons";
import { queryClient } from "@/lib/queryClient";
import leadService from "@/services/leads.service";
import tableService from "@/services/table.service";
import { TableData } from "@/types/table.types";
import {
  Button,
  Checkbox,
  HStack,
  Input,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface Props {
  totalSteps: number;
  action: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | undefined;
}

function MultipleSouceFindJobInputs({
  totalSteps,
  action,
  selectedTableData,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [isFindPeopleAtCompany, setIsFindPeopleAtCompany] = useState(false);
  const [isNotifyByEmail, setIsNotifyByEmail] = useState(false);
  const [findPeopleJobTitles, setFindPeopleJobTitles] = useState<Option[]>([]);
  const toast = useToast();
  const isConfigured = !!selectedTableData;
  const otherPayloads = [
    {
      name: "Location",
      state: location,
      description: "",
      setState: (value: any) => {
        setLocation(value);
      },
    },
    {
      name: "Organization Name",
      state: organizationName,
      description: "",
      setState: (value: any) => {
        setOrganizationName(value);
      },
    },
  ];

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runMultiSourceJobOpenings(
      {
        tableId: selectedTableData?._id || "",
      },
      "update-job-openings-table",
    );
    window.open(`/table/${selectedTableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runMultiSourceJobOpenings(
      {
        jobTitle,
        location,
        organizationName,
        frequencyToUpdate: frequencyToUpdate?.value,
        isFindPeopleAtCompany,
        isNotifyByEmail,
        findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
      },
      "create-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      selectedTableData?._id || "",
      {
        metaData: {
          jobTitle,
          location,
          organizationName,
          frequencyToUpdate: frequencyToUpdate?.value,
          isFindPeopleAtCompany,
          isNotifyByEmail,
          findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (jobTitle !== selectedTableData?.metaData?.jobTitle) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === selectedTableData?.metaData?.frequencyToUpdate,
    ) as Option;

    setJobTitle(selectedTableData?.metaData?.jobTitle || "");
    setLocation(selectedTableData?.metaData?.location || "");
    setOrganizationName(selectedTableData?.metaData?.organizationName || "");
    setIsFindPeopleAtCompany(
      selectedTableData?.metaData?.isFindPeopleAtCompany,
    );
    setIsNotifyByEmail(selectedTableData?.metaData?.isNotifyByEmail);
    setFrequencyToUpdate(freq);
    const savedJobTitles =
      selectedTableData?.metaData?.findPeopleJobTitles?.map((item: string) => ({
        label: item,
        value: item,
      }));
    setFindPeopleJobTitles(savedJobTitles || []);
  }, [selectedTableData]);

  return (
    <>
      <WalkThroughModalBody>
        <WalkThroughInfo
          info="Find Active Hiring Jobs From Multiple Sources"
          className="py-4 border-[#F0EFF1] border-b-[1.5px]"
        />
        <div className="h-full pt-0 pb-5 px-4">
          <VStack spacing={4} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
            <VStack spacing={2} align="stretch">
              <div className="flex items-center justify-between">
                <Text fontSize="md" fontWeight="semibold">
                  How frequently you want to find new jobs?
                </Text>

                <Checkbox
                  isChecked={isNotifyByEmail}
                  onChange={(e) => setIsNotifyByEmail(e.target.checked)}
                  size="md"
                  colorScheme="purple"
                >
                  Notify me via email
                </Checkbox>
              </div>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to find new jobs"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={1} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Job title <span className="text-red-400">*</span>
              </Text>
              <Input
                placeholder="Enter Job Title"
                size="md"
                variant="outline"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
            </VStack>
            {otherPayloads?.map((item) => (
              <VStack spacing={1} align="stretch">
                <Text fontSize="md" fontWeight="semibold">
                  {item.name}
                </Text>
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.state}
                  onChange={(e) => item.setState(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                />
                <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                  {item.description}
                </p>
              </VStack>
            ))}
          </VStack>
          <HStack align={"center"} gap={4} className="!mt-3">
            <Text fontSize="md" fontWeight="semibold">
              Would you like to find people at company?
            </Text>
            <Switch
              isChecked={isFindPeopleAtCompany}
              size="md"
              onChange={(e) => setIsFindPeopleAtCompany(e.target.checked)}
              id={`source-switch`}
              className="text-[14px] flex font-medium"
              colorScheme="purple"
            />
          </HStack>
          {isFindPeopleAtCompany && (
            <div className="!mt-3">
              <p className="pb-1 text-base font-semibold">
                {"Add job titles to find people"}
              </p>
              <MultiSelectDropdown
                value={findPeopleJobTitles}
                options={[]}
                onChange={(selectedValues) =>
                  setFindPeopleJobTitles(selectedValues as Option[])
                }
                placeholder={"Add job titles to include"}
              />
            </div>
          )}
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={isLoading}
        onSave={async () => {
          action === "new"
            ? await handleAutoPilot()
            : await updatePreferences();
        }}
        totalSteps={totalSteps}
        action={action}
      />
    </>
  );
}

export default MultipleSouceFindJobInputs;
