import { createStore, StoreApi, useStore } from "zustand";
import { TableState } from "../types";
import { ROOT_FOLDER_ID } from "../constants";
import { createContext, useContext } from "react";
export const tableListContext = createContext<StoreApi<TableState> | null>(
  null,
);
export const createTableStore = () =>
  createStore<TableState>((set) => ({
    gridApi: null,
    editing: null,
    get gridIsReady(): boolean {
      if (!this.gridApi) return false;
      return !this.gridApi.isDestroyed();
    },
    selectedTable: {
      tableItem: null,
      shift: false,
    },
    showFolders: true,
    folderClosedStatus: new Map([[ROOT_FOLDER_ID, false]]),
    selected: new Set(),
    folderMap: null,
    sort: new Map([
      ["type", "asc"],
      ["updatedAt", "desc"],
    ]),
    search: "",
    tableList: [],
    folderList: [],
    updateState: (newState) => set((state) => ({ ...state, ...newState })),
    setFolderClosed: (folderId, closed) =>
      set((state) => {
        const openFolderStatus = new Map(state.folderClosedStatus);
        openFolderStatus.set(folderId, closed);
        return { ...state, folderClosedStatus: openFolderStatus };
      }),
  }));

export const useTableState = <T = unknown>(
  selector?: (state: TableState) => T,
): T => {
  const store = useContext(tableListContext);
  if (!store) {
    throw new Error("useTableStore must be used within a TableProvider");
  }

  // @ts-expect-error ignore type
  return useStore(store, selector || ((state) => state));
};
