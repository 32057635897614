import { Button, MenuItem } from "@chakra-ui/react";

import Icons from "@/components/Icons";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  isAnyProcessRunning: boolean;
  isMenuItem?: boolean;
}

const ImportData = ({ isAnyProcessRunning, isMenuItem = false }: Props) => {
  const updateState = useEnrichStore((state) => state.updateState);
  const handleImportData = () => {
    updateState({
      selectedEnrichments: {
        id: "importData",
        enrichmentName: "importData",
        credits: "1",
        icon: <Icons.ImportIcon />,
        name: "Import Data",
      },
      isOpenEnrichModal: false,
    });
  };

  return (
    <>
      {isMenuItem ? (
        <MenuItem className="!px-2 !pb-0">
          <Button
            size="sm"
            className="m-auto w-full"
            variant={"outline"}
            leftIcon={<Icons.ImportIcon />}
            onClick={handleImportData}
            isDisabled={isAnyProcessRunning}
          >
            <span className="flex-1">Import</span>
          </Button>
        </MenuItem>
      ) : (
        <Button
          size="sm"
          className="m-auto w-full"
          variant={"outline"}
          leftIcon={<Icons.ImportIcon />}
          onClick={handleImportData}
          isDisabled={isAnyProcessRunning}
        >
          <span className="flex-1">Import</span>
        </Button>
      )}
    </>
  );
};

export default ImportData;
