import { categories } from "./constants";
import { useEnrichStore, CategoryType } from "@/stores/enrich.store";

const Sidebar = () => {
  const activeCategory = useEnrichStore((state) => state.activeCategory);
  const updateState = useEnrichStore((state) => state.updateState);

  const handleCategory = (category: CategoryType) => {
    const newActiveCategory = category === activeCategory ? "" : category;

    updateState({
      activeCategory: newActiveCategory,
      searchInput: "",
    });
  };

  return (
    <div className="enrichment-modal max-h-full w-[25%]">
      <p className="w-full px-[24px] py-[30px] text-[16px] font-medium text-[#0F0D1C]">
        Filter Options
      </p>
      <div className="mx-[20px] mb-[20px] flex flex-col gap-[10px] ">
        {categories.map((item) => (
          <div
            key={item.name}
            className={`flex w-full cursor-pointer items-center gap-[15px] rounded-[4px] px-[8px] py-[12px] ${
              item.name === activeCategory ? "bg-[#F5F3FF]" : "bg-[#F9F8F9]"
            }`}
            onClick={() => handleCategory(item.name)}
          >
            {typeof item.icon === "string" ? (
              <img src={item.icon} alt="Enrichment Icon" />
            ) : (
              <item.icon />
            )}
            <p
              className={`text-[14px] font-medium leading-[16px] ${
                item.name === activeCategory
                  ? "font-[500] text-textPurple"
                  : "font-[400] text-black"
              } `}
            >
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
