import { useCallback, useEffect, useState } from "react";
import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import Header from "../Header";
import BackToMainPage from "../BackToMainPage";

import ToolsBg from "@/assets/images/tools-bg.png";
import EmailFinderIcon from "@/assets/svgs/unique-email-finder.svg";
import Icons from "@/components/Icons";
import enrichment from "@/services/enrichment.service";
import useSocket from "@/lib/socket";
import { SingleEnrichmentPayload } from "@/types/enrichment.types";
import { Link } from "@tanstack/react-router";

const UniqueEmailFinder = () => {
  const toast = useToast();
  const uniqueSocketId = uuidv4();
  const [fullName, setFullName] = useState("");
  const [domain, setDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState({ email: "", emailStatus: "" });
  const [textCopied, setTextCopied] = useState(false);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ payload }: { payload: SingleEnrichmentPayload }) =>
      enrichment.getSingleWorkEmail("findWorkEmail", payload),
  });

  const { subscribe } = useSocket();

  const copyEmail = () => {
    navigator.clipboard.writeText(output.email);
    setTextCopied(true);
    setTimeout(() => {
      setTextCopied(false);
    }, 1000);
    return;
  };

  const handleAccessEmail = useCallback(async () => {
    setLoading(true);
    const payload = {
      jobName: "FIND_SINGLE_WORK_EMAIL",
      payload: {
        providers: [
          "ProspeoProfile",
          "ProspeoLinkedinEmail",
          "Datagma",
          "LeadMagic",
        ],
        fullName: fullName,
        companyName: domain,
        linkedinUrl: "",
      },
      uniqueSocketId,
    };
    const response = await mutateAsync({ payload });
    if (!response.success) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [fullName, domain, uniqueSocketId, mutateAsync, toast]);

  useEffect(() => {
    subscribe(uniqueSocketId, (data: any) => {
      if (
        data?.response?.emailStatus !== "NOT_FOUND" ||
        data?.response?.emailStatus !== "NA"
      ) {
        setOutput(data?.response);
      } else {
        toast({
          title: "Error",
          description: "Email not found",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      setLoading(false);
    });
  }, [uniqueSocketId, subscribe, toast]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleAccessEmail();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAccessEmail]);

  return (
    <div
      className="bg-cover bg-center bg-no-repeat min-h-screen"
      style={{ backgroundImage: `url(${ToolsBg})` }}
    >
      <Header />
      <div className="flex items-center justify-center flex-col">
        <div>
          <Box className="flex justify-between items-center my-4">
            <div className="flex items-center gap-2">
              <img src={EmailFinderIcon} height={40} width={40} />
              <div>
                <p className="text-[16px] text-[#0F0D1C] font-[600]">
                  Unique Email Finder
                </p>
                <p className="text-[13px] text-[#7E889F]">
                  Find a lead’s email address.
                </p>
              </div>
            </div>
            <Link to="/tools/bulk-email-finder">
              <Button
                variant={"outline"}
                leftIcon={<Icons.FileUploadIcon />}
                size={"sm"}
                className="!text-[#0F0D1C] !font-medium !text-[14px] !border !border-[#C03EDC] !bg-white"
              >
                Bulk Email Finder
              </Button>
            </Link>
          </Box>
          <Box className="rounded-[12px] border border-[#D0D4DC] py-[32px] bg-white">
            <div className="pb-6 flex items-center gap-2 px-[32px] border-b border-[#D0D4DC]">
              <Input
                placeholder="Full name"
                className="!w-[400px]"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <p>@</p>
              <Input
                placeholder="company.com"
                className="!w-[400px]"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
              <Button
                className="h-[40px] rounded-md !border-none !bg-[#C03EDC] !text-white hover:shadow-md"
                isDisabled={!fullName || !domain}
                onClick={handleAccessEmail}
                isLoading={isPending || loading}
              >
                Find email
              </Button>
            </div>

            {output.email ? (
              <Box className="px-[32px] pt-6">
                <div className="flex items-center gap-2">
                  <p className="font-medium">
                    Email:{" "}
                    <span className="text-[#7E889F] bg-[#F9F8F9] px-[12px] py-[6px] rounded-[16px]">
                      {output.email}
                    </span>
                  </p>
                  {output.emailStatus !== "NA" && (
                    <>
                      <div className="rounded-[16px] bg-[#EBFFEF] px-[12px] py-[6px] text-[12px] text-[#00B41D]">
                        {output.emailStatus}
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-[14px] font-[600]">
                          {textCopied ? "Copied" : ""}
                        </span>
                        <Icons.CopyIcon
                          className="cursor-pointer"
                          onClick={copyEmail}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Box>
            ) : (
              <p className="text-[14px] text-[#7E889F] px-[32px] pt-6">
                Using the domain name instead of the company name allows for
                better results. For example, persana.ai
              </p>
            )}
          </Box>
        </div>
      </div>
      <BackToMainPage />
    </div>
  );
};

export default UniqueEmailFinder;
