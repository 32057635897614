import Icons from "@/components/Icons";
import { Box, Button, IconButton, Input } from "@chakra-ui/react";
import { Feature } from "./Pitch";

const PainPoints = ({
  features,
  handleAddFeature,
  handleDeleteFeature,
  handleInputChange,
}: {
  features: Feature[];
  handleAddFeature: () => void;
  handleDeleteFeature: (index: number) => void;
  handleInputChange: (
    index: number,
    type: keyof Feature,
    value: string,
  ) => void;
}) => {
  return (
    <div className="border border-[#D0D4DC] rounded-[8px] p-[16px]">
      <div className="flex items-center justify-between">
        <p className="text-[16px] text-[#0F0D1C] font-medium">Pain Points</p>
        <Button
          className="!text-[13px] !font-medium !text-[#373F51]"
          variant="outline"
          size="sm"
          leftIcon={<Icons.AddIcon className="text-[#373F51]" />}
          onClick={handleAddFeature}
        >
          Add
        </Button>
      </div>
      <div className={`flex flex-col gap-3 ${features?.length > 0 && "mt-4"}`}>
        {features.map((item, index) => (
          <Box
            key={index}
            className="border border-[#F0EFF1] p-[16px] rounded-md flex flex-col gap-3"
          >
            <div>
              <div className="flex items-center justify-between mb-3">
                <p className="text-[14px] text-[#7E889F]">
                  A pain point is a specific problem, or challenge that users or
                  customers face, which a product, service, or solution aims to
                  address.
                </p>
                <IconButton
                  className="w-fit"
                  aria-label="delete"
                  variant={"outline"}
                  icon={<Icons.DeleteIcon className="text-[#7E889F]" />}
                  size="sm"
                  onClick={() => handleDeleteFeature(index)}
                />
              </div>
              <Input
                value={item.point}
                size="md"
                className="!rounded-md"
                placeholder="Enter Pain Point"
                onChange={(e) =>
                  handleInputChange(index, "point", e.target.value)
                }
              />
            </div>
            <div>
              <p className="text-[14px] font-medium text-[#0F0D1C] mb-1">
                How can you provide a solution to this pain point (value
                proposition)?
              </p>
              <Input
                value={item.solution}
                size="md"
                className="!rounded-md"
                placeholder="Enter Solution"
                onChange={(e) =>
                  handleInputChange(index, "solution", e.target.value)
                }
              />
            </div>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default PainPoints;
