import Icons from "@/components/Icons";
import { RoutesType } from "@/types/routes.types";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    path: "/",
    icon: Icons.DashboardIcon,
    isHidden: false,
  },
  {
    name: "Search",
    path: "/search",
    icon: Icons.SearchIcon,
    isHidden: false,
    isForAdmin: false,
  },
  {
    name: "Quick Start Guide",
    path: "/quick-start-guide",
    icon: Icons.QuickStartGuideIcon,
    isHidden: true,
  },
  {
    name: "AI Signals",
    path: "/account-monitoring",
    icon: Icons.PiGraph,
    isHidden: false,
  },
  {
    name: "Autopilot",
    path: "/auto-pilot",
    icon: Icons.PiLightning,
    isHidden: false,
  },
  {
    name: "Agents",
    path: "/agents",
    icon: Icons.MdSupportAgent,
    isHidden: false,
    isForAdmin: true,
  },
  {
    name: "AI Digital Workers",
    path: "/campaigns",
    icon: Icons.FiUsers,
    isHidden: true,
    isForAdmin: true,
  },
  {
    name: "Quick Tools",
    path: "/tools",
    icon: Icons.FiTool,
    isHidden: false,
  },
  {
    name: "Chrome Extension",
    path: "/linkedin-profiles",
    icon: Icons.BiExtension,
    isHidden: false,
  },
  {
    name: "Playbooks",
    path: "/playbooks",
    icon: Icons.PiTable,
    isHidden: false,
  },
  {
    name: "Team Overview",
    path: "/team-overview",
    icon: Icons.BiUser,
    isHidden: false,
  },
  {
    name: "Admin",
    path: "/padmin",
    icon: Icons.FiShield,
    isHidden: false,
    isForAdmin: true,
  },
  {
    name: "Settings",
    path: "/settings",
    icon: Icons.FiSettings,
    isHidden: false,
  },
];

export default routes;
