import { useCallback, useEffect, useState } from "react";
import { Box, Button, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";

import Header from "../Header";

import MobileFinderIcon from "@/assets/svgs/unique-mobile-finder.svg";
import Icons from "@/components/Icons";
import ToolsBg from "@/assets/images/tools-bg.png";

import useSocket from "@/lib/socket";
import enrichment from "@/services/enrichment.service";
import { SingleEnrichmentPayload } from "@/types/enrichment.types";
import BackToMainPage from "../BackToMainPage";
import { Link } from "@tanstack/react-router";

const UniqueMobileFinder = () => {
  const toast = useToast();
  const uniqueSocketId = uuidv4();
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState({ phoneNumber: "", status: "" });
  const [textCopied, setTextCopied] = useState(false);

  const { subscribe } = useSocket();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ payload }: { payload: SingleEnrichmentPayload }) =>
      enrichment.getSingleMobileNumber("phoneWaterfall", payload),
  });

  const copyMobileNumber = () => {
    navigator.clipboard.writeText(output.phoneNumber);
    setTextCopied(true);
    setTimeout(() => {
      setTextCopied(false);
    }, 1000);
    return;
  };

  const handleAccessMobileNumber = useCallback(async () => {
    setLoading(true);
    const payload = {
      jobName: "FIND_SINGLE_MOBILE_NUMBER",
      payload: {
        providers: ["leadmagic", "prospeoApiKey", "Datagma", "nimblerApiKey"],
        linkedinUrl,
      },
      uniqueSocketId,
    };
    const response = await mutateAsync({ payload });
    if (!response.success) {
      setLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [uniqueSocketId, mutateAsync, toast]);

  useEffect(() => {
    subscribe(uniqueSocketId, (data: any) => {
      if (data) {
        setOutput(data?.response);
      } else {
        toast({
          title: "Error",
          description: "Mobile number not found",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
      setLoading(false);
    });
  }, [uniqueSocketId, subscribe]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleAccessMobileNumber();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleAccessMobileNumber]);

  return (
    <div
      className="bg-cover bg-center bg-no-repeat min-h-screen"
      style={{ backgroundImage: `url(${ToolsBg})` }}
    >
      <Header />
      <div className="flex items-center justify-center flex-col gap-8">
        <div>
          <Box className="flex justify-between my-4">
            <div className="flex items-center gap-2">
              <img src={MobileFinderIcon} height={40} width={40} />
              <div>
                <p className="text-[16px] text-[#0F0D1C] font-[600]">
                  Unique Mobile Finder
                </p>
                <p className="text-[13px] text-[#7E889F]">
                  Find a lead’s mobile number.
                </p>
              </div>
            </div>
            <Link to="/tools/bulk-mobile-finder">
              <Button
                variant={"outline"}
                leftIcon={<Icons.FileUploadIcon />}
                size={"sm"}
                className="!text-[#0F0D1C] !font-medium !text-[14px] !border !border-[#C03EDC] bg-white"
              >
                Bulk Mobile Finder
              </Button>
            </Link>
          </Box>

          <Box className="rounded-[12px] border border-[#D0D4DC] py-[32px] bg-white">
            <div className="pb-6 flex items-center gap-2 px-[32px] border-b border-[#D0D4DC]">
              <Input
                placeholder="Linkedin URL"
                className="!w-[800px]"
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
              />
              <Button
                className="!bg-[#C03EDC] !text-white !border-none h-[40px] rounded-md hover:shadow-md"
                isDisabled={!linkedinUrl}
                onClick={handleAccessMobileNumber}
                isLoading={loading || isPending}
              >
                Find mobile
              </Button>
            </div>
            {output.phoneNumber ? (
              <div className="px-[32px] pt-6">
                <div className="flex items-center gap-2">
                  <p className="font-medium">
                    Mobile Number:{" "}
                    <span className="text-[#7E889F]">{output.phoneNumber}</span>
                  </p>
                  {output.status !== "NA" && (
                    <div className="flex items-center gap-2">
                      <span className="text-[14px] font-[600]">
                        {textCopied ? "Copied" : ""}
                      </span>
                      <Icons.CopyIcon
                        className="cursor-pointer"
                        onClick={copyMobileNumber}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p className="text-[14px] text-[#7E889F] px-[32px] pt-6">
                Enter LinkedIn URLs to find mobile numbers. <br /> For example
                “https://www.linkedin.com/in/john-doe”.
              </p>
            )}
          </Box>
        </div>
      </div>
      <BackToMainPage />
    </div>
  );
};

export default UniqueMobileFinder;
