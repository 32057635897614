import BulkMobileFinder from "@/components/Tools/MobileFinder/BulkMobileFinder";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/tools/bulk-mobile-finder").createRoute({
  component: BulkMobileFinderPage,
  errorComponent: () => <div>Error</div>,
});

function BulkMobileFinderPage() {
  return <BulkMobileFinder />;
}

export default BulkMobileFinderPage;
