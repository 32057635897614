import UniqueEmailFinder from "@/components/Tools/EmailFinder/EmailFinder";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/tools/email-finder").createRoute({
  component: EmailFinderPage,
  errorComponent: () => <div>Error</div>,
});

function EmailFinderPage() {
  return <UniqueEmailFinder />;
}

export default EmailFinderPage;
