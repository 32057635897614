import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Box,
  ModalFooter,
  Button,
  useSteps,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepSeparator,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import ImportLeads from "./ImportLeads/ImportLeads";
import Settings from "./Settings";
import { Option } from "../Common/MultiSelectDropdown/types";

import Icons from "../Icons";
import { StatusOptions } from "./constants";

import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";
import Pitch from "./Pitch/Pitch";
import Outreach from "./Outreach/Outreach";
import Sequences from "./Sequences/Sequences";

interface EditCampaignModalProps {
  campaign?: any;
  openEditModal: boolean;
}

const EditCampaignModal: React.FC<EditCampaignModalProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState<Option | null>(null);
  const toast = useToast();
  const pitchComponentRef = useRef(null);
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const steps = props.campaign?.haveFullAccess
    ? [
        { title: "Company Data" },
        { title: "Target Leads" },
        { title: "Outreach" },
        { title: "Workflow" },
        { title: "Campaign Setup" },
      ]
    : [
        { title: "Company Data" },
        { title: "Target Leads" },
        { title: "Outreach" },
        { title: "Workflow" },
      ];

  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      campaignService.updateCampaign(
        props?.campaign?.id,
        status?.value || "START",
      ),
  });

  const handleOnClose = () => {
    onClose();
    setActiveStep(1);
  };

  const handleNextClick = () => {
    if (pitchComponentRef.current) {
      // @ts-ignore
      pitchComponentRef.current.triggerFunction();
      setActiveStep(activeStep + 1);
    }
  };

  const handleUpdateCampaign = async () => {
    if (!props.campaign?.haveFullAccess) {
      handleOnClose();
      toast({
        title: "Success",
        description: "Campaign saved successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    await mutateAsync(undefined, {
      onSuccess(response: any) {
        if (response?.success) {
          setTimeout(() => {
            queryClient.refetchQueries({ queryKey: ["campaignDetails"] });
          }, 1000);
          toast({
            title: "Success",
            description: "Campaign updated successfully!",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          handleOnClose();
        } else {
          toast({
            title: "Error",
            description:
              response?.error?.message || "Failed to update campaign!",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
        }
      },
    });
  };

  useEffect(() => {
    if (props.openEditModal) {
      onOpen();
    }
  }, [props.openEditModal]);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, [activeStep]);

  useEffect(() => {
    const currentStatus = StatusOptions?.find(
      (item) => item.label === props?.campaign?.status,
    );
    setStatus(currentStatus || null);
  }, [props]);

  return (
    <>
      <Button
        size="sm"
        colorScheme="primary"
        variant="outline"
        leftIcon={<Icons.EditIcon />}
        className="!bg-[#C03EDC] !text-white !border-none h-[40px] rounded-md"
        onClick={onOpen}
      >
        Edit Campaign
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        motionPreset="scale"
        size={"7xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent h={"full"}>
          <ModalHeader className="flex">
            <div className="flex items-center gap-3">
              <p>{props.campaign?.name}</p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody
            ref={modalBodyRef}
            className={`!px-0 !pt-0 overflow-auto custom-scrollbar`}
          >
            <div className="h-full w-full bg-[#fff]">
              {activeStep === 1 ? (
                <Pitch pitchComponentRef={pitchComponentRef} />
              ) : activeStep === 2 ? (
                <ImportLeads campaignId={props?.campaign?.id} />
              ) : activeStep === 3 ? (
                <Outreach />
              ) : activeStep === 4 ? (
                <Sequences campaignId={props?.campaign?.id} />
              ) : (
                <Settings status={status} setStatus={setStatus} />
              )}
            </div>
          </ModalBody>
          <ModalFooter className="mr-12">
            <div className="w-full flex items-center justify-between">
              <Button
                leftIcon={<Icons.LeftArrowIcon className="text-[#373F51]" />}
                isDisabled={activeStep === 1}
                className="!bg-[#F5F3FF] !text-[#373F51] !border !border-[#F5F3FF] h-[40px] rounded-md"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
              <Stepper
                index={activeStep}
                size={"sm"}
                width={700}
                colorScheme="purple"
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                    </Box>

                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
              <Button
                colorScheme="purple"
                rightIcon={
                  (activeStep === 4 && !props.campaign?.haveFullAccess) ||
                  activeStep === steps.length ? (
                    <Icons.CheckCircleIcon />
                  ) : (
                    <Icons.RightArrowIcon />
                  )
                }
                onClick={() =>
                  activeStep === steps?.length
                    ? handleUpdateCampaign()
                    : activeStep === 1
                      ? handleNextClick()
                      : setActiveStep(activeStep + 1)
                }
                // className="!bg-[#C03EDC] !text-white !border-none h-[40px] rounded-md"
                isLoading={isPending}
              >
                {activeStep === 4 && !props.campaign?.haveFullAccess
                  ? "Save"
                  : activeStep === steps.length
                    ? "Save"
                    : "Next"}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditCampaignModal;
