import AppoloIcon from "@/assets/images/apolloio.png";
import DropContactIcon from "@/assets/images/Dropcontact.png";
import FindyMailIcon from "@/assets/images/findymail.png";
import HunterIoIcon from "@/assets/images/Hunter.png";
import IcypeasIcon from "@/assets/images/icypeas.png";
import ProspeoIcon from "@/assets/images/prospeo.png";
import DatagmaIcon from "@/assets/svgs/datagma.svg";
import LeadMagicIcon from "@/assets/svgs/leadmagic.svg";
import { transformProviders } from "./utils/transformProviders";
export const PROVIDERS = [
  {
    label: "Prospeo",
    value: "Prospeo",
    isUseOwnKey: true,
    keyName: "prospeo",
    icon: ProspeoIcon,
  },
  {
    label: "DropContact",
    value: "dropContact",
    isUseOwnKey: true,
    keyName: "dropcontact",
    isUseOnlyOwnKey: true,
    isHiddenOnFirstFold: true,
    icon: DropContactIcon,
  },
  {
    label: "Findymail",
    value: "FindyMail",
    isUseOwnKey: true,
    keyName: "findyMail",
    isHiddenOnFirstFold: true,
    isForStag: false,
    icon: FindyMailIcon,
  },
  {
    label: "Datagma",
    value: "Datagma",
    isUseOwnKey: true,
    keyName: "datagma",
    icon: DatagmaIcon,
  },
  {
    label: "Hunter",
    value: "HunterIo",
    isUseOwnKey: true,
    keyName: "hunterIo",
    isUseOnlyOwnKey: true,
    isForStag: false,
    icon: HunterIoIcon,
    isHiddenOnFirstFold: true,
  },
  {
    label: "Icypeas",
    value: "Icypeas",
    isUseOwnKey: true,
    keyName: "icyPeasApiKey",
    extraKeyField: {
      label: "User Id",
      keyName: "icyPeasUserId",
    },
    icon: IcypeasIcon,
  },
  {
    label: "LeadMagic",
    value: "LeadMagic",
    isUseOwnKey: true,
    keyName: "leadMagicKey",
    icon: LeadMagicIcon,
  },
  {
    label: "Apollo.io",
    value: "ApolloIo",
    isUseOwnKey: true,
    keyName: "apolloio",
    isUseOnlyOwnKey: true,
    isHiddenOnFirstFold: true,
    isForStag: false,
    icon: AppoloIcon,
  },
].filter((provider) => {
  const isStagEnvironment = import.meta.env.VITE_NODE_ENV !== "production";
  return !provider.isForStag || isStagEnvironment;
});

export const defaultProviders = PROVIDERS.filter(
  (provider) => provider.isHiddenOnFirstFold !== true,
).map(transformProviders);

export const providerOptions = PROVIDERS.map(transformProviders);
