import { Switch } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import { useEnrichStore } from "@/stores/enrich.store";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import RadioButton from "@/components/Common/RadioButton";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import {
  JobTitleilterTypes,
  RESPONSE_COLUMNS,
  jobTitleFiltersOptions,
} from "./constants";
import { REGEX } from "../../constants";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { Descendant } from "slate";

interface EnrichmentDataType extends EnrichmentMetaData {
  extraColumns?: string[];
  excludedJobTitles?: Option[];
  includedJobTitles?: Option[];
  isPrioritizeJobTitle?: boolean;
  companyNameId?: string;
}

const initialSlateValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

const SearchPeopleAtCompanyMixrank = () => {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [selectedJobFilterType, setSelectedJobFilterType] =
    useState<JobTitleilterTypes>("comprehensiveSearch");
  const [includedJobTitles, setIncludedJobTitles] = useState<Option[] | []>([]);
  const [excludedJobTitles, setExcludedJobTitles] = useState<Option[] | []>([]);
  const [pastCompanies, setPastCompanies] = useState<Option[] | []>([]);
  const [responseColumns, setResponseColumns] = useState(
    () => RESPONSE_COLUMNS,
  );
  const [isAddToNewTable, setIsAddToNewTable] = useState(false);
  const [maxImport, setMaxImport] = useState<string | number>(20);
  const [isAddTotalCount, setIsAddTotalCount] = useState(false);
  const [isReturnOnlyCount, setIsReturnOnlyCount] = useState(false);
  const [dependentTableId, setDependentTableId] = useState<string | null>(null);

  const [companyName, setCompanyName] =
    useState<Descendant[]>(initialSlateValue);
  const [firstName, setFirstName] = useState<Descendant[]>(initialSlateValue);
  const [lastName, setLastName] = useState<Descendant[]>(initialSlateValue);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  const isSearchPeopleAtCompany =
    selectedEnrichments?.enrichmentName === "searchPeopleAtCompanyPersana";

  const handleSwitch = (id: string) => {
    setResponseColumns((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item,
      ),
    );
  };

  const updateJobTitlesState = (titles: string | string[], setState: any) => {
    if (Array.isArray(titles)) {
      setState(
        titles.map((item) => ({
          label: item,
          value: item,
        })),
      );
    } else {
      const jobTitls = titles.split(",");
      setState(
        jobTitls.map((item) => ({
          label: item,
          value: item,
        })),
      );
    }
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
        setFirstName(enrichmentData.firstName || initialSlateValue);
        setLastName(enrichmentData.lastName || initialSlateValue);
        setCompanyName(enrichmentData.companyName || initialSlateValue);
        setIsAddTotalCount(enrichmentData.isAddTotalCount || false);
        setIsReturnOnlyCount(enrichmentData.isReturnOnlyCount || false);
        setDependentTableId(enrichmentData.dependentTableId || null);

        if (enrichmentData.maxImport) {
          setMaxImport(enrichmentData.maxImport);
        }

        // @ts-ignore
        setSelectedJobFilterType(enrichmentData?.jobTitleFilterType);

        if (enrichmentData.extraColumns) {
          setResponseColumns((prevState) =>
            prevState.map((item) => ({
              ...item,
              isChecked:
                enrichmentData?.extraColumns?.includes(item.label) ?? false,
            })),
          );
        }

        updateJobTitlesState(
          // @ts-ignore
          enrichmentData.includedJobTitles || [],
          setIncludedJobTitles,
        );
        updateJobTitlesState(
          // @ts-ignore
          enrichmentData.pastCompanies || [],
          setPastCompanies,
        );
        updateJobTitlesState(
          // @ts-ignore
          enrichmentData.excludedJobTitles || [],
          setExcludedJobTitles,
        );
      }

      return;
    }

    const initialColumn = getInitialColumn(REGEX.WEBSITE);
    if (initialColumn) {
      setSelectedOption({
        key: initialColumn.name,
        keyId: initialColumn._id,
        iconType: initialColumn.metaData?.iconType || "url",
      });
    }
  }, [viewMode, selectedColumnToEdit]);

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <>
      <div
        className="max-h-[70vh]  grow overflow-y-auto p-4"
        ref={modalBodyRef}
      >
        <div className="flex w-full items-center justify-between pb-1">
          <p className="pb-1 text-base font-semibold">Company Identifier</p>
          <div className="flex items-center space-x-2">
            <label
              htmlFor="add-to-new-table-FPAC"
              className="text-gray-400 text-base font-medium"
            >
              Add to new table
            </label>
            <Switch
              size={"md"}
              isChecked={isAddToNewTable}
              id={`add-to-new-table-FPAC`}
              onChange={() => setIsAddToNewTable((prev) => !prev)}
            />
          </div>
        </div>
        <div>
          <p className="pb-1.5 text-xs">
            {isSearchPeopleAtCompany
              ? "Company domain/website or LinkedIn Company Id "
              : "Company domain/website or company Id or Company LinkedIn URL"}
          </p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="space-y-4">
          <div className="hidden pt-4">
            <p className="pb-1 text-base font-semibold">
              Past Companies to include
            </p>
            <p className="pb-1.5 text-xs">
              Company id can be found using our enrich company enrichment.
            </p>
            <MultiSelectDropdown
              value={pastCompanies}
              options={[]}
              onChange={(selectedValues) => {
                setPastCompanies(selectedValues as Option[]);
              }}
              placeholder="Add past companies id to include"
              limit={20}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">
              Company Name -{" "}
              <span className="text-gray-600 text-xs">Optional</span>
            </p>
            <CustomTextEditor
              editorHeight={"2rem"}
              slateValue={companyName}
              setSlateValue={(val) => setCompanyName(val)}
              placeholder={`Start typing or use the dropdown to select a column.`}
            />
          </div>
          <div className="">
            <div className="flex items-center gap-x-3">
              <p className="text-base font-semibold">Job Title</p>
              <div className="hidden items-center gap-x-3">
                {jobTitleFiltersOptions?.map((item) => (
                  <RadioButton
                    key={item.value}
                    label={item.name}
                    labelExplanation={item.description}
                    name={item.value}
                    checked={selectedJobFilterType === item.value}
                    setChecked={() => {
                      setSelectedJobFilterType(item.value);
                    }}
                  />
                ))}
              </div>
            </div>
            <span className="block pb-1">You can add upto 50 job titles.</span>

            <MultiSelectDropdown
              value={includedJobTitles}
              options={[]}
              onChange={(selectedValues) => {
                setIncludedJobTitles(selectedValues as Option[]);
              }}
              placeholder="Add job titles to include"
              limit={50}
            />
          </div>
          <div className="">
            <p className="mb-2 text-base font-semibold">
              Job Title Exclude Keywords -{" "}
              <span className="text-gray-600 text-xs">Optional</span>
            </p>
            <MultiSelectDropdown
              value={excludedJobTitles}
              options={[]}
              onChange={(selectedValues) => {
                setExcludedJobTitles(selectedValues as Option[]);
              }}
              placeholder="Add job titles to exclude"
              limit={3}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">
              First Name -{" "}
              <span className="text-gray-600 text-xs">Optional</span>
            </p>
            <CustomTextEditor
              editorHeight={"2rem"}
              slateValue={firstName}
              setSlateValue={(val) => setFirstName(val)}
              placeholder={`Start typing or use the dropdown to select a column.`}
            />
          </div>
          <div>
            <p className="pb-1 text-base font-semibold">
              Last Name -{" "}
              <span className="text-gray-600 text-xs">Optional</span>
            </p>
            <CustomTextEditor
              editorHeight={"2rem"}
              slateValue={lastName}
              setSlateValue={(val) => setLastName(val)}
              placeholder={`Start typing or use the dropdown to select a column.`}
            />
          </div>

          <div className="hidden flex-col gap-[8px]">
            <div className="mb-2 flex items-center justify-between">
              <p className="text-base font-semibold">
                Add data as columns to your table
              </p>
              <p className="text-grey-400 text-[14px] text-[#676d7e]">
                {responseColumns.filter((item) => item.isChecked).length}{" "}
                columns selected
              </p>
            </div>
            <div className="space-y-4">
              {responseColumns.map(({ id, label, isChecked }) => (
                <div
                  key={id}
                  className="flex w-full items-center justify-between rounded-[6px] bg-[#FAFAFB] px-4 py-2"
                >
                  <p className="text-[16px] font-[500]">{label}</p>
                  <Switch
                    size={"md"}
                    isChecked={isChecked}
                    id={id}
                    onChange={() => handleSwitch(id)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <p className="text-base font-semibold">
              Max Import ({" "}
              <small className="text-xs">
                Upto {isSearchPeopleAtCompany ? 50 : 1000}
              </small>{" "}
              )
            </p>

            <input
              type="number"
              value={maxImport}
              onChange={(e) => {
                const value = e.target.value;
                setMaxImport(value ? +value : "");
              }}
              className="border-gray-200 w-full rounded-md border px-4 py-2"
              max={1000}
              min={1}
            />
          </div>

          {!isSearchPeopleAtCompany && (
            <>
              <div className="flex items-center justify-between space-x-2">
                <label
                  htmlFor="add-total-count-FPAC"
                  className="text-gray-400 text-base font-medium"
                >
                  Add Total Count
                </label>
                <Switch
                  size={"md"}
                  isChecked={isAddTotalCount}
                  id={`add-total-count-FPAC`}
                  onChange={() => setIsAddTotalCount((prev) => !prev)}
                />
              </div>
              <div className="flex items-center justify-between space-x-2">
                <label
                  htmlFor="add-total-count-FPAC"
                  className="text-gray-400 text-base font-medium"
                >
                  Return only count
                </label>
                <Switch
                  size={"md"}
                  isChecked={isReturnOnlyCount}
                  id={`add-total-count-FPAC`}
                  onChange={() => setIsReturnOnlyCount((prev) => !prev)}
                />
              </div>
            </>
          )}
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        apiPayload={{
          columnId: selectedOption?.keyId,
          isAddToNewTable,
          isAddTotalCount,
          isReturnOnlyCount,
          dependentTableId,
          excludedJobTitles: excludedJobTitles?.map((item) => item.value),
          includedJobTitles: includedJobTitles?.map((item) => item.value),
          pastCompanies: pastCompanies?.map((item) => item.value),
          firstName,
          lastName,
          companyName,
          isPrioritizeJobTitle: false,
          jobTitleFilterType: selectedJobFilterType,
          newColumnNames: responseColumns
            .filter((item) => item.isChecked)
            .map((item) => item.label),
          isUseOwnKey: false,
          maxImport: Math.min(
            +maxImport || 20,
            isSearchPeopleAtCompany ? 50 : 1000,
          ),
        }}
      />
    </>
  );
};

export default SearchPeopleAtCompanyMixrank;
