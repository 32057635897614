/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from "react";
import {
  Button,
  Tag,
  TagLabel,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useMutation } from "@tanstack/react-query";

import Icons from "../Icons";
import SelectColumnDropdown from "../Enrichment/Common/SelectColumnDropdown";

import { findPlaceholderValues } from "@/lib/utils";
import { usePresetsStore } from "@/stores/presets.store";

import type { PresetsResponseData } from "@/types/presets.types";
import type { TSelectedColumnOption } from "@/types/common.types";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText } from "@/utils";
import presetsService from "@/services/presets.service";
import { allEnrichments } from "../Enrichment/constants";

interface Props {
  presetsData: PresetsResponseData[];
  selectedFilter: string;
  selectedPresetId?: string;
}

const PresetsList = ({
  presetsData,
  selectedFilter,
  selectedPresetId,
}: Props) => {
  const toast = useToast();
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const id = selectedEnrichments?.id || "persanaQuantum";
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const updateState = usePresetsStore((state) => state.updateState);
  const { updateState: updateExamples } = useEnrichStore();

  const [selectedPreset, setSelectedPreset] =
    useState<PresetsResponseData | null>(null);
  useEffect(() => {
    const preset = presetsData.find((item) => item._id === selectedPresetId);
    setSelectedPreset(preset ?? null);
  }, [selectedPresetId]);
  const [selectedColumns, setSelectedColumns] = useState<
    {
      placeholderValue: string;
      value: TSelectedColumnOption;
    }[]
  >([]);

  const placeholderValue = useMemo(() => {
    if (!selectedPreset) return [];
    const promptPlaceHolder = findPlaceholderValues(selectedPreset.prompt);
    const conditionPlaceHolder = findPlaceholderValues(
      selectedPreset.condition instanceof Array
        ? convertSlateToText(selectedPreset.condition)?.text ?? ""
        : "",
    );
    return [...new Set([...promptPlaceHolder, ...conditionPlaceHolder])];
  }, [selectedPreset]);

  const categories: string[] = [
    "All",
    ...Array.from(new Set(presetsData.map((item) => item.category))),
  ].filter((item) => item);

  const [selectedCategory, setSelectedCategory] = useState<string>("All");

  const { mutateAsync } = useMutation({
    mutationFn: ({ presetId }: { presetId: string }) =>
      presetsService.deletePreset(presetId),
  });

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  const filteredData =
    selectedCategory === "All" || selectedFilter === "myTemplates"
      ? presetsData
      : presetsData.filter((item) => item.category === selectedCategory);

  const onUsePresetClick = () => {
    let modifiedPrompts = selectedPreset?.prompt || "";
    let modifiedCondition = selectedPreset?.condition
      ? JSON.stringify(selectedPreset?.condition)
      : "";
    selectedColumns.forEach((item) => {
      const placeholder = `{{${item.placeholderValue}}}`;
      if (modifiedPrompts.includes(placeholder)) {
        modifiedPrompts = modifiedPrompts.replace(
          placeholder,
          `{{${item.value.key}}}`,
        );
      }
      if (modifiedCondition.includes(item.placeholderValue)) {
        modifiedCondition = modifiedCondition.replace(
          placeholder,
          `${item.value.key}`,
        );
      }
    });
    const enrichment = allEnrichments.find(
      (item) => item.presetType === selectedPreset?.type,
    );
    if (enrichment) {
      updateEnrichmentState({
        selectedEnrichments: enrichment,
        viewMode: "create",
        searchInput: "",
        isOpenEnrichModal: false,
      });
    }
    setSelectedColumns([]);
    updateState({
      isOpenPresetsModal: false,
      selectedPresetValues: modifiedPrompts || "",
      condition: modifiedCondition ? JSON.parse(modifiedCondition) : "",
      model: selectedPreset?.model,
      maxCost: selectedPreset?.maxCost,
      selectedPreset,
    });

    updateExamples({
      examples: selectedPreset?.examples,
    });
  };

  const handleDeletePreset = (presetId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#AA6BFA",
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      cancelButtonText: "Cancel",
      preConfirm: async () => {
        return await mutateAsync(
          { presetId },
          {
            onSuccess: async (response) => {
              if (response?.success == false) {
                toast({
                  title: "Something went wrong",
                  description: response?.error || "",
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
              } else {
                toast({
                  title: "Delete preset",
                  description:
                    // @ts-ignore
                    response?.message || "Preset deleted successfully",
                  status: "success",
                  duration: 4000,
                  isClosable: true,
                  position: "top-right",
                });
              }
              updateState({
                isOpenPresetsModal: false,
                selectedPresetValues: "",
              });
            },
            onError: (error) => {
              toast({
                title: "Something went wrong",
                description: error.message,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top-right",
              });
              updateState({
                isOpenPresetsModal: false,
                selectedPresetValues: "",
              });
            },
          },
        );
      },
    });
  };

  useEffect(() => {
    if (id === "companyRevenue") {
      const revenuePreset = filteredData?.find(
        (item) => item.name === "Public company revenue in USD",
      );
      revenuePreset && setSelectedPreset(revenuePreset);
    }else if(id === "companyFunding") {
       const revenuePreset = filteredData?.find(
         (item) => item.name === "Find out the funding data of a company",
       );
       revenuePreset && setSelectedPreset(revenuePreset);
    }
  }, []);

  return (
    <div className="py-4">
      {selectedPreset ? (
        <div className="h-full space-y-6">
          <div className="flex justify-start">
            <Icons.ArrowLeftIcon
              onClick={() => {
                setSelectedPreset(null);
                setSelectedColumns([]);
              }}
              className="cursor-pointer text-[#000000]/70"
              size={20}
            />
          </div>

          <div className="!mt-2 text-sm">
            <h2 className="pb-0.5 font-semibold text-[#1d2026]">
              {selectedPreset.name}
            </h2>
            <p className="text-[#676d7e]">{selectedPreset.description}</p>
          </div>

          {placeholderValue.map((value, index) => {
            return (
              <div className="text-sm" key={`${value}_${index}`}>
                <h4 className="pb-0.5 font-semibold text-[#1d2026]">{value}</h4>
                <SelectColumnDropdown
                  selectedOption={
                    selectedColumns.find(
                      (col) => col.placeholderValue === value,
                    )?.value || null
                  }
                  setSelectedOption={(selectedColumn: any) => {
                    // @ts-ignore
                    setSelectedColumns((prevState) => {
                      const matchedColumn = prevState.find(
                        (col) => col.placeholderValue === value,
                      );
                      if (matchedColumn) {
                        matchedColumn.value =
                          selectedColumn as TSelectedColumnOption;
                        return [...prevState];
                      } else {
                        return [
                          ...prevState,
                          {
                            placeholderValue: value,
                            value: selectedColumn,
                          },
                        ];
                      }
                    });
                  }}
                  notInportal
                />
              </div>
            );
          })}

          <div className="text-sm">
            <h4 className="pb-0.5 font-semibold text-[#1d2026]">
              Prompt Preview
            </h4>
            <p className="cursor-not-allowed rounded-md border border-[#c3c7d0] bg-[#f6f6f8] p-3 text-[#676d7e]">
              {selectedPreset.prompt}
            </p>
          </div>

          {selectedPreset.condition && (
            <div className="text-sm">
              <h4 className="pb-0.5 font-semibold text-[#1d2026]">
                Conditional Run Preview
              </h4>
              <p className="cursor-not-allowed rounded-md border border-[#c3c7d0] bg-[#f6f6f8] p-3 text-[#676d7e]">
                {selectedPreset.condition instanceof Array
                  ? convertSlateToText(selectedPreset.condition)?.text ?? ""
                  : selectedPreset.condition}
              </p>
            </div>
          )}

          <Button
            colorScheme="green"
            onClick={onUsePresetClick}
            isDisabled={placeholderValue?.length > 0 && !selectedColumns.length}
          >
            <Icons.CheckCircleIcon className="mr-2 text-lg" />
            Use this preset
          </Button>
        </div>
      ) : (
        <div className="custom-scrollbar grow space-y-4 overflow-y-auto">
          {selectedFilter !== "myTemplates" && (
            <Wrap spacing={3} mb={4} className="my-3">
              {categories.map((category) => (
                <WrapItem key={category}>
                  <Tag
                    size="md"
                    variant={"solid"}
                    bg={selectedCategory === category ? "#f3f7fe" : "#fff"}
                    padding={2}
                    onClick={() => handleCategoryClick(category)}
                    cursor="pointer"
                  >
                    <TagLabel
                      className="text-[#4A5264]"
                      color={
                        selectedCategory === category ? "#005597" : "#4A5264"
                      }
                    >
                      {category}
                    </TagLabel>
                  </Tag>
                </WrapItem>
              ))}
            </Wrap>
          )}

          {filteredData?.map((preset, index) => (
            <div
              key={index}
              className="relative flex w-[98%] cursor-pointer items-center justify-between rounded-md bg-[#f6f6f8] p-3 duration-300 hover:bg-[#E7E8EC]"
              onClick={() => setSelectedPreset(preset)}
            >
              <div>
                <p className="font-semibold">{preset.name}</p>
                <p className="text-sm">{preset.description}</p>
              </div>
              {selectedFilter === "myTemplates" && (
                <Button
                  size="sm"
                  color={"red"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeletePreset(preset._id);
                  }}
                >
                  <Icons.DeleteIcon />
                </Button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PresetsList;
