import Icons from "@/components/Icons";
import StarterChecklist from "@/components/StarterChecklist";
import StarterPlaybooksList from "@/components/StarterPlaybooksList";
import { useModalStore } from "@/stores/modal.store";
import { Button, Flex } from "@chakra-ui/react";

const RightSection = () => {
  return (
    <Flex flexDirection="column" gap="4" w="full">
      <Button
        leftIcon={<Icons.GiftIcon className="text-[#C03EDC] w-[20] h-[20]" />}
        className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#0F0D1C] my-auto ml-auto !text-[16px] !font-medium !cursor-pointer"
        onClick={() => {
          useModalStore.getState().updateState({
            isOpenModal: true,
            modalData: {
              modalType: "referral",
            },
          });
        }}
        variant="secondary"
      >
        Refer to earn free credits
      </Button>
      <StarterChecklist />
      <StarterPlaybooksList />
    </Flex>
  );
};

export default RightSection;
