import { CellKeyDownEvent, IRowNode, RowClickedEvent } from "ag-grid-community";
import { useEffect } from "react";
import { DEFAULT_FOLDER_OPEN } from "../../constants";
import { TRow, TTableRow } from "../../types";
import { useTableState } from "../useTableState.store";

export const useHandleRowOpen = () => {
  const gridApi = useTableState((state) => state.gridApi);
  const selectedRows = useTableState((state) => state.selected);
  const folderClosedStatus = useTableState((state) => state.folderClosedStatus);
  const setFolderClosed = useTableState((state) => state.setFolderClosed);
  const updateState = useTableState((state) => state.updateState);
  // click/enter open
  useEffect(() => {
    if (!gridApi) return;
    gridApi.addEventListener("rowClicked", onRowClicked);
    gridApi.addEventListener("cellKeyDown", onCellKeyDown);
    return () => {
      if (gridApi.isDestroyed()) return;
      gridApi.removeEventListener("rowClicked", onRowClicked);
      gridApi.removeEventListener("cellKeyDown", onCellKeyDown);
    };
    function onRowClicked(e: RowClickedEvent<TRow>) {
      if (e.event?.defaultPrevented) return;
      openRowItem(e.node!, e.event as MouseEvent);
    }
    function onCellKeyDown(e: CellKeyDownEvent<TRow>) {
      const event = e.event! as KeyboardEvent;
      if (e.event?.defaultPrevented) return;
      else if (event?.key === "Enter" && !event?.shiftKey) {
        openRowItem(e.node!);
      }
    }

    function openRowItem(node: IRowNode<TRow>, event?: MouseEvent) {
      const data: TRow = node.data!;
      if (selectedRows.size) {
        node.setSelected(!selectedRows.has(data._id));
        return;
      }
      if (data.type === "folder") {
        const isClosed =
          folderClosedStatus.get(data._id) ?? !DEFAULT_FOLDER_OPEN;
        setFolderClosed(data._id, !isClosed);
      } else {
        updateState({
          selectedTable: {
            tableItem: data as TTableRow,
            shift: event?.shiftKey ?? false,
          },
        });
      }
    }
  }, [folderClosedStatus, gridApi, selectedRows]);
};
