import { Button, Input } from "@chakra-ui/react";
import Icons from "@/components/Icons";
import CampaignEmailIcon from "@/assets/svgs/campaign-email.svg";

const SequenceSidebar = ({
  sequences,
  onAddSequence,
  onSequenceClick,
  onDeleteSequence,
  handleDelayChange,
}: {
  sequences: any[];
  activeSequenceIndex: number;
  onAddSequence: () => void;
  onSequenceClick: (index: number) => void;
  onDeleteSequence: (index: number) => void;
  handleDelayChange: (index: number, value: number) => void;
}) => {
  const getOrdinalSuffix = (num: number) => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) return `${num}st`;
    if (j === 2 && k !== 12) return `${num}nd`;
    if (j === 3 && k !== 13) return `${num}rd`;
    return `${num}th`;
  };

  return (
    <div className="h-[calc(100vh-225px)]">
      <div className="px-[24px] py-[16px] border-b border-[#d0d4dc]">
        <Button
          className="w-full"
          size={"sm"}
          colorScheme="purple"
          leftIcon={<Icons.PlusIcon />}
          onClick={onAddSequence}
        >
          Add Step
        </Button>
      </div>
      <div className="max-h-[85%] overflow-y-auto custom-scrollbar px-[10px] py-[16px]">
        {sequences.map((sequence, index) => (
          <div
            key={index}
            className={`flex items-center mb-[8px] py-[8px] px-[12px] rounded-[4px]`}
          >
            <div className="flex-grow">
              {index !== 0 && (
                <div className="h-[120px] bg-[#F5F3FF] mb-3 p-3 rounded-md py-[16px] px-[16px]">
                  <div className="flex items-center gap-2">
                    <Icons.DelayIcon />
                    <p className="text-[16px] text-[#0F0D1C] font-medium">
                      Delay
                    </p>
                  </div>
                  <div className="bg-[#FDFCFF] border border-[#F0EFF1] rounded-[6px] px-[8px] py-[16px] mt-2">
                    <div className="flex items-center gap-2 text-[13px] text-[#0F0D1C]">
                      <span>After</span>{" "}
                      <Input
                        size={"sm"}
                        w={50}
                        type="number"
                        value={
                          sequences[index]?.seq_delay_details?.delay_in_days
                        }
                        onChange={(e) => {
                          handleDelayChange(index, Number(e.target.value));
                        }}
                      />{" "}
                      <span>days then</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex items-center gap-2 mb-2">
                <img src={CampaignEmailIcon} height={30} width={30} />
                <p className="text-[16px] font-medium text-[#0F0D1C]">
                  {index === 0 ? "Introduction email" : "Follow-up email"}
                </p>
              </div>
              <div
                className={`flex items-start justify-between border border-[#D0D4DC] rounded-md px-[12px] py-[8px] !cursor-pointer`}
                onClick={() => onSequenceClick(index)}
              >
                <div className="flex flex-col justify-between">
                  <p className="text-[14px] text-[#0F0D1C] font-medium">
                    Message
                  </p>
                  <p className="text-[13px] text-[#373F51] mb-2">
                    {`→ ${getOrdinalSuffix(index + 1)} Prompt`}
                  </p>
                  <p className="text-sm text-gray-600">{`Subject: ${sequence.seq_variants[0]?.subject.slice(0, 15) || "Untitled"}...`}</p>
                </div>
                {index !== 0 && (
                  <Icons.DeleteIcon
                    className="cursor-pointer"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onDeleteSequence(index);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SequenceSidebar;
