import BulkEmailFinder from "@/components/Tools/EmailFinder/BulkEmailFinder";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/tools/bulk-email-finder").createRoute({
  component: BulkEmailFinderPage,
  errorComponent: () => <div>Error</div>,
});

function BulkEmailFinderPage() {
  return <BulkEmailFinder />;
}

export default BulkEmailFinderPage;
