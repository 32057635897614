import { Link, useNavigate } from "@tanstack/react-router";
import { Button } from "@chakra-ui/react";

import ArrowRightIcon from "@/assets/svgs/arrow-right.svg";
import Icons from "../Icons";
import { allTemplates } from "./constants";
import { useMemo } from "react";

const StarterPlaybooksList = ({
  showFindWorkEmail = true,
}: {
  showFindWorkEmail?: boolean;
}) => {
  const templates = useMemo(
    () => (showFindWorkEmail ? allTemplates : allTemplates.slice(1)),
    [showFindWorkEmail],
  );
  const navigate = useNavigate();
  return (
    <div className="border border-[#D0D4DC] rounded-[12px] bg-white h-fit w-full py-[24px]">
      <div className="flex flex-col gap-[24px]">
        <div className="flex flex-col gap-[8px] px-[24px]">
          <p className="text-[20px] leading-[24px] font-medium text-[#0F0D1C]">
            Playbooks
          </p>
          <p className="text-sm text-[#4A5264] leading-[20px]">
            Explore some curated workflows
          </p>
        </div>
        <div>
          {templates.map(({ templateId, heading, url, strength }, ind) => (
            // @ts-ignore
            <Link to={url} key={templateId}>
              <div
                className={`border-b border-[#D0D4DC] py-[16px] ${ind === 0 && "border-t "} cursor-pointer hover:bg-[#F0EFF1]`}
              >
                <div className="flex items-center justify-between px-[24px]">
                  <div className="flex flex-col gap-[8px]">
                    <p className="text-[14px] leading-[20px] text-[#0F0D1C]">
                      {heading}
                    </p>
                    <div className="flex items-center gap-3">
                      <div className="px-[6px] py-[4px] rounded-[6px] bg-[#EBFFEF] ">
                        <div className="flex items-center gap-2 text-[12px]">
                          <Icons.TrophyIcon className="text-[#FFB636]" />
                          <p className="text-[#5024DC]">{strength}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    src={ArrowRightIcon}
                    alt=""
                    width={12}
                    height={12}
                    className="rotate-[-30deg]"
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <Button
          rightIcon={
            <Icons.RightArrowIcon className="text-[#373F51] w-[20] h-[20]" />
          }
          className="rounded-[6px] !border-[#5024DC] !bg-[#F5F3FF] !text-[#5C34DD] !w-[95%] m-auto"
          variant="secondary"
          onClick={() => {
            navigate({ to: "/playbooks" });
          }}
        >
          All playbooks
        </Button>
      </div>
    </div>
  );
};

export default StarterPlaybooksList;
