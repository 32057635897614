import { UseDisclosureReturn } from "@chakra-ui/react";

import { SaveSearchModal } from "./SaveSearchModal";
import { useCompanySearchStore } from "@/components/CompanySearch/hooks/useCompanySearchStore";

export const SaveCurrentCompanyFilter = ({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) => {
  const { inputtedValues } = useCompanySearchStore();
  return (
    <div>
      <SaveSearchModal
        filterKey="company_search"
        disclosure={disclosure}
        currentFilter={{ inputtedValues }}
      />
    </div>
  );
};
