import React, { ChangeEvent, useEffect, useMemo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { TableListComponent } from "@/pages/TableList";
import {
  createTableStore,
  tableListContext,
} from "@/pages/TableList/hooks/useTableState.store";
import { useStore } from "zustand";

interface TablePickerProps {
  disclosure: UseDisclosureReturn;
  onTableSelect: (table: { _id: string; name: string } | null) => void;
}

export const TablePicker: React.FC<TablePickerProps> = ({
  disclosure: { isOpen, onClose },
  onTableSelect,
}) => {
  const tableListStore = useMemo(() => createTableStore(), []);
  const search = useStore(tableListStore, (state) => state.search);
  const setSearchValue = useStore(tableListStore, (state) => state.updateState);
  const updateState = useStore(tableListStore, (state) => state.updateState);

  // Handler for input changes
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue({ search: value }); // Update the Zustand store
  };
  useEffect(
    () =>
      tableListStore.subscribe(({ selectedTable }) => {
        if (selectedTable?.tableItem?._id) {
          onTableSelect({
            _id: selectedTable.tableItem?._id,
            name: selectedTable.tableItem?.name,
          });
          updateState({ selectedTable: { tableItem: null, shift: false } });
        }
      }),
    [],
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <div className="flex items-center justify-between gap-2">
            Pick a Table
          </div>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <tableListContext.Provider value={tableListStore}>
            <Box p={4} bg="white" boxShadow="sm" borderRadius="md">
              <Input
                placeholder="Search tables..."
                value={search}
                onChange={handleSearchChange}
                size="md"
                variant="outline"
              />
            </Box>

            {/* TableList Component */}
            <TableListComponent variant="mini" />
          </tableListContext.Provider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
