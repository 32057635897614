import { getInitialColumn, getInitialColumnByColName } from "@/utils";
import { REGEX } from "../../constants";

export const RESPONSE_COLUMNS = [
  {
    isChecked: false,
    label: "Headquarters",
    id: "companyHeadquarters",
  },
  {
    isChecked: false,
    label: "Headcount",
    id: "companyHeadcount",
  },
  {
    isChecked: false,
    label: "Followers",
    id: "companyFollowers",
  },
  {
    isChecked: false,
    label: "Company Website",
    id: "companyWebsite",
  },
  {
    isChecked: false,
    label: "Company LinkedIn URL",
    id: "companyLinkedinUrl",
  },
  {
    isChecked: false,
    label: "Founded Year",
    id: "companyFoundedYear",
  },
  {
    isChecked: false,
    label: "Company Address",
    id: "companyAddress",
  },
  {
    isChecked: false,
    label: "Funding Details",
    id: "companyFunding",
  },
];

export const options = [
  {
    name: "By Website",
    label: "Company Website",
    value: "website",
  },
  {
    name: "By Name",
    label: "Company Name",
    value: "name",
  },
  {
    name: "By LinkedIn",
    label: "Company LinkedIn URL",
    value: "linkedIn",
  },
];

export const functions = [
  {
    value: "website",
    getColumn: () => {
      return getInitialColumn(REGEX.WEBSITE);
    },
  },
  {
    value: "name",
    getColumn: () => {
      return getInitialColumnByColName("Company Name");
    },
  },
  {
    value: "linkedIn",
    getColumn: () => {
      return getInitialColumn(REGEX.LINKEDINCOMPANY);
    },
  },
];
