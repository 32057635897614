import { useMutation, useQuery } from "@tanstack/react-query";

import presetsService from "@/services/presets.service";

import type { PresetEnrichmentType } from "@/types/presets.types";

export const usePresets = (enrichmentType?: PresetEnrichmentType) =>
  useQuery({
    queryKey: ["presets-v2", enrichmentType],
    queryFn: () => presetsService.getPresets(enrichmentType),
    staleTime: 24 * 60 * 1000, // 24 hours
    gcTime: 24 * 60 * 1000, // 24 hours
    refetchOnMount: "always",
  });

export const useCreatePreset = () =>
  useMutation({
    mutationFn: (payload: any) => presetsService.createNewPreset(payload),
  });
