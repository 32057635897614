import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import "./index.css";
import FilterIcon from "@/assets/svgs/filter.svg";
import Icons from "../Icons";
import { useTabStore } from "./store";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { motion } from "framer-motion";
import { HIDE_TABS } from "./constants";
dayjs.extend(relative);
dayjs.extend(duration);

const Header = () => {
  const activeTab = useTabStore((state) => state.activeTab);
  const allTabs = useTabStore((state) => state.tabs);
  const activateTab = useTabStore((state) => state.activateTab);
  const newTab = useTabStore((state) => state.newTab);
  const renameTab = useTabStore((state) => state.renameTab);
  const deleteTab = useTabStore((state) => state.deleteTab);
  const shownTabs = HIDE_TABS
    ? []
    : [activeTab]
        .concat([0, 1, 2, 3, 4].filter((i) => i !== activeTab))
        .slice(0, 3);
  const menuTabs = HIDE_TABS
    ? []
    : allTabs.filter((_, index) => !shownTabs.includes(index));
  return (
    <Box
      zIndex="dropdown"
      className="mx-[16px] flex items-center justify-between gap-4  bg-white px-[12px] pt-[24px]"
    >
      <Box className="flex items-center gap-4 place-self-center">
        <Button
          hidden={HIDE_TABS}
          onClick={() => newTab({ name: "New Search", at: 0 })}
          leftIcon={<Icons.AddIcon />}
          variant={"outline"}
          className=" translate-y-[-0.5em] place-self-start bg-white"
        >
          New Search
        </Button>
      </Box>
      <TabList gap={4} maxW={"calc(100vw - 50em)"} className="place-self-end">
        {allTabs.map(({ name: tab, id, createdAt }, index) => (
          <Tab
            hidden={!shownTabs.includes(index)}
            // _selected={{ bg: "#f5f3ff" }}
            key={id}
            className="group relative flex h-[3em]  max-h-full items-center gap-2 "
            w={`12em`}
            minW={`12em`}
            onDoubleClick={() => renameTab(id)}
          >
            <Box
              as={motion.div}
              zIndex={"dropdown"}
              className="tab-menu-open flex flex-col items-center gap-1"
            >
              <Tooltip label={tab} placement="top">
                <Text className="flex min-h-[1em] w-[10em] max-w-[10em] items-center gap-4 !overflow-y-auto truncate font-bold">
                  <img src={FilterIcon} alt="" />
                  {tab.slice(0, 13)}
                  {tab.length > 13 ? "..." : ""}
                </Text>
              </Tooltip>
              <Text className="tab-menu-buttons !hidden text-sm text-[#718096]">
                {dayjs(createdAt).fromNow()}
              </Text>
              <ButtonGroup
                className="tab-menu-buttons !hidden"
                variant={"ghost"}
                onClick={(e) => e.preventDefault()}
              >
                <IconButton
                  onClick={() => renameTab(id)}
                  icon={<Icons.EditIcon />}
                  aria-label={"edit"}
                />
                {/* duplicate */}
                <IconButton
                  onClick={() => newTab({ from: index })}
                  icon={<Icons.CopyIcon />}
                  aria-label={"edit"}
                />
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    deleteTab(id);
                  }}
                  icon={<Icons.DeleteIcon />}
                  aria-label={"edit"}
                />
              </ButtonGroup>
            </Box>
          </Tab>
        ))}
        {menuTabs.length ? (
          <Menu>
            <MenuButton
              as={IconButton}
              icon={
                <>
                  <Icons.MoreIcon />
                  <span className="absolute  right-[-5%] top-[20%] box-content size-[1.1em] rounded-full border-[1px] border-[solid] border-[white] bg-[purple] !text-[0.7em] text-white">
                    {menuTabs.length}
                  </span>
                </>
              }
              variant={"ghost"}
              className="relative !rounded-b-none !border-b-0"
            />
            <MenuList zIndex={"dropdown"}>
              {menuTabs.map(({ name: tab, id }) => (
                <MenuItem
                  key={id}
                  onClick={() => activateTab(id)}
                  className="!rounded-b-none !border-b-0"
                  // @ts-expect-error ignore
                  command={
                    <IconButton
                      size="xs"
                      onClick={() => deleteTab(id)}
                      aria-label="delete"
                      icon={<Icons.DeleteBinIcon />}
                    />
                  }
                >
                  {tab}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : null}
      </TabList>
    </Box>
  );
};

export default Header;
