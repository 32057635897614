import Icons from "@/components/Icons";
import { Box, Button, IconButton, Input } from "@chakra-ui/react";

const ProofPoints = ({
  proofPoints,
  handleAddProofPoint,
  handleDeleteProofPoint,
  handleInputChange,
}: {
  proofPoints: string[];
  handleAddProofPoint: () => void;
  handleDeleteProofPoint: (index: number) => void;
  handleInputChange: (index: number, value: string) => void;
}) => {
  return (
    <div className="border border-[#D0D4DC] rounded-[8px] p-[16px]">
      <div className="flex items-center justify-between">
        <p className="text-[16px] text-[#0F0D1C] font-medium">Proof Points</p>
        <Button
          className="!text-[13px] !font-medium !text-[#373F51]"
          variant="outline"
          size="sm"
          leftIcon={<Icons.AddIcon className="text-[#373F51]" />}
          onClick={handleAddProofPoint}
        >
          Add
        </Button>
      </div>
      <div
        className={`flex flex-col gap-3 ${proofPoints?.length > 0 && "mt-4"}`}
      >
        {proofPoints.map((point, index) => (
          <Box key={index} className="flex items-center gap-3">
            <Input
              value={point}
              size="md"
              className="!rounded-md"
              placeholder="Enter Proof Point"
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            <IconButton
              aria-label="delete"
              variant={"outline"}
              icon={<Icons.DeleteIcon className="text-[#7E889F]" />}
              size="sm"
              onClick={() => handleDeleteProofPoint(index)}
            />
          </Box>
        ))}
      </div>
    </div>
  );
};

export default ProofPoints;
