import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controls, MarkerType, Node, ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import PersanaTable from "../Common/AutoPilot/PersanaTable/PersanaTable";
import JobOpeningsSourceNode from "./JobOpeningsSourceNode/JobOpeningsSourceNode";

import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import { useTableList } from "@/hooks/useTableList";
import AutoPilotWrapper from "../Common/AutoPilot/AutoPilotWrapper";
import AutoPilotSidebar from "../Common/AutoPilot/Sidebar";
import { TableData } from "@/types/table.types";
import { useTableData, useTableInfo } from "@/hooks/useTableData";

const AUTO_PILOT_NODE_ID = {
  JOB_OPENING_SOURCE: "jobOpeningSource",
  JOB_OPENINGS_TABLE: "jobOpeningsTable",
  PEOPLE_TABLE: "peopleTable",
};

// const activeColor = "#078A52";
// const activeStrokeStyle = {
//   strokeWidth: 2,
//   stroke: activeColor,
// };

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE]: false,
  [AUTO_PILOT_NODE_ID.PEOPLE_TABLE]: false,
};

const AutoPilotJobOpeningsWorkflow: React.FC = () => {
  const [selectedTable, setSelectedTable] = useState<
    Partial<TableData> | undefined
  >({});
  const { data } = useTableData(selectedTable?._id || "", true);

  const linkedColumnIds = useMemo<string[]>(() => {
    return (
      data?.tableData?.columns
        ?.map(
          (column) => column?.metaData?.enrichmentMetaData?.dependentTableId,
        )
        .filter(Boolean) || []
    );
  }, [data?.tableData?.columns]);

  const { data: tableInfos } = useTableInfo(linkedColumnIds);

  const tables = useMemo(
    () =>
      tableInfos
        ? Array.isArray(tableInfos.data)
          ? tableInfos.data
          : [tableInfos.data]
        : [],
    [tableInfos],
  );

  const { data: allTables, refetch } = useTableList();
  const autoPilotJobOpeningsTables = allTables?.filter(
    (item) => item.workflowType === "job-openings",
  );
  const isConfigured = !!selectedTable?._id;
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
      target: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
  ]);
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    });
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  const updateNodes = useCallback(
    (linkedTable?: Partial<TableData>) => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const nodeWidth = 100;
        const topMargin = 150;
        const startX = (containerWidth - nodeWidth) / 2.5;

        const updatedNodes: Node[] = [
          {
            id: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
            type: "customA",
            position: { x: startX, y: topMargin },
            data: {
              showToolbar: !isConfigured
                ? true
                : toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE],
              isConfigured,
              tableData: selectedTable,
              refetch,
            },
          },
          {
            id: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
            type: "customB",
            position: { x: startX, y: topMargin + 200 },
            data: {
              tableTitle: "Job Openings Table",
              showToolbar: toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE],
              isDisabled: !isConfigured,
              tableData: selectedTable,
            },
          },
        ];

        if (linkedTable) {
          updatedNodes.push({
            id: AUTO_PILOT_NODE_ID.PEOPLE_TABLE,
            type: "customC",
            position: { x: startX, y: topMargin + 400 },
            data: {
              tableTitle: "People Table",
              showToolbar: toolbarState[AUTO_PILOT_NODE_ID.PEOPLE_TABLE],
              isDisabled: !isConfigured,
              tableData: linkedTable,
            },
          });

          setEdges([
            {
              id: "e1-2",
              source: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
              target: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
              },
            },
            {
              id: "e2-3",
              source: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
              target: AUTO_PILOT_NODE_ID.PEOPLE_TABLE,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
              },
            },
          ]);
        } else {
          setEdges([
            {
              id: "e1-2",
              source: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
              target: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
              },
            },
          ]);
        }

        setNodes(updatedNodes);
      }
    },
    [toolbarState, isConfigured, selectedTable, refetch],
  );

  useEffect(() => {
    if (!selectedTable?._id && autoPilotJobOpeningsTables?.length) {
      setSelectedTable(autoPilotJobOpeningsTables[0]);
    }
  }, [autoPilotJobOpeningsTables, selectedTable?._id]);

  useEffect(() => {
    updateNodes(tables?.[0]);
  }, [tables, updateNodes]);

  return (
    <>
      <AutoPilotWrapper>
        <AutoPilotSidebar
          title="Find Active Hiring Jobs"
          flowName="find_active_hiring_jobs"
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          autoPilotTables={autoPilotJobOpeningsTables}
        />
        <div
          ref={containerRef}
          style={{ width: "100%", height: "100%" }}
          onClick={handleCanvasClick}
        >
          {selectedTable?._id && (
            <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={{
                  customA: JobOpeningsSourceNode,
                  customB: PersanaTable,
                  customC: PersanaTable,
                }}
                onNodeClick={handleNodeClick}
              >
                <Controls />
              </ReactFlow>
            </TabContext.Provider>
          )}
        </div>
      </AutoPilotWrapper>
    </>
  );
};

export default AutoPilotJobOpeningsWorkflow;
