import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Divider,
  ModalBody,
  useDisclosure,
  ModalFooter,
  Box,
  useToast,
} from "@chakra-ui/react";
import Icons from "../Icons";
import { useGetAllEmailAccounts } from "./hooks/useGetAllEmailAccounts";

import { useCallback, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  GridReadyEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { useMutation } from "@tanstack/react-query";
import campaignService from "@/services/campaign.service";
import { queryClient } from "@/lib/queryClient";

const SelectSenderAccounts = ({ campaign }: { campaign: any }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: allEmailAccounts } = useGetAllEmailAccounts({
    offset: 0,
    limit: 100,
  });

  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [accountToRemove, setAccountsToRemove] = useState<any[]>([]);
  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      campaignService.addEmailAccountToCampaign(
        campaign.campaignId,
        selectedAccounts?.map((item) => item.id),
      ),
  });

  const { mutateAsync: removeAccountsMutateAsync, isPending: isLoading } =
    useMutation({
      mutationFn: () =>
        campaignService.removeEmailAccountFromCampaign(
          campaign.campaignId,
          accountToRemove?.map((item) => item.email_account_id),
        ),
    });

  const { mutateAsync: campaignDetailsMutateAsync, isPending: isRemoving } =
    useMutation({
      mutationFn: (payload: any) =>
        campaignService.updateCampaignDetails(payload),
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: ["userCampaignDetails"] });
        onClose();
      },
    });

  const columnDefs = useMemo(
    () =>
      [
        {
          checkboxSelection: true,
          headerCheckboxSelection: true,
          pinned: true,
          maxWidth: 50,
        },
        {
          headerName: "Name",
          field: "from_name",
          sortable: true,
          filter: true,
          width: 250,
          cellRenderer: (params: any) => <p>{params.data.from_name || ""}</p>,
        },
        {
          headerName: "Email",
          field: "from_email",
          sortable: true,
          filter: true,
          width: 250,
          cellRenderer: (params: any) => <p>{params.data.from_email || ""}</p>,
        },
      ] as ColDef<any>[],
    [],
  );

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();

    const deselectedRows = campaign?.senderAccounts?.filter(
      (account: any) =>
        !selectedRows.some((row) => row.id === account.email_account_id),
    );
    setAccountsToRemove(deselectedRows);
    setSelectedAccounts(selectedRows);
  }, []);

  const handleSave = async () => {
    if (accountToRemove?.length > 0) {
      await removeAccountsMutateAsync(undefined, {
        onSuccess(response: any) {
          if (response?.success) {
            const removedAccounts = campaign.senderAccounts.filter(
              (account: any) =>
                !accountToRemove.some(
                  (item) => item.email_account_id === account.email_account_id,
                ),
            );

            campaignDetailsMutateAsync({
              campaignId: campaign.campaignId,
              senderAccounts: removedAccounts,
            });
            setAccountsToRemove([]);
            queryClient.refetchQueries({ queryKey: ["userCampaignDetails"] });
            onClose();
            toast({
              title: "Success",
              description: "Sender accounts updated successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Error",
              description:
                response?.error?.message || "Failed to update sender accounts!",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
      });
    } else {
      await mutateAsync(undefined, {
        onSuccess(response: any) {
          if (response?.success) {
            response?.data?.data?.result?.length &&
              campaignDetailsMutateAsync({
                campaignId: campaign.campaignId,
                senderAccounts: [
                  ...campaign?.senderAccounts,
                  ...response?.data?.data?.result,
                ],
              });
            toast({
              title: "Success",
              description: "Sender accounts added successfully!",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          } else {
            toast({
              title: "Error",
              description:
                response?.error?.message || "Failed to add sender accounts!",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
          }
        },
      });
    }
  };

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const senderAccountIds = campaign?.senderAccounts?.map(
        (account: any) => account.email_account_id,
      );

      // Pre-select rows
      params.api.forEachNode((node) => {
        if (senderAccountIds.includes(node.data.id)) {
          node.setSelected(true);
        }
      });
    },
    [campaign],
  );

  return (
    <div>
      <Button variant={"outline"} size={"sm"} w={200} onClick={onOpen}>
        Choose Sender Accounts
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="scale"
        size={"3xl"}
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent h={"90%"}>
          <ModalHeader className="flex items-center justify-center bg-[#F5F3FF]">
            <div className="flex items-center flex-col">
              <p className="text-[20px] font-medium">Sender Accounts</p>
              <p className="text-[14px] text-[#0F0D1C] font-[400]">
                Who is sending this campaign?
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody className={`p-4 overflow-auto custom-scrollbar`}>
            <div className="flex flex-col gap-6">
              <Box
                className="custom-scrollbar ag-theme-quartz"
                overflow="auto"
                height="calc(100vh - 240px)"
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={allEmailAccounts?.data ?? []}
                  rowSelection="multiple"
                  suppressRowClickSelection
                  domLayout="normal"
                  onSelectionChanged={onSelectionChanged}
                  onGridReady={onGridReady}
                />
              </Box>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-full flex items-center justify-center gap-3">
              <Button
                onClick={onClose}
                variant={"outline"}
                leftIcon={<Icons.CloseIcon />}
                size={"sm"}
              >
                Cancel
              </Button>

              <Button
                rightIcon={<Icons.CheckCircleIcon />}
                colorScheme="purple"
                size={"sm"}
                className="hover:!bg-[#C03EDC] hover:!shadow-md"
                onClick={handleSave}
                isLoading={isPending || isLoading || isRemoving}
              >
                Save
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SelectSenderAccounts;
