import { Option } from "../../Common/MultiSelectDropdown/types";
import { create } from "zustand";

export interface CompanySearchState {
  inputtedValues: {
    company_type: string[];
    minimun_company_size: string;
    maximum_company_size: string;
    year_founded: string;
    isGreaterYearFounded: boolean;
    isSmallerYearFounded: boolean;
    name: string;
    company_name_prefix: string;
    website: string;
    followers: string;
    isMoreFollowers: boolean;
    isLessFollowers: boolean;
    revenue: Option | null;
    fromRevenue: Option | null;
    toRevenue: Option | null;
    isMoreRevenue: boolean;
    isLessRevenue: boolean;
    isRange: boolean;
    keyword: Option[];
    country_codes: Option[];
    industries: Option[];
    excluded_industries: Option[];
    company_size: Option | null;
    company_sizes: Option[];
    company_types: Option[];
    city_to_include: string;
    city_to_exclude: string;
    applyFilterData?: boolean;
    keywordPreciseSearch?: boolean;
    keywordComprehensiveSearch?: boolean;
    keywordMatchType?: string;
    // description_keywords: string;
  };
  setInputtedValues: (
    values: Partial<CompanySearchState["inputtedValues"]>,
  ) => void;
}

export const useCompanySearchStore = create<CompanySearchState>((set) => ({
  inputtedValues: {
    company_type: [],
    minimun_company_size: "",
    maximum_company_size: "",
    year_founded: "",
    isGreaterYearFounded: false,
    isSmallerYearFounded: false,
    name: "",
    company_name_prefix: "",
    website: "",
    followers: "",
    isMoreFollowers: false,
    isLessFollowers: false,
    revenue: null,
    fromRevenue: null,
    toRevenue: null,
    isMoreRevenue: true,
    isLessRevenue: false,
    isRange: false,
    keyword: [],
    country_codes: [],
    industries: [],
    excluded_industries: [],
    company_size: null,
    company_sizes: [],
    company_types: [],
    city_to_include: "",
    city_to_exclude: "",
    keywordPreciseSearch: true,
    keywordComprehensiveSearch: false,
    keywordMatchType: "any",
    // description_keywords: "",
  },
  setInputtedValues: (values) =>
    set((state) => ({
      inputtedValues: { ...state.inputtedValues, ...values },
    })),
}));
