import { Button, Divider, Text, Textarea, VStack } from "@chakra-ui/react";

type Props = {
  subjectPreview: string;
  emailTemplatePreview: string;
  isLoading: boolean;
  handlePreviewClick: (forPersonalizationPromptPreview: boolean) => void;
};
const RightSection = ({
  subjectPreview = "",
  emailTemplatePreview = "",
  isLoading,
  handlePreviewClick,
}: Props) => {
  return (
    <div className="w-[50%] h-full">
      <div className="flex items-center justify-between px-[20px] relative">
        <Text className="text-[15px] font-[500]">Preview of row #1</Text>
        <Button
          variant={"outline"}
          className="!bg-[#F5F3FF] !border !border-[#C03EDC] !text-[#C03EDC] text-[14px] !h-[30px]"
          size={"sm"}
          onClick={() => handlePreviewClick(false)}
          isLoading={isLoading}
        >
          {emailTemplatePreview ? "Regenerate Preview" : "Generate Preview"}
        </Button>
      </div>
      <Divider className="my-2" />

      <div>
        {!emailTemplatePreview && !subjectPreview ? (
          <div className="flex items-center justify-center mt-8">
            <p className="text-[14px] text-[#7E889F]">
              {isLoading ? "Generating Preview..." : "No Preview Available"}
            </p>
          </div>
        ) : (
          <>
            <VStack
              spacing={2}
              align="stretch"
              marginBottom={2}
              padding={"0 20px"}
              marginTop={4}
              height={102}
            >
              <Text fontSize="15px" fontWeight={600}>
                Subject Line
              </Text>
              <div className="border rounded-[6px] px-[20px] h-[50px] flex items-center">
                <p>{subjectPreview}</p>
              </div>
            </VStack>
            <Divider className="mt-4" />
            <div className="bg-[#FDFCFF] py-7 rounded-md px-[20px] min-h-[360px]">
              <Text fontSize="15px" fontWeight={600}>
                Email Body
              </Text>

              <Textarea
                style={{ cursor: "auto", opacity: 1 }}
                disabled
                className="text-[14px] mt-3 resize-none !custom-scrollbar !bg-white"
                rows={15}
                height={"auto"}
                value={emailTemplatePreview}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RightSection;
