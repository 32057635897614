import {
  Button,
  ButtonGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import AllLeadsGrid from "./LeadList";
import EditCampaignModal from "./EditCamapignModal";
import AllInboxListGrid from "./InboxList";
import Icons from "../Icons";
import useCampaignsDetails from "./hooks/useCampaignDetails";
import CampaignStatus from "../Common/Campaigns/CampaignStatus";
import { useCampaignStore } from "@/stores/campaigns.store";
import { useEffect } from "react";
import Analytics from "./Analytics";
import CampaignLoader from "../Common/Campaigns/CampaignLoader";
import { useRouter } from "@tanstack/react-router";

const CampaignDetails = ({ campaignId }: { campaignId: number }) => {
  const navigate = useNavigate();
  const router = useRouter();
  const search = router.state.location.search;
  const { data: campaignDetails, isLoading } = useCampaignsDetails(
    campaignId,
  ) as any;

  const campaignName = campaignDetails?.data?.name;
  const campaignStatus = campaignDetails?.data?.status;
  const createdAt = campaignDetails?.data?.created_at;

  const updateState = useCampaignStore((state) => state.updateState);

  useEffect(() => {
    updateState({
      selectedCampaign: campaignId,
    });
  }, [campaignId, campaignDetails, updateState]);

  useEffect(() => {
    if (Object.keys(search)?.includes("openEditModal")) {
      const newUrl = window.location.pathname;
      window.history.replaceState(null, "", newUrl);
    }
  }, [search]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center !h-screen -w-full">
        <CampaignLoader />
      </div>
    );
  }

  return (
    <>
      <div className="py-[8px] px-[32px] flex items-center justify-between bg-white">
        <div className="flex flex-col">
          <div className="flex items-center gap-3">
            <p className="text-[24px] font-bold leading-[32px]">
              {campaignName}
            </p>

            <CampaignStatus status={campaignStatus} />
          </div>
          <p className="text-sm text-[#282b4299] italic">
            Created at: {new Date(createdAt).toLocaleString()}
          </p>
        </div>
        <ButtonGroup>
          <Button
            size="sm"
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.DatabaseIcon />}
            className="!bg-[#F5F3FF] !text-[#373F51] !border !border-[#BD90F4] h-[40px] rounded-md"
            onClick={() => navigate({ to: "/campaigns" })}
          >
            All Campaigns
          </Button>
          <EditCampaignModal
            campaign={campaignDetails?.data}
            // @ts-ignore
            openEditModal={search?.openEditModal}
          />
        </ButtonGroup>
      </div>
      <Tabs className="mt-4">
        <TabList>
          <Tab
            className="font-bold w-[200px]"
            _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
          >
            Analytics
          </Tab>
          <Tab
            className="font-bold w-[200px]"
            _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
          >
            Inbox
          </Tab>
          <Tab
            className="font-bold w-[200px]"
            _selected={{ color: "#5024DC", borderBottomColor: "#5024DC" }}
          >
            Lead List
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Analytics campaignId={campaignId} />
          </TabPanel>
          <TabPanel>
            <AllInboxListGrid campaignId={campaignId} />
          </TabPanel>
          <TabPanel>
            <AllLeadsGrid campaignId={campaignId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CampaignDetails;
