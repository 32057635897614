export type TemplateTypes = {
  templateId: string;
  heading: string;
  url: string;
  strength: string;
};

export const allTemplates: TemplateTypes[] = [
  {
    templateId: "1",
    heading: "Find work emails accurately",
    url: "/tools",
    strength: "99% accuracy",
  },
  {
    templateId: "2",
    heading: "Write personalized emails to prospects.",
    url: "/playbooks/Write personalized emails to prospects",
    strength: "High reply rate",
  },
  {
    templateId: "3",
    heading: "Find people",
    url: "/search",
    strength: "700M contacts",
  },
];
