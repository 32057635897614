import Tools from "@/components/Tools";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/tools/").createRoute({
  component: ToolsPage,
  errorComponent: () => <div>Error</div>,
});

function ToolsPage() {
  return <Tools />;
}

export default ToolsPage;
