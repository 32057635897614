import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import type { PresetEnrichmentType } from "@/types/presets.types";
import { Descendant } from "slate";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

type State = {
  isOpenPresetsModal: boolean;
  presetsModalData: {
    modalType?: PresetEnrichmentType;
    selectedPresetId?: string;
    metaData?: any;
  };
  selectedPresetValues?: any;
  condition: any;
  model: any;
  maxCost: any;
  endpoint?: any;
  body?: any;
  headers?: CustomField[];
  selectedPreset?: any;
  selectedMethod?: Option | null;
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
  clearState: () => void;
};

const DEFAULT_STATE: State = {
  isOpenPresetsModal: false,
  presetsModalData: {},
  selectedPresetValues: null,
  endpoint: null,
  body: null,
  headers: [],
  condition: null,
  model: null,
  maxCost: null,
  selectedMethod: null,
};

export const usePresetsStore = create<State & Actions>((set) => ({
  ...DEFAULT_STATE,
  updateState: (payload) => set(() => payload),
  clearState: () =>
    set(() => ({
      ...DEFAULT_STATE,
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Presets", usePresetsStore);
}
