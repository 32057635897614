import { useEffect, useState } from "react";
import {
  WalkThroughModalActionType,
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "../Common/AutoPilot/WalkThroughModal";
import { TableData } from "@/types/table.types";
import WalkThroughInfo from "../Common/AutoPilot/WalkThroughModal/WalkThroughInfo";
import {
  Button,
  Checkbox,
  HStack,
  Input,
  Switch,
  Text,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import Icons from "../Icons";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";
import { frequencyList } from "../Common/AutoPilot/constants";
import { Option } from "../Common/MultiSelectDropdown/types";
import leadService from "@/services/leads.service";
import { queryClient } from "@/lib/queryClient";
import tableService from "@/services/table.service";

interface Props {
  totalSteps: number;
  action: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | undefined;
}
const ExperienceLevels = [
  { label: "Junior", value: "entry" },
  { label: "Mid-Level", value: "mid" },
  { label: "Senior", value: "senior" },
  { label: "Executive", value: "executive" },
] as Option[];
function LeadmagicFlowInputs({ totalSteps, action, selectedTableData }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [isRemote, setIsRemote] = useState(false);
  const [isNotifyByEmail, setIsNotifyByEmail] = useState(false);
  const [postedWithin, setPostedWithin] = useState(30);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [exeperienceLevel, setExperienceLevel] = useState<Option | null>(null);
  const [isFindPeopleAtCompany, setIsFindPeopleAtCompany] = useState(false);
  const [findPeopleJobTitles, setFindPeopleJobTitles] = useState<Option[]>([]);
  const toast = useToast();
  const isConfigured = !!selectedTableData;
  const payloads = [
    {
      name: "Job Title",
      value: jobTitle,
      setter: (value: string) => setJobTitle(value),
      type: "text",
    },
    {
      name: "Company Name",
      value: companyName,
      setter: (value: string) => setCompanyName(value),
      type: "text",
    },
    {
      name: "Company Website",
      value: companyWebsite,
      setter: (value: string) => setCompanyWebsite(value),
      type: "text",
    },
    {
      name: "Location",
      value: location,
      setter: (value: string) => setLocation(value),
      type: "text",
    },
    {
      name: "Experience Level",
      value: exeperienceLevel,
      setter: (value: Option) => setExperienceLevel(value),
      type: "dropdown",
      options: ExperienceLevels,
    },
    {
      name: "Job Description",
      value: jobDescription,
      setter: (value: string) => setJobDescription(value),
      type: "text",
      hint: `Use quotes (" ") for exact matches, OR for alternatives, and - to exclude terms. Example: "python" OR "javascript" -frontend.`,
    },
    // {
    //   name: "Is Remote",
    //   value: isRemote,
    //   setter: (value: boolean) => setIsRemote(value),
    //   type: "checkbox",
    // },
    {
      name: "Posted Within",
      value: postedWithin,
      setter: (value: number) => setPostedWithin(value),
      type: "number",
    },
  ] as {
    name: string;
    value: string | number | boolean | Option | null;
    setter: (value: any) => void;
    type: "text" | "dropdown" | "checkbox" | "number";
    options?: Option[];
    hint?: string;
    learnMore?: string;
  }[];

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runLeadmagicJobOpenings(
      {
        job_title: jobTitle,
        location,
        company_name: companyName,
        company_website: companyWebsite,
        job_description: jobDescription,
        has_remote: !!isRemote,
        posted_within: postedWithin,
        experience_level: exeperienceLevel?.value,
        frequencyToUpdate: frequencyToUpdate?.value,
        tableId: selectedTableData?._id || undefined,
        isFindPeopleAtCompany,
        isNotifyByEmail,
        findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
      },
      "create-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runLeadmagicJobOpenings(
      {
        tableId: selectedTableData?._id || "",
      },
      "update-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${selectedTableData?._id}`, "_blank");
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      selectedTableData?._id || "",
      {
        metaData: {
          job_title: jobTitle,
          location,
          company_name: companyName,
          company_website: companyWebsite,
          job_description: jobDescription,
          has_remote: !!isRemote,
          posted_within: postedWithin,
          experience_level: exeperienceLevel?.value,
          frequencyToUpdate: frequencyToUpdate?.value,
          isFindPeopleAtCompany,
          isNotifyByEmail,
          findPeopleJobTitles: findPeopleJobTitles?.map((item) => item.value),
        },
      },
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (jobTitle !== selectedTableData?.metaData?.jobTitle) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value == selectedTableData?.metaData?.frequencyToUpdate,
    ) as Option;

    const experience_level = ExperienceLevels?.find(
      (item) => item.value === selectedTableData?.metaData?.experience_level,
    ) as Option;
    setJobTitle(selectedTableData?.metaData?.job_title || "");
    setLocation(selectedTableData?.metaData?.location || "");
    setCompanyName(selectedTableData?.metaData?.company_name || "");
    setCompanyWebsite(selectedTableData?.metaData?.company_website || "");
    setJobDescription(selectedTableData?.metaData?.job_description || "");
    setIsRemote(!!selectedTableData?.metaData?.has_remote || false);
    setPostedWithin(selectedTableData?.metaData?.posted_within || 30);
    setIsFindPeopleAtCompany(
      selectedTableData?.metaData?.isFindPeopleAtCompany,
    );
    setExperienceLevel(experience_level || exeperienceLevel);
    setIsNotifyByEmail(selectedTableData?.metaData?.isNotifyByEmail || false);
    setFrequencyToUpdate(freq);
    const savedJobTitles =
      selectedTableData?.metaData?.findPeopleJobTitles?.map((item: string) => ({
        label: item,
        value: item,
      }));
    setFindPeopleJobTitles(savedJobTitles || []);
  }, [selectedTableData]);

  return (
    <>
      <WalkThroughModalBody>
        <WalkThroughInfo
          info="Find Job Openings With Leadmagic"
          className="border-b-[1.5px] border-[#F0EFF1] py-4"
        />
        <div className="h-full space-y-4 px-4 pb-5 pt-0">
          <VStack spacing={1} align={"stretch"}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
          </VStack>
          <VStack align="stretch">
            <div className="flex items-center justify-between">
              <Text fontSize="md" fontWeight="semibold">
                How frequently you want to find new jobs?
              </Text>

              <Checkbox
                isChecked={isNotifyByEmail}
                onChange={(e) => setIsNotifyByEmail(e.target.checked)}
                size="md"
                colorScheme="purple"
              >
                Notify me via email
              </Checkbox>
            </div>
            <MultiSelectDropdown
              isMulti={false}
              placeholder="Frequency to find new jobs"
              closeMenuOnSelect
              options={frequencyList}
              value={frequencyToUpdate}
              onChange={(selected) => setFrequencyToUpdate(selected as Option)}
            />
          </VStack>
          {payloads.map((item, index) => (
            <VStack
              key={index}
              align="stretch"
              pb={index === payloads.length - 1 ? 4 : 0}
            >
              <Text fontSize="md" fontWeight="semibold">
                {item.name}
              </Text>
              {item.type === "text" && (
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.value as string}
                  onChange={(e) => item.setter(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                  type="text"
                />
              )}
              {item.type === "dropdown" && (
                <MultiSelectDropdown
                  isMulti={false}
                  placeholder={`Select ${item.name}`}
                  closeMenuOnSelect
                  options={item.options || []}
                  value={item.value as Option}
                  onChange={(selected) => {
                    const value = selected as Option;
                    item.setter(value);
                  }}
                />
              )}
              {/* {item.type === "checkbox" && (
                <input
                  type="checkbox"
                  checked={item.value}
                  onChange={(e) => item.setter(e.target.checked)}
                  className="border border-gray-300 rounded-md p-2"
                />
              )} */}
              {item.type === "number" && (
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.value as number}
                  onChange={(e) => item.setter(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                  type="number"
                />
              )}
              {item.hint && (
                <Tooltip
                  className="!z-[100000]"
                  label={
                    <div>
                      <ol className="p-4">
                        <li>
                          <strong>OR:</strong> Include results matching any of
                          the specified keywords. <br />
                          <em>Example:</em>{" "}
                          <code>
                            &quot;lead generation&quot; OR &quot;sales
                            automation&quot;
                          </code>
                        </li>
                        <li>
                          <strong>AND:</strong> Implicit when terms are
                          separated by spaces (no explicit AND needed). <br />
                          <em>Example:</em>{" "}
                          <code>
                            &quot;lead generation&quot; &quot;CRM
                            integration&quot;
                          </code>
                        </li>
                        <li>
                          <strong>NOT:</strong> Use <code>-</code> to exclude
                          specific keywords. <br />
                          <em>Example:</em>{" "}
                          <code>
                            &quot;sales automation&quot; -&quot;cold
                            outreach&quot;
                          </code>
                        </li>
                        <li>
                          <strong>Exact Phrase Match:</strong> Use quotes (
                          <code>&quot;&quot;</code>) to match exact phrases.{" "}
                          <br />
                          <em>Example:</em>{" "}
                          <code>&quot;lead generation software&quot;</code>
                        </li>
                        <li>
                          <strong>Grouping:</strong> Use parentheses for logical
                          grouping. <br />
                          <em>Example:</em>{" "}
                          <code>
                            (&quot;lead generation&quot; OR &quot;sales
                            automation&quot;) -&quot;cold outreach&quot;
                          </code>
                        </li>
                      </ol>
                    </div>
                  }
                >
                  <Text className="!z-[100000] flex items-center gap-2 text-xs text-[gray]">
                    <Icons.InformationIcon /> {item.hint}
                  </Text>
                </Tooltip>
              )}
            </VStack>
          ))}
          <HStack align={"center"} gap={4} className="!mt-3">
            <Text fontSize="md" fontWeight="semibold">
              Would you like to find people at company?
            </Text>
            <Switch
              isChecked={isFindPeopleAtCompany}
              size="md"
              onChange={(e) => setIsFindPeopleAtCompany(e.target.checked)}
              id={`source-switch`}
              className="text-[14px] flex font-medium"
              colorScheme="purple"
            />
          </HStack>
          {isFindPeopleAtCompany && (
            <div className="!mt-3">
              <p className="pb-1 text-base font-semibold">
                {"Add job titles to find people"}
              </p>
              <MultiSelectDropdown
                value={findPeopleJobTitles}
                options={[]}
                onChange={(selectedValues) =>
                  setFindPeopleJobTitles(selectedValues as Option[])
                }
                placeholder={"Add job titles to include"}
              />
            </div>
          )}
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={isLoading}
        onSave={async () => {
          action === "new"
            ? await handleAutoPilot()
            : await updatePreferences();
        }}
        totalSteps={totalSteps}
        action={action}
      />
    </>
  );
}

export default LeadmagicFlowInputs;
