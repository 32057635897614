import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { allEnrichments } from "@/components/Enrichment/constants";
import { useEnrichStore } from "@/stores/enrich.store";
import { Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { defaultPersonalizationPrompts, preferenceOptions } from "./constants";
import Icons from "@/components/Icons";
import { convertTextToSlate } from "@/utils";

type TemplatePreferenceProps = {
  selectedPreference: Option | null;
  setSelectedPreference: (val: any) => void;
  handleModalClose: () => void;
  handleDetails: (key: string, val: any) => void;
  setPersonalization: (val: any) => void;
};
const TemplatePreference = ({
  selectedPreference,
  setSelectedPreference,
  handleModalClose,
  handleDetails,
  setPersonalization,
}: TemplatePreferenceProps) => {
  const { updateState: updateEnrichState } = useEnrichStore();
  const textColor = useColorModeValue("gray.700", "gray.200");
  return (
    <VStack align="stretch">
      <Text fontSize="md" fontWeight="semibold" color={textColor}>
        Select Email Preference <sup>*</sup>
      </Text>
      <div className="border border-[#D0D4DC] rounded-[8px] flex flex-col">
        {preferenceOptions?.map((item) => (
          <div
            key={item.value}
            className="flex items-start gap-4 px-[20px] py-[16px] rounded-md cursor-pointer hover:bg-[#F5F3FF]"
            onClick={() => {
              setSelectedPreference(item);
              const slateFormattedPersonalization = convertTextToSlate(
                // @ts-ignore
                defaultPersonalizationPrompts?.[item?.label],
              );
              setPersonalization(slateFormattedPersonalization);
              if (item.label === "Custom Email Template") {
                handleDetails("body", [
                  {
                    type: "paragraph",
                    children: [{ text: "{{ai_personalization}}" }],
                  },
                ]);
              } else if (item.label === "Static Email Template") {
                handleDetails("body", [
                  {
                    type: "paragraph",
                    children: [{ text: "" }],
                  },
                ]);
              } else if (item.label === "Generate Email for LinkedIn Connect") {
                handleDetails("body", [
                  {
                    type: "paragraph",
                    children: [{ text: "\n" }],
                  },
                ]);
              }
            }}
          >
            {selectedPreference?.value?.includes(item.value) ? (
              <Icons.CircleCheck className="mt-2 text-[18px] text-[#5024DC]" />
            ) : (
              <Icons.CircleIcon className="mt-2 text-[18px]" />
            )}

            <div>
              <p className="text-[16px] text-[#0F0D1C]">{item.label}</p>
              <p className="text-xs text-[#4A5264]">
                {item.value === "People Information" && (
                  <>
                    {`Make sure to have {{summary}} column in
                                    your table. Use `}
                    <span
                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                      onClick={() => {
                        handleModalClose();
                        updateEnrichState({
                          selectedEnrichments: allEnrichments?.find(
                            (item) => item.id === "linkedInDetails",
                          ),
                          isOpenEnrichModal: false,
                          viewMode: "create",
                        });
                      }}
                    >
                      Person Profile
                    </span>{" "}
                    enrichment to get the summary details.
                  </>
                )}
                {item.value === "Company Information" && (
                  <>
                    {`Make sure to have {{company_info}} column
                                    in your table. Use `}
                    <span
                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                      onClick={() => {
                        handleModalClose();
                        updateEnrichState({
                          selectedEnrichments: allEnrichments?.find(
                            (item) => item.id === "companyDetails",
                          ),
                          isOpenEnrichModal: false,
                          viewMode: "create",
                        });
                      }}
                    >
                      Company Profile
                    </span>{" "}
                    enrichment to get the company details.
                  </>
                )}
                {item.value === "LinkedIn Connect" && (
                  <>
                    {`Make sure to have {{person_name}} and
                                    {{linkedin_summary}} columns in your
                                    table. Use `}
                    <span
                      className="cursor-pointer text-[#5024DC] underline hover:text-[#7B5CED]"
                      onClick={() => {
                        handleModalClose();
                        updateEnrichState({
                          selectedEnrichments: allEnrichments?.find(
                            (item) => item.id === "linkedInDetails",
                          ),
                          isOpenEnrichModal: false,
                          viewMode: "create",
                        });
                      }}
                    >
                      Person Profile
                    </span>{" "}
                    enrichment to get the person details.
                  </>
                )}
                {item.value === "Custom Email Template" && (
                  <>Create your own template with AI</>
                )}
                {item.value !== "People Information" &&
                  item.value !== "Company Information" &&
                  item.value !== "LinkedIn Connect" &&
                  item.value !== "Custom Email Template" && (
                    <>Create your own template without AI</>
                  )}
              </p>
            </div>
          </div>
        ))}
      </div>
    </VStack>
  );
};

export default TemplatePreference;
