import { Divider, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import CustomTextEditor from "@/components/Enrichment/Common/CustomTextEditor";

interface Props {
  mailDetails: {
    subject: any;
    body: any;
  };
  isLoading: boolean;
  handleDetails: (key: string, value: any) => void;
}

const DefaultPrompt = ({ mailDetails, handleDetails, isLoading }: Props) => {
  const textColor = useColorModeValue("gray.700", "gray.200");
  const showContent = true;

  return (
    <div className="w-[50%]" style={{ borderRight: "1px solid #F0EFF1" }}>
      {showContent && (
        <>
          {/* <p className="text-sm italic text-[#4A5264]">
           {`Note:  In this step, we will create your template. Please don't remove {{first_name}} and {{ai_personalization}}.  Select the first name field on your table.`}
         </p> */}
          <div className="flex items-center h-[30px]">
            <Text className="text-[15px] font-[500] px-[20px]">Prompt</Text>
          </div>
          <Divider className="my-2" />
          <VStack spacing={2} align="stretch" padding={"0 20px"} marginTop={3}>
            <Text fontSize="15px" fontWeight={600} color={textColor}>
              Subject Line
            </Text>
            <CustomTextEditor
              editorHeight="20px"
              slateValue={mailDetails.subject}
              setSlateValue={(newInputText) => {
                handleDetails("subject", newInputText);
              }}
            />
          </VStack>
          <Divider className="mt-4" />
        </>
      )}
      <div className="flex flex-col gap-2 py-6 bg-[#FDFCFF] px-[20px]">
        <Text fontSize="15px" fontWeight={600} marginTop={2}>
          Default Template
        </Text>
        {isLoading ? (
          <p className="text-[14px] text-[#7E889F]">Generating template...</p>
        ) : (
          <CustomTextEditor
            includeAIPersonalizationOption
            slateValue={mailDetails.body}
            setSlateValue={(newInputText) => {
              handleDetails("body", newInputText);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DefaultPrompt;
