import { useUserConfig } from "@/hooks/useUserConfig";

export interface Filter {
  isPinned?: boolean;
  name?: string;
  [key: string]: ANY;
}

interface UseFilterManager {
  save: (newFilter: Filter) => void;
  list: () => Filter[];
  remove: (index: number) => void;
  update: (index: number, filter: Filter) => void;
  isSaving: boolean;
  isDeleting: boolean;
}

export const useFilterManager = (key: string): UseFilterManager => {
  const filterKey = `${key}_filters`;
  const [userConfigQuery, userConfigMutation] = useUserConfig(filterKey);

  const listFilters = (): Filter[] => {
    return (userConfigQuery.data?.config?.[filterKey] || []) as Filter[];
  };

  const saveFilter = (newFilter: Filter): void => {
    const existingFilters = listFilters();
    const filterWithDate = {
      ...newFilter,
      filterKey,
      savedDate: new Date().toISOString(),
    };

    userConfigMutation.mutate([filterWithDate, ...existingFilters]);
  };

  const removeFilter = (index: number): void => {
    const existingFilters = listFilters();
    const updatedFilters = existingFilters.filter((_, i) => i !== index);
    userConfigMutation.mutate(updatedFilters);
  };

  const updateAtIndex = (index: number, newFilter: Filter) => {
    const existingFilters = listFilters();
    userConfigMutation.mutate(existingFilters.with(index, newFilter));
  };
  return {
    save: saveFilter,
    list: listFilters,
    remove: removeFilter,
    update: updateAtIndex,
    isSaving: userConfigMutation.isPending,
    isDeleting: userConfigMutation.isPending,
  };
};
