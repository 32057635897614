import PersanaTools from "@/assets/svgs/persana-tools.svg";

const Header = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 p-[32px]">
      <span className="flex items-center gap-4 text-[3em] font-semibold ">
        <img src={PersanaTools} height={32} width={32} alt="" />
        <p className="text-[30px] font-medium">
          Persana{" "}
          <span className="italic text-transparent bg-clip-text bg-gradient-to-r from-[#8E32A4] to-[#5024DC]">
            Tools
          </span>
        </p>
      </span>
    </div>
  );
};

export default Header;
