import { useEffect, useRef, useState } from "react";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { MarkerType, Node, ReactFlow } from "@xyflow/react";

import IntroductionEmail from "./IntroductionEmail";
import FollowUp from "./FollowUp";
import BreakUp from "./BreakUp";
import CampaignGeneration from "@/components/Common/Campaigns/CampaignGeneration";

import Icons from "@/components/Icons";
import NiaImg from "@/assets/svgs/nia-campaign.svg";
import "@xyflow/react/dist/style.css";

const Flowchart = ({
  haveFullAccess,
  handleFullAccess,
}: {
  haveFullAccess: boolean;
  handleFullAccess: (status: boolean) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const activeColor = "#693dc7";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const activeStrokeStyle = {
    strokeWidth: 2,
    stroke: activeColor,
  };

  const SEQUENCE_NODE_ID = {
    INTRODUCTION_EMAIL: "introductionEmail",
    FOLLOW_UP: "followUp",
    BREAK_UP: "breakUp",
  };

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: SEQUENCE_NODE_ID.INTRODUCTION_EMAIL,
      target: SEQUENCE_NODE_ID.FOLLOW_UP,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
    {
      id: "e2-3",
      source: SEQUENCE_NODE_ID.FOLLOW_UP,
      target: SEQUENCE_NODE_ID.BREAK_UP,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
  ]);

  const handleEditCampaign = async () => {
    if (haveFullAccess) {
      handleFullAccess(true);
    } else {
      onOpen();
      setTimeout(() => {
        onClose();
      }, 3000);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 150;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const updatedNodes: Node[] = [
        {
          id: SEQUENCE_NODE_ID.INTRODUCTION_EMAIL,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {},
        },
        {
          id: SEQUENCE_NODE_ID.FOLLOW_UP,
          type: "customB",
          position: { x: startX, y: topMargin + 200 },
          data: {},
        },
        {
          id: SEQUENCE_NODE_ID.BREAK_UP,
          type: "customC",
          position: { x: startX, y: topMargin + 400 },
          data: {},
        },
      ];
      setNodes(updatedNodes);

      const updatedEdges = edges.map((edge) => {
        if (edge.source === SEQUENCE_NODE_ID.INTRODUCTION_EMAIL) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (edge.target === SEQUENCE_NODE_ID.FOLLOW_UP) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        if (edge.target === SEQUENCE_NODE_ID.BREAK_UP) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }

        return edge;
      });

      setEdges(updatedEdges);
    }
  }, [containerRef?.current]);

  useEffect(() => {
    onOpen();

    setTimeout(() => {
      onClose();
    }, 3000);
  }, []);

  return (
    <>
      {/* Header */}
      <div className="relative flex items-center bg-white px-[32px] py-[8px] w-full h-[70px]">
        {/* Middle Content - Centered */}
        <div className="absolute left-1/2 transform -translate-x-1/2 top-2 flex items-center gap-4 bg-[#F5F3FF] border border-[#8E32A4] rounded-[10px] px-[16px] py-[6px]">
          <img src={NiaImg} alt="" height={40} width={40} />
          <div>
            <p className="text-[14px] text-[#0F0D1C] font-medium mb-1">
              Nia is generating your email content
            </p>
            <p className="text-[#7E889F] text-[13px]">
              Nia will notify you when your campaign is set.
            </p>
          </div>
        </div>

        {/* Edit Campaign Button - Right */}
        <Tooltip label="Help">
          <IconButton
            aria-label="info"
            size={"md"}
            colorScheme="primary"
            variant="outline"
            className="ml-auto !cursor-pointer"
            onClick={handleEditCampaign}
            icon={<Icons.InformationIcon />}
          />
        </Tooltip>
      </div>

      {/* Flowchart */}
      <div
        ref={containerRef}
        style={{
          width: "calc(100vw - 10px)",
          height: "100%",
          marginTop: "20px",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{
            customA: IntroductionEmail,
            customB: FollowUp,
            customC: BreakUp,
          }}
          fitView={true}
        ></ReactFlow>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <CampaignGeneration />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Flowchart;
