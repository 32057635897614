import { UseDisclosureReturn } from "@chakra-ui/react";
import { Filter } from "./useFilterManager";

import { useSearchLeadsStore } from "../store";
import { SaveSearchModal } from "./SaveSearchModal";

export const SaveCurrentPeopleFilter = ({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) => {
  const currentFilter: Filter = useSearchLeadsStore((state) => ({
    naturalFilters: state.naturalFilters,
    expandedFields: state.expandedFields,
    sideBarInput: state.sideBarInput,
    currentSearchPayload: state.currentSearchPayload,
    assistantInput: state.assistantInput,
    valueLabels: state.valueLabels,
  }));

  return (
    <div>
      <SaveSearchModal
        filterKey="people_search"
        disclosure={disclosure}
        currentFilter={currentFilter}
      />
    </div>
  );
};
