import KeySelect from "@/components/Common/KeySelect";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import PersanaAccordion from "@/components/Common/PersanaAccordion";
import CustomTextEditor from "../../Common/CustomTextEditor";
import { Descendant } from "slate";
import { TSelectedColumnOption } from "@/types/common.types";
import { arrayToObject } from "@/lib/utils";
import { Button, Input } from "@chakra-ui/react";
import ConditionalFormula from "../../Common/ConditionalFormula";
import WatchTutorial from "@/components/Common/WatchTutorial";
import { useReducer, useState } from "react";

type CustomField = {
  id: string;
  name?: string;
  value?: Descendant[];
};

const PipeDriveOrganization = () => {
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const initialSlateValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const initialState = {
    organization: initialSlateValue,
    ownerId: initialSlateValue,
    address: initialSlateValue,
  };

  function reducer(state: any, action: any) {
    return { ...state, [action.key]: action.value };
  }

  const [contactDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = (key: string, value: any) => dispatch({ key, value });

  const optionOptions = [
    {
      name: "Organization Name",
      state: contactDetails.organization,
      description: "",
      setState: (value: any) => {
        updateDetails("organization", value);
      },
    },
    {
      name: "Owner Id",
      state: contactDetails.ownerId,
      description: "",
      setState: (value: any) => {
        updateDetails("ownerId", value);
      },
    },
    {
      name: "Address",
      description: "",
      state: contactDetails.address,
      setState: (value: any) => {
        updateDetails("address", value);
      },
    },
  ];

  const handleCustomField = (
    id: string,
    name: string,
    value: Descendant[] | string,
  ): void => {
    setCustomFields((prevFields: CustomField[]) =>
      prevFields.map((field: CustomField) =>
        field.id === id ? { ...field, [name]: value } : field,
      ),
    );
  };

  const handleRemoveCustomField = (name: string): void => {
    const newFields = customFields?.filter(
      (field: CustomField) => field.name !== name,
    );
    setCustomFields(newFields);
  };

  const handleAddCustomField = (): void => {
    const newField: CustomField = {
      id: `custom_field_name_${customFields.length + 1}`,
      name: `Custom Field Name ${customFields.length + 1}`,
      value: initialSlateValue,
    };

    setCustomFields((prevFields: CustomField[]) => [...prevFields, newField]);
  };

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Create or Update Pipe Drive Organization"}
          link={""}
        />

        <KeySelect
          providerName={"pipeDriveApiKey"}
          providerTitle="pipeDrive API Key"
          disablePersanaKey={true}
        />

        <SelectColumnDropdown
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />

        <p className="text-purple-500 font-mediu mb-3 mt-1 text-sm">
          Note: Please connect your account
        </p>

        <PersanaAccordion
          btnStyle={{
            backgroundColor: "rgb(250 250 251)",
            borderRadius: "6px",
          }}
          items={[
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add More Contact Details (Optional)
                </button>
              ),

              panel: (
                <div className="space-y-3">
                  {optionOptions.map((item) => (
                    <div key={item.name}>
                      <p className="pb-1.5 text-[0.95rem] font-medium">
                        {item?.name}
                      </p>
                      <CustomTextEditor
                        editorHeight={"4rem"}
                        slateValue={item?.state}
                        setSlateValue={item?.setState}
                        placeholder={`Start typing or use the dropdown to select a column.`}
                      />
                    </div>
                  ))}
                </div>
              ),
            },
            {
              button: (
                <button className="w-full truncate text-left font-semibold text-[#000000]">
                  Add Custom Fields (Optional)
                </button>
              ),

              panel: (
                <>
                  {customFields?.map((field: any, index: number) => (
                    <div
                      className="mt-3 flex items-center justify-start gap-[5px]"
                      key={`other-fields-${index}`}
                    >
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">name</p>
                        <Input
                          maxW={"200px"}
                          value={field?.name || ""}
                          placeholder={"Enter name..."}
                          name={"name"}
                          onChange={(e) =>
                            handleCustomField(
                              field.id,
                              e.target.name,
                              e.target.value,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <p className="pb-1 text-base font-semibold">value</p>
                        <CustomTextEditor
                          editorHeight={"1rem"}
                          slateValue={field.value}
                          setSlateValue={(val) =>
                            handleCustomField(field.id, "value", val)
                          }
                          showDetails={false}
                        />
                      </div>
                      <Button
                        className="mb-1 self-end"
                        onClick={() => {
                          handleRemoveCustomField(field.name);
                        }}
                        size={"sm"}
                      >
                        X
                      </Button>
                    </div>
                  ))}

                  {customFields?.length < 20 ? (
                    <div className="mt-5">
                      <Button size={"sm"} onClick={handleAddCustomField}>
                        + Add New Custom Field
                      </Button>
                      <span className={"block py-1 text-sm text-[#676d7e]"}>
                        Any fields you want to add to the lead. Put the name of
                        the field on the left and the value on the right.
                      </span>
                    </div>
                  ) : null}
                </>
              ),
            },
          ]}
        />
        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.keyId}
        apiPayload={{
          apiKeyName: "pipeDriveApiKey",
          apiKeyErrorMessage: "Please connect your pipe drive account",
          columnId: selectedOption?.keyId,

          contactDetailsBySlateValue: {
            ...contactDetails,
          },
          customFields:
            customFields?.length > 0 ? arrayToObject(customFields) : {},
        }}
      />
    </>
  );
};

export default PipeDriveOrganization;
