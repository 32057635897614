import { useEffect, useMemo, useState } from "react";
import { getExportFields } from "../../utils";
import { useTableInfo } from "@/hooks/useTableData";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { tableActionIcons } from "@/components/Icons/tableActionIcons";
import Icons from "@/components/Icons";
import CustomAccordion from "@/components/Common/CustomAccordion";
import Select from "react-select";

export const ExportMapper = ({
  isForEmailEnrichment,
  selectedTableId,
  setMapping,
}: {
  isForEmailEnrichment: boolean;
  selectedTableId: string;
  setMapping: (mapping: {
    fieldMap: { [field: string]: string };
    isComplete: boolean;
  }) => void;
}) => {
  // Get the fields to map
  const fields: { [field: string]: string } = useMemo(
    () => getExportFields(isForEmailEnrichment),
    [isForEmailEnrichment],
  );

  // Fetch table data
  const { data, isFetching, isError } = useTableInfo(selectedTableId);

  const {
    columns,
    columnIcons,
    fieldIcons,
    setFieldMappings,
    fieldMappings,
    totalFieldsToMap,
    currentMappedFields,
  } = useMappings();

  // Load the initial mappings
  useEffect(() => {
    const initialMappings: { [key: string]: string } = {};
    Object.keys(fields).forEach((field) => {
      const matchedColumn = columns.find((col: ANY) => col.field === field);
      if (matchedColumn) {
        initialMappings[field] = matchedColumn._id;
      }
    });
    setFieldMappings(initialMappings);
  }, [columns]);

  // Send the mapping to the parent
  useEffect(() => {
    const isComplete = currentMappedFields === totalFieldsToMap;
    setMapping({
      fieldMap: fieldMappings,
      isComplete,
    });
  }, [fieldMappings, totalFieldsToMap, currentMappedFields]);

  if (isFetching) return <div>Loading...</div>;
  if (isError) return <div>Error loading table data</div>;

  const handleMappingChange = (field: string, columnId: string) => {
    setFieldMappings((prev) => ({ ...prev, [field]: columnId }));
  };
  const columnMapCount = (columnId: string) =>
    Object.values(fieldMappings).filter((id) => id === columnId).length;
  const resetMapping = () => {
    const initialMappings: { [key: string]: string } = {};
    Object.keys(fields).forEach((field) => {
      const matchedColumn = columns.find((col: ANY) => col.field === field);
      if (matchedColumn) {
        initialMappings[field] = matchedColumn._id;
      }
    });
    setFieldMappings(initialMappings);
  };
  const clearMapping = () => {
    const initialMappings: { [key: string]: string } = {};
    setFieldMappings(initialMappings);
  };
  return (
    <div>
      <CustomAccordion
        isOpen
        title="Map Columns"
        description={
          <Flex mt={1} float={"right"} ml="auto" gap={2}>
            <ButtonGroup>
              <Tooltip label="Reset Mapping">
                <Button
                  onClick={resetMapping}
                  size={"xs"}
                  leftIcon={<Icons.RefreshIcon />}
                >
                  Reset
                </Button>
              </Tooltip>
              <Tooltip label="Clear Mapping">
                <IconButton
                  aria-label="Clear Mapping"
                  size={"xs"}
                  icon={<Icons.CleanIcon />}
                  onClick={clearMapping}
                />
              </Tooltip>
            </ButtonGroup>
          </Flex>
        }
      >
        <Table>
          <Thead>
            <Tr>
              <Th>Field</Th>
              <Th className="flex flex-row justify-between gap-4">
                Column
                <Text className="text-xs text-[gray]">
                  {currentMappedFields}/{totalFieldsToMap}
                </Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody gap={0}>
            {Object.entries(fields).map(([field, label]) => (
              <Tr key={field}>
                <Td>
                  <div className="flex place-content-start items-center gap-1">
                    {fieldIcons[field] ? fieldIcons[field] : null}
                    {label}
                  </div>
                </Td>
                <Td>
                  <Box className="flex w-full items-center gap-1">
                    <Box>
                      {columnIcons[fieldMappings[field]]
                        ? columnIcons[fieldMappings[field]]
                        : null}
                    </Box>
                    <Select
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "100%",
                          outline: "1px solid transparent",
                          outlineColor:
                            columnMapCount(fieldMappings[field]) > 1
                              ? "red"
                              : "transparent",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 99999,
                          bottom: "100%", // Position above the select input
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          width: "12em",
                          zIndex: 99999,
                        }),
                      }}
                      options={[
                        {
                          label: "Actions",
                          options: [
                            { value: "", label: "Select a column" },
                            // { value: `:${label}:`, label: "Create new Column" },
                          ],
                        },
                        {
                          label: "Available Columns",
                          options: columns.map((col: ANY) => ({
                            value: col._id,
                            label: col.name,
                          })),
                        },
                      ]}
                      value={
                        fieldMappings[field]
                          ? {
                              value: fieldMappings[field],
                              label:
                                columns.find(
                                  (col: ANY) =>
                                    col._id === fieldMappings[field],
                                )?.name || "Create new Column",
                            }
                          : { value: "", label: "Select a column" }
                      }
                      onChange={(selectedOption) =>
                        handleMappingChange(field, selectedOption?.value || "")
                      }
                      menuPlacement="top" // Ensures the menu is displayed above the input
                      menuPortalTarget={document.body} // Attach the menu to the body to prevent clipping
                      isSearchable
                    />
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CustomAccordion>
    </div>
  );
  function useMappings() {
    const columns = useMemo(
      () =>
        data?.data?.columns
          ?.map((column: ANY) =>
            column?._id
              ? {
                  name: column?.name,
                  _id: column?._id,
                  field: column.metaData?.field,
                  iconType: column.metaData?.iconType,
                }
              : null,
          )
          .filter(Boolean) || [],
      [data],
    );
    const fieldIcons = useMemo(
      () =>
        Object.fromEntries(
          columns
            .map((col: ANY) => {
              const field = col.field;
              const Icon =
                tableActionIcons[
                  col.iconType as keyof typeof tableActionIcons
                ] ?? tableActionIcons.text;
              return Icon
                ? [field, <Icon key={field} className="size-3" />]
                : [];
            })
            .filter(([field, icon]: ANY) => field && icon),
        ),
      [columns],
    );
    const columnIcons = useMemo(
      () =>
        Object.fromEntries(
          columns
            .map((col: ANY) => {
              const field = col.field;
              const Icon =
                tableActionIcons[
                  col.iconType as keyof typeof tableActionIcons
                ] ?? tableActionIcons.text;
              return Icon
                ? [col._id, <Icon key={field} className="size-3" />]
                : [];
            })
            .filter(([field, icon]: ANY) => field && icon),
        ),
      [columns],
    );
    // Manage mapping state
    const [fieldMappings, setFieldMappings] = useState<{
      [key: string]: string;
    }>({});
    const totalFieldsToMap = useMemo(
      () => Object.keys(fields).length,
      [fields],
    );
    const currentMappedFields = useMemo(
      () => Object.values(fieldMappings).filter((columnId) => columnId)?.length,
      [fieldMappings],
    );
    return {
      columns,
      fieldIcons,
      columnIcons,
      fieldMappings,
      setFieldMappings,
      totalFieldsToMap,
      currentMappedFields,
    };
  }
};
