import React from "react";
import { Handle, NodeToolbar, Position } from "@xyflow/react";

import Toolbar from "../../Common/AutoPilot/Toolbar";
import CustomNodeCard from "../../Common/AutoPilot/CustomNodeCard";

import SourceImg from "@/assets/svgs/auto-pilot-source.svg";
import { CustomNodeProps } from "@/components/Common/AutoPilot/types";
import { useWalkThroughModalStore } from "@/components/Common/AutoPilot/WalkThroughModal";

const JobOpeningsSourceNode: React.FC<CustomNodeProps> = ({ data }) => {
  const { updateModalState } = useWalkThroughModalStore();

  const handleConfig = () => {
    updateModalState({
      isOpen: true,
      title: "Job Openings with Google",
      flowName: "google_job_openings",
      action: "update",
      selectedTableData: data.tableData,
    });
  };

  return (
    <>
      {data.showToolbar && (
        <NodeToolbar isVisible={true} position={Position.Right}>
          <Toolbar
            isConfigured={!!data.isConfigured}
            handleConfig={handleConfig}
          />
        </NodeToolbar>
      )}
      <CustomNodeCard
        isActive={data.showToolbar || false}
        title="Job Openings Source"
        subtitle="Source"
        configStatus="Configured"
        isConfigured={!!data.isConfigured}
        isDisabled={false}
        imgSrc={SourceImg}
      />

      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" />
    </>
  );
};

export default JobOpeningsSourceNode;
