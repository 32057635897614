import { Box, Button } from "@chakra-ui/react";
import CampaignReach from "./Analytics/CampaignReach";
import EmailReach from "./Analytics/EmailReach";
import { useEffect, useRef, useState } from "react";
import useAnalytics from "./hooks/useAnalytics";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Icons from "../Icons";

const Analytics = ({ campaignId }: { campaignId: number }) => {
  // State for date range
  const [dateRange, setDateRange] = useState({
    startDate: format(new Date(), "yyyy/MM/dd"),
    endDate: format(new Date(), "yyyy/MM/dd"),
  });

  // State for controlling visibility of date picker
  const [calendarVisible, setCalendarVisible] = useState(false);

  // Ref to detect clicks outside the calendar or date range selector
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const dateRangeRef = useRef<HTMLDivElement | null>(null);

  // Fetch analytics data with updated dates
  const { data: campaignAnalytics, refetch } = useAnalytics(
    campaignId,
    dateRange.startDate,
    dateRange.endDate,
  ) as any;

  useEffect(() => {
    refetch(); // Re-fetch data whenever date range changes
  }, [dateRange, refetch]);

  // Close calendar if clicking outside the calendar and date range selector
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node) &&
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target as Node)
      ) {
        setCalendarVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Toggle calendar visibility
  const toggleCalendar = () => {
    setCalendarVisible((prev) => !prev);
  };
  return (
    <>
      <div className="flex items-center justify-center">
        {/* Date Range Selector */}
        <Box ref={dateRangeRef} className="w-[60%] mx-auto">
          <div className="flex items-center space-x-4 bg-white">
            <p className="text-[16px] font-medium text-[#373F51]">
              Select date:
            </p>
            <Button
              size={"sm"}
              variant={"outline"}
              rightIcon={<Icons.CalendarIcon className="text-[20px]" />}
              className="!text-[13px]"
              onClick={toggleCalendar}
            >
              {dateRange.startDate}
            </Button>
          </div>

          {/* DateRange calendar, visible when toggled */}
          {calendarVisible && (
            <div ref={calendarRef} className="absolute z-50">
              <DateRange
                ranges={[
                  {
                    startDate: new Date(dateRange.startDate),
                    endDate: new Date(dateRange.endDate),
                    key: "selection",
                  },
                ]}
                onChange={(ranges: any) => {
                  const { selection } = ranges;
                  const selectedDate = selection.startDate; // Use the same date for both start and end
                  setDateRange({
                    startDate: format(selectedDate, "yyyy/MM/dd"),
                    endDate: format(selectedDate, "yyyy/MM/dd"),
                  });
                }}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
                showDateDisplay={false}
                rangeColors={["#3b82f6"]} // Optional: customize the color for the selected date
                editableDateInputs={false} // Prevent user from editing inputs manually
              />
            </div>
          )}
        </Box>
      </div>
      <div className="flex flex-col gap-4 mt-2 w-[60%] mx-auto">
        <div className="flex items-center gap-3">
          <EmailReach campaignAnalytics={campaignAnalytics?.data || {}} />
          {/* <DealValue /> */}
        </div>
        <div className="flex items-center gap-3">
          <CampaignReach campaignAnalytics={campaignAnalytics?.data || {}} />
          {/* <LeadQuality campaignAnalytics={campaignAnalytics?.data || {}} /> */}
        </div>
      </div>
    </>
  );
};

export default Analytics;
