import { Textarea } from "@chakra-ui/react";

type EmailDetails = {
  sendTo: string;
  subject: string;
  body: string;
};

type Props = {
  showSendTo: boolean;
  showSubject: boolean;
  localEmailDetails: EmailDetails;
  setLocalEmailDetails: (val: any) => void;
};

const DraftEmailDetails = ({
  // showSendTo,
  // showSubject,
  localEmailDetails,
  setLocalEmailDetails,
}: Props) => {
  return (
    <div className="px-4 py-2">
      {/* {showSendTo && (
        <>
          <div className="mb-4">
            <p className="text-sm font-semibold">
              Send To:{" "}
              <Input
                placeholder=""
                size={"sm"}
                variant={"unstyled"}
                className="mt-2"
                value={localEmailDetails.sendTo}
                onChange={(e) =>
                  setLocalEmailDetails((prevDetails: EmailDetails) => ({
                    ...prevDetails,
                    sendTo: e.target.value,
                  }))
                }
              />
            </p>
          </div>
          <Divider className="my-2" />
        </>
      )}
      {showSubject && (
        <>
          <div className="mb-4">
            <p className="text-sm font-semibold">
              Subject:{" "}
              <Input
                placeholder=""
                size={"sm"}
                variant={"unstyled"}
                className="mt-2"
                value={localEmailDetails.subject}
                onChange={(e) =>
                  setLocalEmailDetails((prevDetails: EmailDetails) => ({
                    ...prevDetails,
                    subject: e.target.value,
                  }))
                }
              />
            </p>
          </div>
          <Divider className="my-2" />
        </>
      )} */}

      <div className="mb-4">
        <p className="text-sm font-semibold mb-2">AI Draft Message:</p>
        <Textarea
          style={{
            height: "300px",
            border: "1px solid #cbd5e0",
            maxHeight: "400px",
            fontSize: "14px",
          }}
          resize={"none"}
          placeholder=""
          value={`Subject: ${localEmailDetails.subject} ${localEmailDetails.subject && "\n\n"} Email body: \n\n ${localEmailDetails.body}`}
          onChange={(e) =>
            setLocalEmailDetails((prevDetails: EmailDetails) => ({
              ...prevDetails,
              body: e.target.value,
            }))
          }
        />
      </div>
    </div>
  );
};

export default DraftEmailDetails;
