import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

import { Box, Text } from "@chakra-ui/react";

// Register Chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const CampaignReach = ({ campaignAnalytics }: { campaignAnalytics: any }) => {
  if (!campaignAnalytics) {
    return <div>Loading...</div>;
  }

  const {
    sent_count = 0,
    open_count = 0,
    click_count = 0,
    reply_count = 0,
    bounce_count = 0,
  } = campaignAnalytics || {};

  // Categories for the x-axis
  const categories = ["Sent", "Opened", "Clicked", "Replied", "Bounced"];

  // Corresponding values for the categories
  const values = [
    parseInt(sent_count, 10),
    parseInt(open_count, 10),
    parseInt(click_count, 10),
    parseInt(reply_count, 10),
    parseInt(bounce_count, 10),
  ];

  // Chart data configuration
  const data = {
    labels: categories,
    datasets: [
      {
        label: "Count",
        data: values,
        backgroundColor: [
          "#757AE9",
          "#BF51C1",
          "#EEB728",
          "#51C1C1",
          "#E9758A",
        ],
        borderColor: ["#757AE9", "#BF51C1", "#EEB728", "#51C1C1", "#E9758A"],
        borderWidth: 1,
      },
    ],
  };

  // Chart options configuration
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Disable legend as we have only one dataset
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <Box
      className="bg-white rounded-[6px] p-4 border border-[#F0EFF1] shadow-[1px_1px_4px_4px_#DDD8FF4D,_-1px_-1px_4px_4px_#DDD8FF4D]"
      style={{ width: "100%" }}
    >
      <div className="flex items-center gap-2">
        <Text className="text-[16px] text-[#373F51] font-medium">
          Campaign Reach
        </Text>
      </div>

      <Box className="flex justify-center items-center !w-full mt-4 relative h-[400px]">
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default CampaignReach;
