import {
  Button,
  ButtonGroup,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";
import { useModalStore } from "@/stores/modal.store";

const Search = () => {
  const searchInput = useEnrichStore((state) => state.searchInput);
  const updateState = useEnrichStore((state) => state.updateState);
  const { updateState: updateModalState } = useModalStore();

  return (
    <>
      <div className="mb-[12px] flex items-center justify-between">
        <p className="text-[20px] leading-[24px]">Enrich your search</p>
        <ButtonGroup>
          <Button
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.MarketplaceIcon />}
            className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
            onClick={() => {
              updateState({
                isOpenEnrichModal: false,
              });
              setTimeout(() => {
                updateModalState({
                  isOpenModal: true,
                  modalData: {
                    modalType: "marketplace",
                    metaData: {
                      openPreviousModal: true,
                    },
                  },
                });
              }, 100);
            }}
          >
            Marketplace
          </Button>
          <Button
            colorScheme="primary"
            variant="outline"
            leftIcon={<Icons.BackIcon />}
            className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
            onClick={() => {
              updateState({
                isOpenEnrichModal: false,
              });
            }}
          >
            Go Back
          </Button>
        </ButtonGroup>
      </div>
      <InputGroup width="100%" className="mb-[20px]">
        <InputLeftElement>
          <Icons.SearchIcon size={20} color="gray" />
        </InputLeftElement>
        <Input
          style={{ borderRadius: "20px" }}
          placeholder="What you are looking for?"
          variant="outline"
          onChange={(e) => {
            updateState({
              searchInput: e.target.value,
              activeCategory: "",
            });
          }}
          value={searchInput}
        />
      </InputGroup>
    </>
  );
};

export default Search;
