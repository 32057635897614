import React from "react";

function AutoPilotWrapper({ children }: { children: React.ReactNode }) {
  return (
    <div
      className="grid min-h-screen max-h-screen"
      style={{
        gridTemplateColumns: "repeat(1, 25% 1fr)",
      }}
    >
      {children}
    </div>
  );
}

export default AutoPilotWrapper;
