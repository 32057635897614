import { HStack } from "@chakra-ui/react";
import AnnouncementIcon from "@/assets/svgs/announcement.svg";
import { useModalStore } from "@/stores/modal.store";

const NewReleaseBanner = () => {
  const updateState = useModalStore((state) => state.updateState);
  return (
    <div className="fixed top-0 z-10 flex items-center justify-center bg-[#F5F3FF] px-[20px] py-[8px] w-full">
      <HStack align={"center"} justify={"center"} gap={8}>
        <HStack align={"center"} justify={"center"} className="text-[#5024DC]">
          <img src={AnnouncementIcon} width={24} height={24} />
          <p className="text-[16px] font-medium">New Launch!</p>
          <p className="text-[16px] font-medium text-[#5024DC]">
            Persana's Automated Agents
          </p>
        </HStack>
        <div
          className="cursor-pointer w-fit rounded-[6px] border border-[#F0EFF1] px-[16px] py-[6px] text-[14px] font-medium text-white"
          style={{
            background: "linear-gradient(90deg, #8E32A4 0%, #5024DC 100%)",
          }}
          onClick={() => {
            updateState({
              isOpenModal: true,
              modalData: { modalType: "agents" },
            });
          }}
        >
          Learn more
        </div>
      </HStack>
    </div>
  );
};

export default NewReleaseBanner;
