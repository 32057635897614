import LoaderImg from "@/assets/svgs/campaign-loader.svg";

const CampaignGeneration = () => {
  return (
    <div className="flex flex-col gap-2 items-center py-[24px] bg-white">
      <img src={LoaderImg} alt="" />
      <p className="text-[20px] text-[#000000]">
        Nia is generating your campaign...
      </p>
      <p className="text-[#373F51] text-[14px] font-medium">
        Please check back 24hrs.
      </p>
    </div>
  );
};

export default CampaignGeneration;
