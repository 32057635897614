export const calculatePeopleAppliedFiltersCount = ({
  sideBarInput: data,
}: ANY = {}) => {
  let totalLength = 0;

  for (const key in data) {
    if (key !== "company_names" && key !== "fetchedCompanies") {
      const val = data[key];
      if (Array.isArray(val)) {
        totalLength += val.length;
      } else if (typeof val === "string") {
        totalLength += val.length > 0 ? 1 : 0;
      }
    }
  }

  return totalLength;
};

export const calculateCompanyAppliedFilters = ({
  inputtedValues,
}: ANY = {}) => {
  return (
    (inputtedValues?.keyword ? 1 : 0) +
    (inputtedValues?.name ? 1 : 0) +
    (inputtedValues?.website ? 1 : 0) +
    (inputtedValues?.company_types?.length || 0) +
    (inputtedValues?.company_sizes?.length || 0) +
    (inputtedValues?.year_founded ? 1 : 0) +
    (inputtedValues?.followers ? 1 : 0) +
    (inputtedValues?.revenue ? 1 : 0) +
    (inputtedValues?.company_name_prefix ? 1 : 0) +
    (inputtedValues?.country_codes?.length || 0) +
    (inputtedValues?.city_to_include ? 1 : 0) +
    (inputtedValues?.city_to_exclude ? 1 : 0) +
    (inputtedValues?.industries?.length || 0) +
    (inputtedValues?.excluded_industries?.length || 0)
  );
};

export const getExportFields = (isForEmailEnrichment: boolean) => {
  return {
    name: "Name",
    ...(isForEmailEnrichment
      ? { email: "Email", email_status: "Email Status" }
      : {}),
    linkedin_url: "Linkedin URL",
    title: "Title",
    "organization.name": "Company Name",
    city: "City",
    "organization.website_url": "Company Website",
    "organization.linkedin_url": "Company Linkedin URL",
    employment_history: "Employment History",
  };
};
